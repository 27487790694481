export default {
  addRecord: 'Add record',
  record: 'Record',
  recordAdded: 'Record added',
  status: 'Status',
  recordedDate: 'Recorded date',
  dueDate: 'Due date',
  completedDate: 'Completed date',
  lastRecord: 'Last record',
  subject: 'Subject',
  statuses: {
    planned: 'Planned',
    completed: 'Completed',
    not_started: 'Not started',
    in_progress: 'In progress',
    canceled: 'Canceled',
    waiting_for_input: 'Waiting for input',
    deferred: 'Deferred'
  },
  types: {
    call: 'Call',
    event: 'Event',
    task: 'Task'
  },
  typeOfActivity: 'Type of activity'
};
