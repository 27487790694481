export default {
  accountTransactionCount: 'Account Transaction Count',
  addFunds: 'Add funds',
  addANote: 'Add a note...',
  allInvoices: 'All invoices',
  allItemsBelowAreExpectedToBePaidHoweverYouMayChooseSpecificItemsToBeIncludedInTheCreatedOpenInvoice:
    'All items below are expected to be paid. However, you may choose specific items to be included in the created open invoice. All unchecked items will stay in the preliminary invoice for you to pay with the future open invoice(s).',
  amount: 'Amount',
  anotherAddress: 'Another address',
  archivizerAccount: 'Archivizer account',
  areYouSureYouWantToCreateInvoice: 'Are you sure you want to create invoice?',
  areYouSureYouWantToCancelThisInvoice:
    'Are you sure you want to cancel this іnvoice?',
  areYouSureYouWantToUncollectibleThisInvoice:
    'Are you sure you want to uncollectible this іnvoice?',
  areYouSureYouWantToSendThisInvoice:
    'Are you sure you want to send this іnvoice?',
  areYouSureYouWantToPayFromDeposit:
    'Are you sure you want to pay from deposit?',
  avInvoiceBillingInfoPaymentMethod: 'AV invoice billing info payment method',
  billingAddress: 'Billing address',
  billingInfo: 'Billing info',
  cancel: 'Cancel invoice',
  cardInfo: 'Card info',
  chargeback: 'Chargeback',
  clientId: 'Client Id',
  companyName: 'Company name',
  confirmITC: 'Confirm ITC',
  confirmPayment: 'Confirm payment',
  confirmInvoicePayment: 'Confirm invoice payment',
  contactEmailAddress: 'Contact email address',
  contactName: 'Contact name',
  contractor: 'Contractor',
  contactPhoneNumber: 'Contact phone number',
  create: 'Create invoice',
  created: 'created invoice',
  createYourInvoices: 'Create your invoices',
  creditCard: 'Credit card',
  date: 'Invoice date',
  details: 'Invoice details',
  deposit: 'Deposit',
  depositInvoices: 'Deposit invoices',
  dueDate: 'Due date',
  dueDateColon: 'Due date:',
  generalLedgerId: 'General ledger id',
  id: 'Invoice ID',
  ids: 'Invoice IDs',
  invoiceCreated: 'Invoice created',
  invoiceBillingEmail: 'Invoice billing email',
  invoiceHasNoItems: 'This invoice has no items',
  issueDate: 'Issue date',
  issueDateColon: 'Issue date:',
  notes: 'Notes',
  notesColon: 'Notes:',
  otherInvoices: 'Other Invoices',
  plural: 'Invoices',
  parentInvoiceId: 'Parent Invoice Id',
  pluralLowerCase: 'invoices',
  primaryInvoiceId: 'Primary Invoice Id',
  refreshStatus: 'Refresh status',
  refund: 'Refund',
  refunded: 'Refunded',
  regular: 'Regular',
  secondaryInvoiceId: 'Secondary invoice ID',
  send: 'Send invoice',
  sendToClient: 'Send invoice to the client',
  singular: 'Invoice',
  terms: 'Terms',
  total: 'Invoice total',
  termsColon: 'Terms:',
  type: 'Invoice type',
  userId: 'User ID',
  messages: {
    areYouSureYouWantToCancelInvoice:
      'Are you sure you want to cancel invoice?',
    creditCardMethods: 'Visa/Mastercard, Payoneer, Stripe, EasyPay, Fondy.eu',
    onceWeReceiveYourPayment:
      'Once we receive your payment, funds are available on your deposit',
    payoneerWarning:
      'By using the Payoneer Services through the Online Pages and APIs, I agree that any information and data related to payments made through the API and any information provided by me on the Online Pages will be transmitted and processed by Payoneer, and may also be available to Company.',
    stripeWarning: 'You can pay with the Stripe payment system only on ',
    weHaveSentTheInvoiceToYourEmail: 'We have sent the invoice to your email',
    weHaveSentReceiptToYourEmail: 'We have sent receipt to your email',
    wireMethods: 'SWIFT, ACH, BACS, SEPA, PayPal'
  },
  myItemsOnly: 'My items only',
  noPlural: 'No invoices',
  account: 'Status',
  payer: 'Payer',
  payBill: 'Pay bill',
  payFromDeposit: 'Pay from deposit',
  paymentDate: 'Payment date',
  paymentMethod: 'Payment method',
  paymentMethodWithColon: 'Payment method:',
  payments: 'Payments',
  performerInvoices: 'Performer invoices',
  currentPerformerInvoices: 'Current performer invoices',
  overduePerformerInvoices: 'Overdue performer invoices',
  prepayment: 'Prepayment',
  prepaymentAmount: 'Prepayment amount',
  proformaInvoice: 'Proforma invoice',
  projectName: 'Project name',
  receivedAmount: 'Received amount',
  returnReason: 'Return reason',
  sender: 'Sender',
  searchByGeneralLedgerId: 'Search by general ledger id',
  status: 'Invoice status',
  view: 'View invoice',
  viewOpenInvoices: 'View open invoices',
  void: 'Void',
  wire: 'WIRE',
  statuses: {
    draft: 'Draft',
    open: 'Open',
    paid: 'Paid',
    prepaid: 'Prepaid',
    sent: 'Sent',
    canceled: 'Canceled',
    uncollectible: 'Uncollectible',
    void: 'Void',
    none: 'None',
    upcoming: 'Upcoming'
  }
};
