export default {
  plural: 'Categories',
  seeAll: 'See all categories',
  seeLess: 'See less categories',
  singular: 'Category',
  new: 'New Category',
  'add...': 'Add categories',
  'select...': 'Select categories...',
  no: 'No categories',
  styles: {
    plural: 'Styles',
    singular: 'Style',
    'select...': 'Select style...'
  },
  materials: {
    plural: 'Materials',
    singular: 'Material',
    'select...': 'Select material...'
  },
  brands: {
    plural: 'Brands',
    search: 'Search brands',
    singular: 'Brand',
    'add...': 'Add brand...',
    'select...': 'Select brand...'
  },
  gammas: {
    singular: 'Gamma',
    'select...': 'Select gamma...'
  },
  groups: {
    plural: 'Groups',
    singular: 'Group',
    'select...': 'Select groups...'
  },
  tones: {
    singular: 'Tone',
    'select...': 'Select tone...'
  },
  colors: {
    plural: 'Colors',
    singular: 'Color',
    'select...': 'Select color...'
  },
  mainColors: {
    plural: 'Main colors',
    singular: 'Main color',
    'select...': 'Select main color...'
  },
  accentColors: {
    plural: 'Accent colors',
    singular: 'Accent color',
    'select...': 'Select accent color...'
  },
  authors: {
    plural: 'Authors',
    singular: 'Author',
    search: 'Search authors',
    select: 'Select author'
  },
  performers: {
    plural: 'Authors',
    singular: 'Author',
    select: 'Select author',
    'select...': 'Select author...'
  },
  projects: {
    plural: 'Projects',
    singular: 'Project',
    select: 'Select project',
    'select...': 'Select project...'
  },
  manufacturers: {
    singular: 'Manufacturer',
    plural: 'Manufacturers'
  },
  fileVersionTags: {
    plural: 'File version tags',
    singular: 'File version tag',
    'select...': 'Select file version tag...'
  },
  renderTypes: {
    plural: 'Render types',
    singular: 'Render type',
    'select...': 'Select render type...'
  },
  collections: {
    plural: 'Collections',
    singular: 'Collection',
    'select...': 'Select collection...'
  },
  mistakeTypes: {
    plural: 'Mistake types',
    singular: 'Mistake type',
    select: 'Select mistake type',
    'select...': 'Select mistake type...'
  },
  statuses: {
    plural: 'Statuses',
    singular: 'Status',
    select: 'Select status',
    'select...': 'Select status...'
  },
  client: {
    select: 'Select client'
  },
  coldSource: 'Cold source',
  marketingSource: 'Marketing source'
};
