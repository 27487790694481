import { ItemTypesKeyType } from '../../keys/models/itemTypes';

const itemTypesKeys: ItemTypesKeyType = {
  plural: 'Item types',
  defaultPlural: 'Default item types',
  search: 'Search item types',
  select: 'Select item type...',
  create: 'Create item type',
  update: 'Update item type',
  delete: 'Delete item type'
};

export default itemTypesKeys;
