import React, { useState, useCallback } from 'react';

import { ClassName } from '../../../../types';
import { AvBillingInfoNanoID } from '../../avBillingInfosTypes';

import { AvBillingInfoPublicUrl } from '../../AvBillingInfoPublicUrl';

type AvBillingInfosUsersIndexTableProps = {
  avBillingInfoNanoId: AvBillingInfoNanoID;
  className: ClassName;
};

function AvBillingInfoLogo({
  avBillingInfoNanoId,
  className
}: AvBillingInfosUsersIndexTableProps) {
  const [hidden, setHidden] = useState<boolean>(false);

  const handleError = useCallback<() => void>(() => {
    setHidden(true);
  }, []);

  const handleLoad = useCallback<() => void>(() => {
    setHidden(false);
  }, []);

  return (
    <img
      className={className}
      src={AvBillingInfoPublicUrl.logo(avBillingInfoNanoId)}
      alt="logo"
      onError={handleError}
      onLoad={handleLoad}
      hidden={hidden}
    />
  );
}

export default AvBillingInfoLogo;
