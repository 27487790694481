import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  BooleanFilter,
  StringFilter,
  CreatedAt,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsSerializer,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  NanoID,
  NanoIDFilter,
  UUID,
  FetchItemFetched,
  FetchItemsGqlQuery,
  TextFilterType,
  NanoIdFilterType,
  IdFilterType,
  DateFilterType,
  UuidFilterType
} from '../../types';

import {
  AccountTypeUUID,
  AccountTypeNanoID,
  AccountTypeName,
  AccountTypeClientName,
  AccountTypePerformerName,
  AccountTypeShortName,
  AccountTypeSystemName,
  AccountTypeChartNumber,
  AccountTypeIncreaseSides,
  AccountTypeCreatedAt,
  AccountTypeTypes
} from '../accountTypes/accountTypesTypes';

import { TeamName, TeamNanoID } from '../teams/teamsTypes';

export type AccountID = ID;
export type AccountUUID = UUID;
export type AccountNanoID = NanoID;
export type AccountCreatedAt = CreatedAt;
export type AccountGeneralLedgerId = ID;
export type AccountIncreaseSide = 'debit' | 'credit';

export type AccountBalance = number;

export type AccountAccountTypeUUID = AccountTypeUUID;
export type AccountAccountTypeNanoID = AccountTypeNanoID;
export type AccountAccountTypeName = AccountTypeName;
export type AccountAccountTypeClientName = AccountTypeClientName;
export type AccountAccountTypePerformerName = AccountTypePerformerName;
export type AccountAccountTypeShortName = AccountTypeShortName;
export type AccountAccountTypeSystemName = AccountTypeSystemName;
export type AccountAccountTypeChartNumber = AccountTypeChartNumber;
export type AccountAccountTypeIncreaseSides = AccountTypeIncreaseSides;
export type AccountAccountTypeCreatedAt = AccountTypeCreatedAt;

export type AccountAccountTypeTypes = AccountTypeTypes;

export type AccountGeneralLedgerCompanyNanoID = TeamNanoID;
export type AccountGeneralLedgerCompanyName = TeamName;

export type FetchAccountsCacheKey = FetchItemsCacheKey;

export interface FetchAccountsFilters {
  companyNanoId?: NanoIDFilter;
  accountTypeNanoId?: NanoIDFilter;
  withoutZeroBalances?: BooleanFilter;
  accountTypeType?: AccountAccountTypeTypes;
  accountTypeChartNumber?: StringFilter;
}

export interface FetchFinAccountsFilters {
  accountTypeChartNumber?: TextFilterType;
  accountTypeType?: AccountTypeTypeFilter;
  companyNanoId?: NanoIdFilterType;
  createdAt?: DateFilterType;
  id?: IdFilterType;
  nanoId?: NanoIdFilterType;
  updatedAt?: DateFilterType;
  uuid?: UuidFilterType;
  withoutZeroBalances?: boolean;
}

export type AccountTypeTypeFilter = {
  eq?: AccountTypeTypes;
  in?: AccountTypeTypes[];
};

export const enum FetchAccountsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  ACCOUNT_TYPE_CHART_NUMBER_ASC = 'ACCOUNT_TYPE_CHART_NUMBER_ASC',
  ACCOUNT_TYPE_CHART_NUMBER_DESC = 'ACCOUNT_TYPE_CHART_NUMBER_DESC',
  BALANCE_CENTS_ASC = 'BALANCE_CENTS_ASC',
  BALANCE_CENTS_DESC = 'BALANCE_CENTS_DESC',
  ACCOUNT_TYPE_NAME_ASC = 'ACCOUNT_TYPE_NAME_ASC',
  ACCOUNT_TYPE_NAME_DESC = 'ACCOUNT_TYPE_NAME_DESC'
}

export const enum FetchAccountsSortableFields {
  ACCOUNT_TYPE_CHART_NUMBER = 'accountTypeChartNumber',
  BALANCE_CENTS = 'balanceCents',
  ACCOUNT_TYPE_NAME = 'accountTypeName'
}

export const enum AccountFields {
  NAME = 'name'
}

export type FetchAccountCacheKey = FetchItemCacheKey;
export type FetchAccountGqlQuery = FetchItemGqlQuery;

export type FetchAccountsSort = FetchItemsSort;
export type FetchAccountsSortAccounts = FetchItemsSortItems;
export type FetchAccountsPage = FetchItemsPage;
export type FetchAccountsLimit = FetchItemsLimit;
export type FetchAccountsSerializer = FetchItemsSerializer;
export type FetchAccountsErrorMessage = FetchItemsErrorMessage;
export type FetchAccountsFetched = FetchItemsFetched;
export type FetchAccountFetched = FetchItemFetched;
export type FetchAccountsIsPlaceholderData = FetchItemsIsPlaceholderData;
// export type FetchAccountIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchAccountsPaginateAccounts = FetchItemsPaginateItems;
export type FetchAccountsPrefetchAccounts = FetchItemsPrefetchItems;
export type FetchAccountsTotalCount = FetchItemsTotalCount;
export type FetchAccountsFilterAccounts =
  FetchItemsFilterItems<FetchAccountsFilters>;

export type FetchAccountsGqlQuery = FetchItemsGqlQuery;

export type FetchAccountsCheckedAll = CheckedAll;
export type FetchAccountsOnCheckAll = OnCheckAll;
export type FetchAccountsChecked = Checked;
export type FetchAccountsCheckedHash = CheckedHashItem;
export type FetchAccountsOnSetCheckedIds = OnSetCheckedIds;

export type ChangeAccountsFiltersFunc = (
  changedFilters: FetchAccountsFilters,
  removeFilters?: string[]
) => void;

export type AccountTypeFilter = {
  eq?: AccountTypeTypes;
  in?: AccountTypeTypes[];
};
