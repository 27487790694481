import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { TaskNanoID } from '../tasks/tasksTypes';
import { UserNanoID } from '../users/usersTypes';

import { ProjectID, ProjectNanoID } from './projectsTypes';
import { CRM_SMASHING_CGI_URL } from '../../config';

const basePath = '/projects';

export class ProjectPath {
  static index() {
    return basePath;
  }

  static show(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}`;
  }

  static brief(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/brief`;
  }

  static importTasks(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/import-tasks`;
  }

  static burning() {
    return `${basePath}/filters/burning`;
  }

  static evaluations() {
    return `${basePath}/evaluations`;
  }

  static favorite() {
    return `${basePath}/filters/favorite`;
  }

  static myProjects() {
    return `${basePath}/filters/my-projects`;
  }

  static invitations() {
    return `${basePath}/invitations`;
  }

  static createInTeam() {
    return `${basePath}/create`;
  }

  static invoices() {
    return `${basePath}/invoices`;
  }

  static userProjects($userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${$userNanoId}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }

  static accept(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/accept`;
  }

  static messages(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/messages`;
  }

  static depositInvoices(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/deposit-invoices`;
  }

  static messagesById(projectId: ProjectID) {
    return `${basePath}/${projectId}/messages`;
  }

  static taskAttachments(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/attachments`;
  }

  static taskFiles(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/files`;
  }

  static taskImages(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/images`;
  }

  static taskSourceFiles(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/source-files`;
  }

  static taskLifestyles(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/lifestyles`;
  }

  static taskProducts(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/products`;
  }

  static taskMaterials(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/materials`;
  }

  static attachments(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/attachments`;
  }

  static files(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/files`;
  }

  static images(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/images`;
  }

  static results(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/results`;
  }

  static lifestyles(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/lifestyles`;
  }

  static products(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/products`;
  }

  static materials(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/materials`;
  }

  static team(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/team`;
  }

  static notifications(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/notifications`;
  }

  static transactions(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/transactions`;
  }

  static invoicesByNanoId(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/invoices`;
  }

  static activity(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/activity`;
  }

  static batchOperations(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/batch-operations`;
  }

  static showSmashingCgi(projectNanoId: ProjectNanoID) {
    return `${CRM_SMASHING_CGI_URL}${basePath}/${projectNanoId}`;
  }

  static payments(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/payments`;
  }

  static proforma(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/proforma`;
  }

  static journalRecords(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/journal-records`;
  }
}
