export default {
  accordingTo: 'according to',
  account: 'Account',
  activity: 'Activity',
  accept: 'Accept',
  active: 'Active',
  add: 'Add',
  addItem: 'Add item',
  addMore: 'Add more',
  addReaction: 'Add reaction',
  addService: 'Add service',
  addToFavorites: 'Add to favorites',
  addUser: 'Add user',
  addYourCommentOptional: 'Add your comment (optional)',
  adorable: 'Adorable',
  allActivity: 'All activity',
  allAttachments: 'All attachments',
  allocate: 'Allocate',
  allocateFunds: 'Allocate funds',
  allTime: 'All time',
  already_selected: 'Already selected',
  amount: 'Amount',
  amountDue: 'Amount due',
  and: 'and',
  any: 'Any',
  anyType: 'Any type',
  applicationLanguage: 'Application language',
  apply: 'Apply',
  approve: 'Approve',
  artists: 'Artists',
  assignAsCreator: 'Assign as creator',
  assignAsOwner: 'Assign as owner',
  attach: 'Attach',
  attachImage: 'Attach image',
  autoAllocate: 'Auto allocate',
  backToProject: 'Back to project',
  backtrace: 'Backtrace',
  badDebt: 'Bad debt',
  back: 'Back',
  block: 'Block',
  blocked: 'Blocked',
  brief: 'Brief',
  browse: 'Browse',
  budget: 'Budget',
  cancel: 'Cancel',
  caution: 'Caution!',
  celebrate: 'Celebrate',
  clap: 'Clap',
  clear: 'Clear',
  clearAll: 'Clear all',
  clearFilter: 'Clear filter',
  clearFilters: 'Clear filters',
  clearSelection: 'Clear selection',
  clickToCopy: 'Click to copy',
  client: 'Client',
  clientDebt: 'Client debt',
  clientManager: 'Client manager',
  clients: 'Clients',
  close: 'Close',
  colors: 'Colors',
  comment: 'Comment',
  company: 'Company',
  confirm: 'Confirm',
  contactSupport: 'Contact support',
  contactInfo: 'Contact info',
  continue: 'Continue',
  control: 'Control',
  copyId: 'Copy ID',
  copyLink: 'Copy link',
  copyUuid: 'Copy UUID',
  cost: 'Cost',
  create: 'Create',
  createNewFilter: 'Create new filter',
  criticalRequirements: 'Critical requirements',
  change: 'Change',
  changeOwner: 'Change owner',
  changeCurrency: 'Change currency',
  current: 'Current',
  date: 'Date',
  dateAndTimeFormat: 'Date and time format',
  dateOfBirth: 'Date of birth',
  debt: 'Debt',
  default: 'Default',
  delete: 'Delete',
  denyAccess: 'Deny access',
  delete_filter: 'Delete filter',
  description: 'Description',
  descriptionOptional: 'Description (optional)',
  disable: 'Disable',
  dislike: 'Dislike',
  download: 'Download',
  downloadTaskResult: 'Download task result',
  downloadAll: 'Download all',
  downloadAllUpperCase: 'DOWNLOAD ALL',
  dpi: 'dpi',
  draft: 'Draft',
  draftBudget: 'Draft budget',
  drawings: 'Drawings',
  dueDate: 'Due date',
  duplicate: 'Duplicate',
  edit: 'Edit',
  emailAddress: 'Email address',
  emailChanged: 'Email changed',
  enable: 'Enable',
  english: 'English',
  errorMessage: 'Error message',
  enterMessageId: 'Enter message ID',
  enterMessageIds: 'Enter message IDs',
  eur: 'EUR',
  expand_view: 'Expand view',
  expenses: 'Expenses',
  favorite: 'Favorite',
  files: 'Files',
  filter: 'Filter',
  filterFavorite: 'Filter favorite',
  filters: 'Filters',
  fin: 'Fin',
  fire: 'Fire',
  flagged: 'Flagged',
  forward: 'Forward',
  for: 'for',
  from: 'From',
  general: 'General',
  generate: 'Generate',
  goBack: 'Go back',
  groupBy: 'Group by',
  gbp: 'GBP',
  helpCenter: 'Help Center',
  hidden: 'Hidden',
  hiddenLowerCase: 'hidden',
  hide: 'Hide',
  hideList: 'Hide list',
  high: 'High',
  homepage: 'Go to the home page',
  images: 'Images',
  in: 'in',
  include: 'Include',
  incomeStatement: 'Income statement',
  info: 'Info',
  invisibleForClient: 'Invisible for client',
  invisibleForWorker: 'Invisible for worker',
  invite_user: 'Invite user',
  itc: 'ITC',
  its: "It's",
  joined: 'Joined',
  languagesSpoken: 'Languages spoken',
  lastMonth: 'Last month',
  lastWeek: 'Last week',
  laughing: 'Laughing',
  leave: 'Leave',
  leaveMessage: 'Leave Message',
  like: 'Like',
  link: 'Link',
  location: 'Location',
  log_out: 'Log out',
  login_page: 'Go to the login page',
  loadMore: 'Load More',
  love: 'Love',
  low: 'Low',
  mad: 'Mad',
  media: 'Media',
  members: 'Members',
  membersGenitiveLowerCase: 'members',
  model: 'Model',
  more: 'More',
  move: 'Move',
  myLibrary: 'My library',
  name: 'Name',
  nameEn: 'Name (En)',
  na: 'n/a',
  nda: 'NDA',
  needHelp: 'Need help?',
  new: 'New',
  no: 'No',
  notAsked: 'Not asked',
  noResultsYet: 'No results yet',
  noSearchResults: 'No search results',
  thereAreNoMatchesForSelectedFilters:
    'There are no matches for selected filters',
  of: 'of',
  ok: 'Ok',
  open: 'Open',
  openOptions: 'Open options',
  open_in_new_tab: 'Open in new tab',
  or: 'Or',
  oneDay: '1 day',
  oneWeek: '1 week',
  oneMonth: '1 month',
  overdue: 'Overdue',
  owner: 'Owner',
  paid: 'Paid',
  passwordChanged: 'Password changed',
  pay: 'Pay',
  payByCard: 'Pay by card',
  payer: 'Payer',
  paymentMethod: 'Payment Method',
  payments: 'Payments',
  paymentTerms: 'Payment Terms',
  pendingPayments: 'Pending payments',
  phoneNumber: 'Phone number',
  pinned: 'Pinned',
  policies: 'Policies',
  postMessage: 'Post message',
  price: 'Price',
  privacy: 'Privacy',
  privacyPolicy: 'Privacy policy',
  proceed: 'Proceed',
  processing: 'Processing...',
  profile: 'Profile',
  progress: 'Progress',
  proforma: 'Proforma',
  px: 'px',
  quantity: 'Quantity',
  requirements: 'Requirements',
  receiveNotifications: 'Receive notifications',
  recentlySelected: 'Recently selected',
  references: 'References',
  refund: 'Refund',
  refunds: 'Refunds',
  remove: 'Remove',
  removeFromFavorites: 'Remove from favorites',
  reset_password: 'Reset password',
  results: 'results',
  rub: 'RUB',
  russian: 'Russian',
  save: 'Save',
  saveChanges: 'Save Changes',
  saveFilter: 'Save filter',
  savedFilters: 'Saved filters',
  scopeOfWork: 'Scope of work',
  search: 'Search',
  searchResults: 'Search results',
  search_user_by_name: 'Search user by name',
  searching: 'Searching...',
  secondsGenitive: 'seconds',
  select: 'Select',
  selectToAddToTheProject: 'Select to add to the project',
  selected: 'Selected',
  send: 'Send',
  sender: 'Sender',
  sendInstructions: 'Send instructions',
  sendToAllTasks: 'Send to all tasks',
  sendViaEmail: 'Send via email',
  sendToEmail: 'Send to email',
  sent: 'Sent',
  serviceAgreement: 'Service agreement',
  serviceName: 'Service name',
  setDueDate: 'Set due date',
  settings: 'Settings',
  show: 'Show',
  showAll: 'Show all',
  showLess: 'Show less',
  showList: 'Show list',
  showMore: 'Show more',
  showFavoriteOnly: 'Show favorite only',
  showMyLibrary: 'Show my library',
  showing: 'Showing',
  sign_out: 'Sign out',
  sortByNewest: 'Sort by: Newest',
  sortByMostLiked: 'Sort by: Most liked',
  sortByMostUsed: 'Sort by: Most used',
  stopAll: 'Stop all',
  newest: 'Newest',
  mostUsed: 'Most used',
  mostLiked: 'Most liked',
  sad: 'Sad',
  sketches: 'Sketches',
  sku: 'SKU',
  starred: 'Starred',
  startDate: 'Start date',
  state: 'State',
  status: 'Status',
  statusColon: 'Status:',
  submit: 'Submit',
  success: 'Success',
  surnameEn: 'Surname (En)',
  surprised: 'Surprised',
  system: 'System',
  task: 'Task',
  tbd: 'TBD',
  terms: 'Terms',
  termsOfService: 'Terms of Service',
  terrified: 'Terrified',
  textHere: 'Text here',
  textures: 'Textures',
  tfe: 'TFE',
  testProjectInfo: 'Test project info',
  threeDStock: '3D Stock',
  threeDs: '3Ds',
  time: 'Time',
  timeZone: 'Time zone',
  to: 'To',
  today: 'Today',
  total: 'Total',
  totalDebt: 'Total debt',
  trackedTime: 'Tracked time',
  tryAgain: 'Try again',
  uah: 'UAH',
  ukraine: 'Ukrainian',
  underpayment: 'Underpayment',
  unselectAllItems: 'Unselect all items',
  unblock: 'Unblock',
  update: 'Update',
  updateExistingFilter: 'Update existing filter',
  updateImageVersions: 'Update image versions',
  updated: 'Updated',
  usd: 'USD',
  user: 'User',
  view: 'View',
  viewAccounts: 'View accounts',
  viewAll: 'View all',
  viewAllMembers: 'View all members',
  viewCR: 'View CR',
  viewProfile: 'View Profile',
  visibility: 'Visibility',
  visibilityForClient: 'Visibility for client',
  visibilityForWorker: 'Visibility for worker',
  visible: 'Visible',
  visibleForClient: 'Visible for client',
  welcomeToArchivizer: 'Welcome to ARCHIVIZER!',
  workers: 'Workers',
  workflowGuide: 'Workflow guide',
  writeHere: 'Write here',
  yes: 'Yes',
  colorSchemeDark: 'Dark',
  colorSchemeLight: 'Light',
  colorScheme: 'Color scheme',
  show_n_selected_items: {
    show: 'Show',
    hide: 'Hide',
    selected_items: 'selected items'
  },
  tax: 'Tax',
  timer: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds'
  },
  letters: {
    h: 'h',
    m: 'm'
  },
  receiver: 'Receiver',
  selectAll: 'Select all',
  void: 'Void',
  upload: 'Upload',
  lessWeek: '< 1 week',
  lessTwoWeek: '< 2 weeks',
  lessMonth: '< 1 month',
  lessTwoMonth: '< 2 months',
  moreTwoMonth: '> 2 months',
  yesterday: 'Yesterday'
};
