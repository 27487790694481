import { TeamNanoID } from '../teams/teamsTypes';
import { AccountTypeNanoID } from '../accountTypes/accountTypesTypes';
import { AccountID, AccountNanoID } from '../accounts/accountsTypes';

export class AccountCache {
  static showCacheKey() {
    return 'account';
  }
  static companyPaymentsAccountsAssetsCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-payments-accounts-assets`;
  }
  static companyPaymentsAccountsEquityCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-payments-accounts-equity`;
  }
  static companyPaymentsAccountsLiabilityCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-payments-accounts-liability`;
  }
  static companyPaymentsAccountsProvisionsCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-payments-accounts-provisions`;
  }
  static companyCashAccountCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-cash-account`;
  }
  static companiesPageAccountsCacheKey(companyNanoId: TeamNanoID[]) {
    return `companies-${companyNanoId.join('-')}-all-accounts`;
  }
  static performerRevenueAccountCacheKey(companyNanoId: TeamNanoID) {
    return `performer-${companyNanoId}-revenue-account`;
  }
  static companyAccountsByAccountType(
    companyNanoId: TeamNanoID,
    accountTypeNanoId: AccountTypeNanoID
  ) {
    return `company-${companyNanoId}-accounts-by-account-type-${accountTypeNanoId}`;
  }
  static showRedirectCacheKey(accountId: AccountID) {
    return `account-${accountId}-redirect`;
  }

  static companyDashboardAccountsCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-dashboard-accounts`;
  }

  static accountBalances(accountNanoId: AccountNanoID) {
    return `account-${accountNanoId}-account-balances`;
  }

  static companyAccountBalances() {
    return `company-account-balances`;
  }
}
