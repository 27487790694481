import { IconsEnum } from '../../../../../assets/icons/types';

import { UserTermsModalButton } from '../../../../../app/components/modalButtons/UserTermsModalButton';

import { UserFinanceDebitBadgeLink } from '../DefaultFooter/components/UserFinanceDebitBadgeLink';
import { UserDownloadManagerModalButton } from '../DefaultFooter/components/UserDownloadManagerModalButton';
import { UserFinanceDebitFinBadgeLink } from '../DefaultFooter/components/UserFinanceDebitFinBadgeLink';

import { AddFundsModalButton } from '../../../../invoices/components/modalButton/AddFundsModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { UsersPermissions } from '../../../../users/usersConstants';
import { InvoicesPermissions } from '../../../../invoices/invoicesConstants';
import { words, invoicesKeys } from '../../../../../locales/keys';

function TpvFooter() {
  return (
    <div className="relative w-full text-xs leading-none flex justify-between">
      <div className="text-2xs text-white flex gap-1 items-center leading-none">
        <CheckPermissions
          action={UsersPermissions.READ_USER_FINANCE_DEBIT_BADGE_LINK}
        >
          <UserFinanceDebitBadgeLink
            className="rounded hover:bg-white/20 pl-2.5 gap-1 items-center focus:ring-base focus:ring-0 hidden sm:flex"
            badgeClassName="bg-white/20 px-1 rounded leading-5 text-2xs"
          />
        </CheckPermissions>

        <CheckPermissions
          action={UsersPermissions.READ_USER_FINANCE_DEBIT_FIN_BADGE_LINK}
        >
          <UserFinanceDebitFinBadgeLink
            className="rounded hover:bg-white/20 pl-2.5 gap-1 items-center focus:ring-base focus:ring-0 hidden sm:flex"
            badgeClassName="bg-white/20 px-1 rounded leading-5 text-2xs"
          />
        </CheckPermissions>
        <CheckPermissions
          action={InvoicesPermissions.READ_FOOTER_ADD_FUNDS_MODAL_BUTTON}
        >
          <AddFundsModalButton
            className="rounded hover:bg-white/20 px-2.5 gap-1 items-center focus:ring-base focus:ring-0 hidden sm:flex"
            icon={IconsEnum.PLUS}
            i18nText={invoicesKeys.addFunds}
          />
        </CheckPermissions>
      </div>

      <div className="text-2xs text-white flex gap-1 items-center leading-none">
        <UserDownloadManagerModalButton
          processingClassName="rounded hover:bg-white/20 pl-2.5 gap-1 items-center focus:ring-base focus:ring-0 flex overflow-hidden sm:flex"
          staleClassName="rounded hover:bg-white/20 px-2.5 gap-1 items-center focus:ring-base focus:ring-0 flex overflow-hidden sm:flex"
          badgeClassName="bg-white/20 px-1 rounded leading-5 text-2xs"
          i18nTextClassName="leading-5"
        />
        <UserTermsModalButton
          i18nText={words.terms}
          i18nTextClassName="leading-5 font-medium text-blue-300 hover:text-blue-100 hidden sm:flex"
          className="rounded hover:bg-white/20 px-2.5 gap-1 items-center focus:ring-base focus:ring-0 flex"
        />
      </div>
    </div>
  );
}

export default TpvFooter;
