import React, { Fragment, memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { PrimaryNavTabsEnum } from '../../../hooks/useMenu/types';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { PrimaryNavTab } from './helpers/PrimaryNavTab';

import { ProjectPath } from '../../../../main/projects/ProjectPath';
import { StockPath } from '../../../../main/stock/StockPath';
import { PermissionPath } from '../../../../main/permissions/PermissionPath';
import { NotificationPath } from '../../../../main/notifications/NotificationPath';
import { InvoicePath } from '../../../../main/invoices/InvoicePath';
import { TeamPath } from '../../../../main/teams/TeamPath';

import { menuKeys } from '../../../../locales/keys/models/menu';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { AppPermissions } from '../../../appConstants';

function PrimaryNav() {
  return (
    <Fragment>
      <TooltipSingletonSourceWrapper
        placement={TooltipPlacement.RIGHT}
        withArrow
      >
        <CheckPermissions action={AppPermissions.READ_MENU_NOTIFICATIONS_TAB}>
          <PrimaryNavTab
            href={NotificationPath.index()}
            icon={IconsEnum.BELL}
            tooltipI18nText={menuKeys.tabs.notifications}
            tab={PrimaryNavTabsEnum.NOTIFICATIONS}
          />
        </CheckPermissions>

        <CheckPermissions
          action={AppPermissions.READ_MENU_TASKS_AND_PROJECTS_TAB}
        >
          <PrimaryNavTab
            href={ProjectPath.index()}
            icon={IconsEnum.PROJECTS}
            tooltipI18nText={menuKeys.tabs.workspace}
            tab={PrimaryNavTabsEnum.TASKS_AND_PROJECTS}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_COMPANY_LIBRARY_TAB}>
          <PrimaryNavTab
            href={TeamPath.currentCompanyLibrary()}
            icon={IconsEnum.CUBE_TRANSPARENT_OUTLINE}
            tooltipI18nText={menuKeys.tabs.library}
            tab={PrimaryNavTabsEnum.COMPANY_LIBRARY}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_STOCK_TAB}>
          <PrimaryNavTab
            href={StockPath.index()}
            icon={IconsEnum.CUBE_OUTLINE}
            tooltipI18nText={menuKeys.tabs.stock3d}
            tab={PrimaryNavTabsEnum.STOCK}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_PEOPLE_TAB}>
          <PrimaryNavTab
            href={TeamPath.companiesMyCompanies()}
            icon={IconsEnum.USER_GROUP}
            tooltipI18nText={menuKeys.tabs.people}
            tab={PrimaryNavTabsEnum.PEOPLE}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_PAYMENTS_TAB}>
          <PrimaryNavTab
            href={InvoicePath.paymentsInvoices()}
            icon={IconsEnum.CASH_OUTLINE}
            tooltipI18nText={menuKeys.tabs.payments}
            tab={PrimaryNavTabsEnum.PAYMENTS}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_ADMIN_TAB}>
          <PrimaryNavTab
            href={PermissionPath.index()}
            icon={IconsEnum.SHIELD_EXCLAMATION}
            tooltipI18nText={menuKeys.tabs.admin}
            tab={PrimaryNavTabsEnum.ADMIN}
          />
        </CheckPermissions>
      </TooltipSingletonSourceWrapper>
    </Fragment>
  );
}

export default memo(PrimaryNav);
