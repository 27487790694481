import { FetchAccountsSortTypes } from './accountsTypes';

export const INITIAL_ACCOUNTS_FILTERS = {};
export const INITIAL_ACCOUNTS_PAGE = 1;
export const INITIAL_ACCOUNTS_SORT = [FetchAccountsSortTypes.CREATED_AT_DESC];
export const INITIAL_ACCOUNTS_LIMIT = 50;

export const CASH_ACCOUNT_TYPE_CHART_NUMBER = '1.1.1';

export enum AccountPageTabs {
  JOURNAL_RECORDS = 'Journal Records',
  ACCOUNT_BALANCES = 'Account Balances'
}

export enum AccountsPermissions {
  READ_ACCOUNT_SHOW_PAGE = 'read_account_show_page',
  READ_PAYMENTS_BALANCE_SHEET_MENU_INDEX_LINK = 'read_payments_balance_sheet_menu_index_link',
  READ_PAYMENTS_BALANCE_SHEET_INDEX_PAGE = 'read_payments_balance_sheet_index_page',
  READ_PAYMENTS_BALANCE_SHEET_RECALCULATE_BALANCE_BUTTON = 'read_payments_balance_sheet_recalculate_balance_button',
  READ_PAYMENTS_ACCOUNTS_ASSETS_TABLE = 'read_payments_accounts_assets_table',
  READ_PAYMENTS_ACCOUNTS_EQUITY_TABLE = 'read_payments_accounts_equity_table',
  READ_PAYMENTS_ACCOUNTS_LIABILITY_TABLE = 'read_payments_accounts_liability_table',
  READ_PAYMENTS_ACCOUNTS_PROVISIONS_TABLE = 'read_payments_accounts_provisions_table',
  READ_PAYMENTS_BALANCE_SHEET_INCOME_STATEMENT_SECTION = 'read_payments_balance_sheet_income_statement_section',
  READ_PAYMENTS_ACCOUNTS_HIDE_ZERO_BALANCE_FILTER = 'read_payments_accounts_hide_zero_balance_filter',
  READ_PAYMENTS_ACCOUNTS_TYPE_FILTER = 'read_payments_accounts_type_filter',
  READ_ACCOUNTS_TABLE_SELECTED_FIELD = 'read_accounts_table_selected_field',
  READ_ACCOUNTS_TABLE_DROPDOWN_FIELD = 'read_accounts_table_dropdown_field',
  READ_ACCOUNTS_TABLE_BALANCE_FIELD = 'read_accounts_table_balance_field',
  READ_ACCOUNTS_TABLE_NUMBER_FIELD = 'read_accounts_table_number_field',
  READ_ACCOUNTS_TABLE_NUMBER_FIELD_LINK = 'read_accounts_table_number_field_link',
  READ_ACCOUNTS_TABLE_NAME_FIELD = 'read_accounts_table_name_field',
  READ_ACCOUNTS_TABLE_TRANSACTIONS_LINK = 'read_accounts_table_transactions_link',
  READ_ACCOUNT_BALANCES_PAGE = 'read_account_balances_page',
  READ_ACCOUNT_BALANCE_PAGE = 'read_account_balance_page'
}
