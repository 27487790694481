import {
  DownloadFile,
  DownloadName
} from '../../main/downloads/downloadsTypes';

export interface DownloadFileOptions {
  name: DownloadName;
  file: DownloadFile;
}

function downloadFile(downloadFile: DownloadFileOptions) {
  const link = document.createElement('a');
  link.href = downloadFile.file;
  link.download = downloadFile.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default downloadFile;
