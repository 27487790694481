export default {
  areYouSureYouWantToCancelTheTask: 'Ви впевнені, що хочете скасувати задачу?',
  areYouSureYouWantToClearSelected: 'Ви впевнені, що хочете очистити вибране?',
  approveTaskResult: 'Затвердити результати задачі',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'Оцінюючи завдання, ви приймаєте результати завдання для клієнта',
  beforeYouAcceptTheTaskWeLikeToRemindYouThat: `Перш ніж Ви приймете задачу, ми хотіли б нагадати Вам, що:`,
  onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt: `Як тільки Ви приймете задачу, вона закриється, і Ви не зможете залишати в ній нові коментарі.`,
  ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly:
    'Якщо після цього Ви хочете, щоб результат було змінено або скориговано, для цього буде створена нова задача, за яку стягуватиметься відповідна плата.',
  pleaseProvideYourAddress: 'Будь ласка, надайте вашу адресу',
  pleaseRateTheTask: 'Оцініть задачу',
  pleaseRateTaskRusult: 'Оцініть якість результату',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'Платіж прийнято в обробку. Зазвичай платіж обробляється і зараховується, або скасовується платіжною системою/банком, який випустив карту протягом 10 - 30 хвилин. У цей час не потрібно робити нових спроб оплати.',
  thePaymentHasBeenCanceled: 'Платіж скасовано.',
  youWillBeRedirectedToTheProjectPageIn:
    'Вас буде направлено на сторінку проекту через',
  youWillBeRedirectedToThePaymentsPageIn:
    'Вас буде направлено на сторінку оплати через',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Ви впевнені, що хочете видалити цей запис? Цю дію неможливо скасувати.',
  thisActionWillAffect: 'Ця дія вплине на',
  indicateHowMuchDoYouAgreeWithTheFollowingStatement:
    'Відмітьте, наскільки ви погоджуєтесь із наведеними нижче твердженнями'
};
