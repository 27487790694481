import { gql } from 'graphql-request';

import {
  UserEmail,
  UserFullName,
  UserImageFile,
  UserUUID,
  UserBalanceAmount,
  UserDebt,
  UserClient,
  UserClientBalance,
  UserWorkerBalance,
  UserCurrency,
  UserClientDebt,
  UserCurrentTeamNanoID
} from '../usersTypes';

export interface FetchUserMenuQueryResponse {
  uuid: UserUUID;
  balanceAmount: UserBalanceAmount;
  currency: UserCurrency;
  debt: UserDebt;
  email: UserEmail;
  fullName: UserFullName;
  client: UserClient;
  clientBalance: UserClientBalance;
  workerBalance: UserWorkerBalance;
  clientDebt: UserClientDebt;
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
  };
  image: {
    file: UserImageFile;
  };
}

export const FETCH_USER_MENU_QUERY = gql`
  query User($uuid: ID!) {
    user(uuid: $uuid) {
      uuid
      balanceAmount
      currency
      debt
      email
      fullName
      client
      clientDebt
      clientBalance
      currentTeam {
        nanoId
      }
      workerBalance
      image {
        file
      }
    }
  }
`;
