import { components, OptionProps } from 'react-select';

import { MultiSelectExtendedDataType } from '../../types';

import { Files } from '../../../../utils/Files';

import { ImageItemImageVersions } from '../../../ImageHelper';

function Option({
  children,
  ...props
}: OptionProps<MultiSelectExtendedDataType>) {
  return (
    <components.Option {...props}>
      {props.data?.image ? (
        <div className="flex items-center">
          <img
            className="rounded-full h-8 w-8"
            src={Files.image(
              { file: props.data.image },
              ImageItemImageVersions.Thumb160x160
            )}
          />
          <span className="pl-2">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.Option>
  );
}

export default Option;
