import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

interface WordsType {
  accordingTo: LocalesKey;
  account: LocalesKey;
  activity: LocalesKey;
  accept: LocalesKey;
  active: LocalesKey;
  add: LocalesKey;
  addMore: LocalesKey;
  addItem: LocalesKey;
  addReaction: LocalesKey;
  addService: LocalesKey;
  addToFavorites: LocalesKey;
  addUser: LocalesKey;
  addYourCommentOptional: LocalesKey;
  adorable: LocalesKey;
  allActivity: LocalesKey;
  allAttachments: LocalesKey;
  allocate: LocalesKey;
  allocateFunds: LocalesKey;
  allTime: LocalesKey;
  already_selected: LocalesKey;
  amount: LocalesKey;
  amountDue: LocalesKey;
  and: LocalesKey;
  any: LocalesKey;
  anyType: LocalesKey;
  applicationLanguage: LocalesKey;
  apply: LocalesKey;
  approve: LocalesKey;
  artists: LocalesKey;
  assignAsCreator: LocalesKey;
  assignAsOwner: LocalesKey;
  attach: LocalesKey;
  attachImage: LocalesKey;
  autoAllocate: LocalesKey;
  backToProject: LocalesKey;
  backtrace: LocalesKey;
  badDebt: LocalesKey;
  back: LocalesKey;
  block: LocalesKey;
  blocked: LocalesKey;
  brief: LocalesKey;
  browse: LocalesKey;
  budget: LocalesKey;
  cancel: LocalesKey;
  caution: LocalesKey;
  celebrate: LocalesKey;
  clap: LocalesKey;
  clear: LocalesKey;
  clearAll: LocalesKey;
  clearFilter: LocalesKey;
  clearFilters: LocalesKey;
  clearSelection: LocalesKey;
  clickToCopy: LocalesKey;
  client: LocalesKey;
  clientDebt: LocalesKey;
  clientManager: LocalesKey;
  clients: LocalesKey;
  close: LocalesKey;
  collapse: LocalesKey;
  colors: LocalesKey;
  comment: LocalesKey;
  company: LocalesKey;
  confirm: LocalesKey;
  contactSupport: LocalesKey;
  contactInfo: LocalesKey;
  continue: LocalesKey;
  control: LocalesKey;
  copyId: LocalesKey;
  copyLink: LocalesKey;
  copyUuid: LocalesKey;
  cost: LocalesKey;
  create: LocalesKey;
  created: LocalesKey;
  createNewFilter: LocalesKey;
  criticalRequirements: LocalesKey;
  change: LocalesKey;
  changeOwner: LocalesKey;
  changeCurrency: LocalesKey;
  current: LocalesKey;
  date: LocalesKey;
  dateAndTimeFormat: LocalesKey;
  dateOfBirth: LocalesKey;
  debt: LocalesKey;
  default: LocalesKey;
  delete: LocalesKey;
  denyAccess: LocalesKey;
  description: LocalesKey;
  descriptionOptional: LocalesKey;
  disable: LocalesKey;
  dislike: LocalesKey;
  download: LocalesKey;
  downloadTaskResult: LocalesKey;
  downloadAll: LocalesKey;
  downloadAllUpperCase: LocalesKey;
  dpi: LocalesKey;
  draft: LocalesKey;
  draftBudget: LocalesKey;
  drawings: LocalesKey;
  dueDate: LocalesKey;
  duplicate: LocalesKey;
  edit: LocalesKey;
  emailAddress: LocalesKey;
  emailChanged: LocalesKey;
  enable: LocalesKey;
  english: LocalesKey;
  errorMessage: LocalesKey;
  enterMessageId: LocalesKey;
  enterMessageIds: LocalesKey;
  eur: LocalesKey;
  expenses: LocalesKey;
  favorite: LocalesKey;
  files: LocalesKey;
  filter: LocalesKey;
  filterFavorite: LocalesKey;
  filters: LocalesKey;
  fin: LocalesKey;
  fire: LocalesKey;
  flagged: LocalesKey;
  forward: LocalesKey;
  for: LocalesKey;
  from: LocalesKey;
  general: LocalesKey;
  generate: LocalesKey;
  goBack: LocalesKey;
  groupBy: LocalesKey;
  gbp: LocalesKey;
  helpCenter: LocalesKey;
  hidden: LocalesKey;
  hiddenLowerCase: LocalesKey;
  hide: LocalesKey;
  hideList: LocalesKey;
  high: LocalesKey;
  images: LocalesKey;
  in: LocalesKey;
  include: LocalesKey;
  incomeStatement: LocalesKey;
  info: LocalesKey;
  itc: LocalesKey;
  its: LocalesKey;
  invisibleForClient: LocalesKey;
  invisibleForWorker: LocalesKey;
  invite_user: LocalesKey;
  joined: LocalesKey;
  languagesSpoken: LocalesKey;
  lastMonth: LocalesKey;
  lastWeek: LocalesKey;
  laughing: LocalesKey;
  leave: LocalesKey;
  leaveMessage: LocalesKey;
  like: LocalesKey;
  link: LocalesKey;
  loadMore: LocalesKey;
  location: LocalesKey;
  love: LocalesKey;
  low: LocalesKey;
  mad: LocalesKey;
  media: LocalesKey;
  members: LocalesKey;
  membersGenitiveLowerCase: LocalesKey;
  model: LocalesKey;
  more: LocalesKey;
  move: LocalesKey;
  myLibrary: LocalesKey;
  name: LocalesKey;
  nameEn: LocalesKey;
  na: LocalesKey;
  nda: LocalesKey;
  needHelp: LocalesKey;
  new: LocalesKey;
  no: LocalesKey;
  notAsked: LocalesKey;
  noResultsYet: LocalesKey;
  noSearchResults: LocalesKey;
  thereAreNoMatchesForSelectedFilters: LocalesKey;
  of: LocalesKey;
  ok: LocalesKey;
  open: LocalesKey;
  openOptions: LocalesKey;
  or: LocalesKey;
  oneDay: LocalesKey;
  oneWeek: LocalesKey;
  oneMonth: LocalesKey;
  overdue: LocalesKey;
  owner: LocalesKey;
  paid: LocalesKey;
  passwordChanged: LocalesKey;
  pay: LocalesKey;
  payByCard: LocalesKey;
  payer: LocalesKey;
  paymentMethod: LocalesKey;
  payments: LocalesKey;
  paymentTerms: LocalesKey;
  pendingPayments: LocalesKey;
  phoneNumber: LocalesKey;
  pinned: LocalesKey;
  policies: LocalesKey;
  postMessage: LocalesKey;
  price: LocalesKey;
  privacy: LocalesKey;
  privacyPolicy: LocalesKey;
  proceed: LocalesKey;
  processing: LocalesKey;
  profile: LocalesKey;
  progress: LocalesKey;
  proforma: LocalesKey;
  px: LocalesKey;
  quantity: LocalesKey;
  receiveNotifications: LocalesKey;
  recentlySelected: LocalesKey;
  references: LocalesKey;
  refund: LocalesKey;
  refunds: LocalesKey;
  remove: LocalesKey;
  removeFromFavorites: LocalesKey;
  requirements: LocalesKey;
  root: LocalesKey;
  rub: LocalesKey;
  russian: LocalesKey;
  sad: LocalesKey;
  save: LocalesKey;
  saveChanges: LocalesKey;
  saveFilter: LocalesKey;
  savedFilters: LocalesKey;
  scopeOfWork: LocalesKey;
  search: LocalesKey;
  searchResults: LocalesKey;
  search_user_by_name: LocalesKey;
  searching: LocalesKey;
  secondsGenitive: LocalesKey;
  select: LocalesKey;
  selectToAddToTheProject: LocalesKey;
  selected: LocalesKey;
  send: LocalesKey;
  sender: LocalesKey;
  sendInstructions: LocalesKey;
  sendToAllTasks: LocalesKey;
  sendViaEmail: LocalesKey;
  sendToEmail: LocalesKey;
  sent: LocalesKey;
  serviceAgreement: LocalesKey;
  serviceName: LocalesKey;
  setDueDate: LocalesKey;
  settings: LocalesKey;
  sortByNewest: LocalesKey;
  sortByMostLiked: LocalesKey;
  sortByMostUsed: LocalesKey;
  newest: LocalesKey;
  mostLiked: LocalesKey;
  mostUsed: LocalesKey;
  show: LocalesKey;
  showing: LocalesKey;
  showAll: LocalesKey;
  showLess: LocalesKey;
  showList: LocalesKey;
  showMore: LocalesKey;
  showFavoriteOnly: LocalesKey;
  showMyLibrary: LocalesKey;
  sketches: LocalesKey;
  sku: LocalesKey;
  starred: LocalesKey;
  startDate: LocalesKey;
  state: LocalesKey;
  status: LocalesKey;
  statusColon: LocalesKey;
  submit: LocalesKey;
  success: LocalesKey;
  surnameEn: LocalesKey;
  surprised: LocalesKey;
  system: LocalesKey;
  stopAll: LocalesKey;
  task: LocalesKey;
  tbd: LocalesKey;
  terrified: LocalesKey;
  terms: LocalesKey;
  termsOfService: LocalesKey;
  textHere: LocalesKey;
  textures: LocalesKey;
  tfe: LocalesKey;
  testProjectInfo: LocalesKey;
  threeDStock: LocalesKey;
  threeDs: LocalesKey;
  time: LocalesKey;
  timeZone: LocalesKey;
  to: LocalesKey;
  today: LocalesKey;
  total: LocalesKey;
  totalDebt: LocalesKey;
  trackedTime: LocalesKey;
  tryAgain: LocalesKey;
  uah: LocalesKey;
  ukraine: LocalesKey;
  underpayment: LocalesKey;
  unselectAllItems: LocalesKey;
  unblock: LocalesKey;
  update: LocalesKey;
  updateExistingFilter: LocalesKey;
  updateImageVersions: LocalesKey;
  updated: LocalesKey;
  usd: LocalesKey;
  user: LocalesKey;
  view: LocalesKey;
  viewAccounts: LocalesKey;
  viewAll: LocalesKey;
  viewAllMembers: LocalesKey;
  viewCR: LocalesKey;
  viewProfile: LocalesKey;
  visibility: LocalesKey;
  visibilityForClient: LocalesKey;
  visibilityForWorker: LocalesKey;
  visible: LocalesKey;
  visibleForClient: LocalesKey;
  welcomeToArchivizer: LocalesKey;
  workers: LocalesKey;
  workflowGuide: LocalesKey;
  writeHere: LocalesKey;
  yes: LocalesKey;
  colorSchemeDark: LocalesKey;
  colorSchemeLight: LocalesKey;
  colorScheme: LocalesKey;
  tax: LocalesKey;
  timer: {
    days: LocalesKey;
    hours: LocalesKey;
    minutes: LocalesKey;
    seconds: LocalesKey;
  };
  letters: {
    h: LocalesKey;
    m: LocalesKey;
  };
  receiver: LocalesKey;
  selectAll: LocalesKey;
  void: LocalesKey;
  upload: LocalesKey;
  lessWeek: LocalesKey;
  lessTwoWeek: LocalesKey;
  lessMonth: LocalesKey;
  lessTwoMonth: LocalesKey;
  moreTwoMonth: LocalesKey;
  yesterday: LocalesKey;
}

export const words = keyPathMirror<WordsType, string>(
  {
    accordingTo: null,
    account: null,
    activity: null,
    accept: null,
    active: null,
    add: null,
    addItem: null,
    addMore: null,
    addReaction: null,
    addService: null,
    addToFavorites: null,
    addUser: null,
    addYourCommentOptional: null,
    adorable: null,
    allActivity: null,
    allAttachments: null,
    allocate: null,
    allocateFunds: null,
    allTime: null,
    already_selected: null,
    amount: null,
    amountDue: null,
    and: null,
    any: null,
    anyType: null,
    applicationLanguage: null,
    apply: null,
    approve: null,
    artists: null,
    assignAsCreator: null,
    assignAsOwner: null,
    attach: null,
    attachImage: null,
    autoAllocate: null,
    backToProject: null,
    backtrace: null,
    badDebt: null,
    back: null,
    block: null,
    blocked: null,
    brief: null,
    browse: null,
    budget: null,
    cancel: null,
    caution: null,
    celebrate: null,
    clap: null,
    clear: null,
    clearAll: null,
    clearFilter: null,
    clearFilters: null,
    clearSelection: null,
    clickToCopy: null,
    client: null,
    clientDebt: null,
    clientManager: null,
    clients: null,
    close: null,
    collapse: null,
    colors: null,
    comment: null,
    company: null,
    confirm: null,
    contactSupport: null,
    contactInfo: null,
    continue: null,
    control: null,
    copyId: null,
    copyLink: null,
    copyUuid: null,
    cost: null,
    create: null,
    created: null,
    createNewFilter: null,
    criticalRequirements: null,
    change: null,
    changeOwner: null,
    changeCurrency: null,
    current: null,
    date: null,
    dateAndTimeFormat: null,
    dateOfBirth: null,
    debt: null,
    default: null,
    delete: null,
    denyAccess: null,
    description: null,
    descriptionOptional: null,
    disable: null,
    dislike: null,
    download: null,
    downloadTaskResult: null,
    downloadAll: null,
    downloadAllUpperCase: null,
    dpi: null,
    draft: null,
    draftBudget: null,
    drawings: null,
    dueDate: null,
    duplicate: null,
    edit: null,
    emailAddress: null,
    emailChanged: null,
    enable: null,
    english: null,
    errorMessage: null,
    enterMessageId: null,
    enterMessageIds: null,
    eur: null,
    expenses: null,
    favorite: null,
    files: null,
    filter: null,
    filterFavorite: null,
    filters: null,
    fin: null,
    fire: null,
    flagged: null,
    forward: null,
    for: null,
    from: null,
    general: null,
    generate: null,
    goBack: null,
    groupBy: null,
    gbp: null,
    helpCenter: null,
    hidden: null,
    hiddenLowerCase: null,
    hide: null,
    hideList: null,
    high: null,
    images: null,
    in: null,
    include: null,
    incomeStatement: null,
    info: null,
    invisibleForClient: null,
    invisibleForWorker: null,
    invite_user: null,
    itc: null,
    its: null,
    joined: null,
    languagesSpoken: null,
    lastMonth: null,
    lastWeek: null,
    laughing: null,
    leave: null,
    leaveMessage: null,
    like: null,
    link: null,
    loadMore: null,
    location: null,
    love: null,
    low: null,
    mad: null,
    media: null,
    members: null,
    membersGenitiveLowerCase: null,
    model: null,
    more: null,
    move: null,
    myLibrary: null,
    name: null,
    nameEn: null,
    na: null,
    nda: null,
    needHelp: null,
    new: null,
    no: null,
    notAsked: null,
    noResultsYet: null,
    noSearchResults: null,
    thereAreNoMatchesForSelectedFilters: null,
    of: null,
    ok: null,
    open: null,
    openOptions: null,
    or: null,
    oneDay: null,
    oneWeek: null,
    oneMonth: null,
    overdue: null,
    owner: null,
    paid: null,
    passwordChanged: null,
    pay: null,
    payByCard: null,
    payer: null,
    paymentMethod: null,
    payments: null,
    paymentTerms: null,
    pendingPayments: null,
    phoneNumber: null,
    pinned: null,
    policies: null,
    postMessage: null,
    price: null,
    privacy: null,
    privacyPolicy: null,
    proceed: null,
    processing: null,
    profile: null,
    progress: null,
    proforma: null,
    px: null,
    quantity: null,
    receiveNotifications: null,
    recentlySelected: null,
    references: null,
    refund: null,
    refunds: null,
    remove: null,
    removeFromFavorites: null,
    requirements: null,
    root: null,
    rub: null,
    russian: null,
    sad: null,
    save: null,
    saveChanges: null,
    saveFilter: null,
    savedFilters: null,
    scopeOfWork: null,
    search: null,
    searchResults: null,
    search_user_by_name: null,
    searching: null,
    secondsGenitive: null,
    select: null,
    selectToAddToTheProject: null,
    selected: null,
    send: null,
    sender: null,
    sendInstructions: null,
    sendToAllTasks: null,
    sendViaEmail: null,
    sendToEmail: null,
    sent: null,
    serviceAgreement: null,
    serviceName: null,
    setDueDate: null,
    settings: null,
    show: null,
    showing: null,
    showAll: null,
    showLess: null,
    showList: null,
    showMore: null,
    showMyLibrary: null,
    showFavoriteOnly: null,
    sortByNewest: null,
    sortByMostLiked: null,
    sortByMostUsed: null,
    textHere: null,
    newest: null,
    mostLiked: null,
    mostUsed: null,
    sketches: null,
    sku: null,
    starred: null,
    startDate: null,
    state: null,
    status: null,
    statusColon: null,
    submit: null,
    success: null,
    surnameEn: null,
    surprised: null,
    system: null,
    stopAll: null,
    task: null,
    tbd: null,
    terrified: null,
    terms: null,
    termsOfService: null,
    tfe: null,
    textures: null,
    testProjectInfo: null,
    threeDStock: null,
    threeDs: null,
    time: null,
    timeZone: null,
    to: null,
    today: null,
    total: null,
    totalDebt: null,
    trackedTime: null,
    tryAgain: null,
    uah: null,
    ukraine: null,
    underpayment: null,
    unselectAllItems: null,
    unblock: null,
    update: null,
    updateExistingFilter: null,
    updateImageVersions: null,
    updated: null,
    usd: null,
    user: null,
    view: null,
    viewAccounts: null,
    viewAll: null,
    viewAllMembers: null,
    viewCR: null,
    viewProfile: null,
    visibility: null,
    visibilityForClient: null,
    visibilityForWorker: null,
    visible: null,
    visibleForClient: null,
    welcomeToArchivizer: null,
    workers: null,
    workflowGuide: null,
    writeHere: null,
    yes: null,
    tax: null,
    timer: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    },
    colorScheme: null,
    colorSchemeDark: null,
    colorSchemeLight: null,
    letters: {
      h: null,
      m: null
    },
    receiver: null,
    selectAll: null,
    void: null,
    upload: null,
    lessWeek: null,
    lessTwoWeek: null,
    lessMonth: null,
    lessTwoMonth: null,
    moreTwoMonth: null,
    yesterday: null
  },
  'words.'
);
