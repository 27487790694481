export default {
  addToDescription: 'Add to description',
  all: 'All messages',
  areYouSureYouWantToSendThisMessageToAllProjectTasks:
    'Are you sure you want to send this message to all project tasks?',
  areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible:
    'Are you sure you want to mark all messages as read? This action is irreversible.',
  authors: 'Message authors',
  emailNotificationSent: 'Email notification sent',
  flagged: 'Flagged',
  forward: 'Forward message',
  forwardMessageFrom: 'Forward message from',
  forwardedFrom: 'Forwarded from',
  fromClients: 'From clients',
  inProjectsWhereIAmAMember: 'In projects where I am a member',
  inTasksWhereIAmAMember: 'In tasks where I am a member',
  markAllAsRead: 'Mark all as read',
  markAsRead: 'Mark as read',
  markAsUnread: 'Mark as unread',
  messageId: 'Message ID',
  messageIds: 'Message IDs',
  messageWithAttachments: 'Message with attachments',
  my: 'My messages',
  new: 'New',
  noPlural: 'No messages',
  passwordWasSuccessfullyUpdated: 'Password was successfully updated.',
  pinnedMessages: 'Pinned messages',
  plural: 'Messages',
  project: 'Project messages',
  search: 'Search messages',
  selectAuthor: 'Select author',
  send: 'Send message',
  sendEmailNotification: 'Send email notification',
  sending: 'Sending',
  sent: 'Message sent',
  showOlder: 'Show older messages',
  singular: 'Message',
  starred: 'Starred',
  toLatestMessages: 'To latest messages',
  tracked: 'tracked',
  visible: 'Visible',
  withAttachments: 'With attachments'
};
