import React, { Fragment, ReactNode } from 'react';

import { PathUrl } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';
import { TeamNanoID } from '../../../main/teams/teamsTypes';
import { UserNanoID } from '../../../main/users/usersTypes';

import { useAuth } from '../../../auth/hooks/useAuth';

import { ThirdPersonViewProvider } from '../../hooks/useThirdPersonView';
import { MenuProvider } from '../../hooks/useMenu';

import { PrimaryMenu } from '../../components/menus/PrimaryMenu';
import { TpvFooter } from '../../../main/common/components/footers/TpvFooter';

import { CompanyViewHeadCompany } from './components/CompanyViewHeadCompany';
import { CompanyViewHeadUser } from './components/CompanyViewHeadUser';

import { HeadTitle } from '../../../helpers/HeadTitle';
import { LoadingSkeleton } from '../../../helpers/LoadingSkeleton';
import { NextPureLinkHelper } from '../../../helpers/links/NextPureLinkHelper';

import { MainPath } from '../../../main/MainPath';

interface CompanyViewAppLayoutProps {
  children: ReactNode;
  companyNanoId: TeamNanoID;
  userNanoId?: UserNanoID;
  nonTpvLastPath?: PathUrl;
}

function CompanyViewAppLayout({
  children,
  companyNanoId,
  userNanoId,
  nonTpvLastPath
}: CompanyViewAppLayoutProps) {
  const auth = useAuth();

  return (
    <ThirdPersonViewProvider companyNanoId={companyNanoId}>
      <MenuProvider userNanoId={userNanoId}>
        <HeadTitle />
        <div className="bg-sky-900 px-2 py-1 flex flex-col gap-1 h-full w-full">
          <div className="flex justify-between">
            <div className="text-2xs 2xl:text-sm text-white flex gap-1 items-center leading-none">
              <NextPureLinkHelper
                className="rounded hover:bg-white/20 py-0.5 2xl:py-1 px-3 2xl:px-5 focus:ring-base focus:ring-0"
                href={nonTpvLastPath || MainPath.index()}
                icon={IconsEnum.ARROW_NARROW_RIGHT_SOLID}
                iconClassName="h-4 w-4 2xl:h-6 2xl:w-6 transform rotate-180"
              />

              {auth?.authFetched && auth?.isAuthenticated ? (
                <Fragment>
                  <CompanyViewHeadCompany companyNanoId={companyNanoId} />
                  <CompanyViewHeadUser
                    companyNanoId={companyNanoId}
                    userNanoId={userNanoId}
                  />
                </Fragment>
              ) : null}
            </div>
            <div className="text-2xs text-white flex gap-2 items-center leading-none">
              {/* help */}
            </div>
          </div>
          <div className="relative flex flex-1 flex-col-reverse sm:flex-row w-full h-full rounded-xl overflow-hidden bg-white dark:bg-gray-900 shadow z-0">
            <Fragment>
              <PrimaryMenu userNanoId={userNanoId} />
              {auth?.authFetched && auth?.isAuthenticated ? (
                children
              ) : (
                <div
                  id="main-section"
                  className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
                >
                  <LoadingSkeleton loaded={false} />
                </div>
              )}
            </Fragment>
          </div>
          {auth?.authFetched && auth?.isAuthenticated ? <TpvFooter /> : null}
        </div>
      </MenuProvider>
    </ThirdPersonViewProvider>
  );
}

export default CompanyViewAppLayout;
