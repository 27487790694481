import { ItemsKeyType } from '../../keys';

const itemsKeys: ItemsKeyType = {
  add: 'Add item',
  areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction:
    'Are you sure you want to delete this item? You will not be able to undo this action.',
  bind: 'Bind',
  bound: 'Bound',
  createDate: 'Create date',
  description: 'Description',
  hideCompleted: 'Hide completed items',
  id: 'ID',
  move: 'Move item',
  moveTo: 'Move to',
  invoice: 'Invoice',
  mixed: 'Mixed',
  name: 'Item name',
  noScopeOfWorkYet: 'No scope of work yet',
  payFromDeposit: 'Pay from deposit',
  plural: 'Items',
  pluralLowerCase: 'items',
  price: 'Price',
  postpone: 'Postpone',
  postponed: 'Postponed',
  postponedItems: 'Postponed items',
  postponedItemsTotal: 'Postponed items total',
  qty: 'Qty',
  rendering: 'Rendering',
  scopeOfWork: 'Scope of work',
  select: 'Select item...',
  selectedTotal: 'Selected items total',
  shownTotal: 'Shown items total',
  singular: 'Item',
  singularLowerCase: 'item',
  status: 'Status',
  subTotal: 'Subtotal',
  threeDRenderingServicesPrepayment: '3D rendering services prepayment',
  unbind: 'Unbind',
  unbound: 'Unbound',
  typeNotesHere: 'Type notes here...',
  youShouldAddAtLeastOneItem: 'You should add at least one item..'
};

export default itemsKeys;
