export enum AppPermissions {
  READ_MENU_DASHBOARD_TAB = 'read_menu_dashboard_tab',
  READ_MENU_DASHBOARD_LINK = 'read_menu_dashboard_link',
  READ_MENU_NOTIFICATIONS_TAB = 'read_menu_notifications_tab',
  READ_MENU_TASKS_AND_PROJECTS_TAB = 'read_menu_tasks_and_projects_tab',
  READ_MENU_STOCK_TAB = 'read_menu_stock_tab',
  READ_MENU_COMPANY_LIBRARY_TAB = 'read_menu_company_library_tab',
  READ_MENU_USERS_TAB = 'read_menu_users_tab',
  READ_MENU_WORKERS_TAB = 'read_menu_workers_tab',
  READ_MENU_PEOPLE_TAB = 'read_menu_people_tab',
  READ_MENU_CLIENTS_TAB = 'read_menu_clients_tab',
  READ_MENU_ADMIN_TAB = 'read_menu_admin_tab',
  READ_MENU_PAYMENTS_TAB = 'read_menu_payments_tab',
  READ_COMPANY_VIEW_MENU_DASHBOARD_TAB = 'read_company_view_menu_dashboard_tab',
  READ_COMPANY_VIEW_MENU_PAYMENTS_TAB = 'read_company_view_menu_payments_tab',
  READ_SELF_COMPANY_USER_VIEW_MENU_WORKSPACE_TAB = 'read_self_company_user_view_menu_workspace_tab',
  READ_OTHER_COMPANY_USER_VIEW_MENU_WORKSPACE_TAB = 'read_other_company_user_view_menu_workspace_tab',
  READ_SELF_COMPANY_VIEW_MENU_WORKSPACE_TAB = 'read_self_company_view_menu_workspace_tab',
  READ_OTHER_COMPANY_VIEW_MENU_WORKSPACE_TAB = 'read_other_company_view_menu_workspace_tab',
  READ_SELF_COMPANY_VIEW_MENU_LIBRARY_TAB = 'read_self_company_view_menu_library_tab',
  READ_OTHER_COMPANY_VIEW_MENU_LIBRARY_TAB = 'read_other_company_view_menu_library_tab',
  READ_TASKS_AND_PROJECTS_MENU_TASKS_LINK = 'read_tasks_and_projects_menu_tasks_link',
  READ_TASKS_AND_PROJECTS_MENU_PROJECTS_LINK = 'read_tasks_and_projects_menu_projects_link',
  READ_TASKS_AND_PROJECTS_MENU_MESSAGES_LINK = 'read_tasks_and_projects_menu_messages_link'
}
