import React, { Fragment } from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ClassName } from '../../../../../../../types';

import { useDownloadManagerModal } from './hooks/useDownloadManagerModal';

import { DownloadManagerModalItems } from './components/DownloadManagerModalItems';

import { PureIconBadgeButtonHelper } from '../../../../../../../helpers/buttons/PureIconBadgeButtonHelper';
import { SimpleModal } from '../../../../../../../helpers/modals/SimpleModal';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { downloadsKeys, words } from '../../../../../../../locales/keys';

interface DownloadManagerModalButtonProps {
  processingClassName?: ClassName;
  staleClassName?: ClassName;
  i18nTextClassName?: ClassName;
  badgeClassName?: ClassName;
}

function UserDownloadManagerModalButton({
  processingClassName,
  staleClassName,
  i18nTextClassName,
  badgeClassName
}: DownloadManagerModalButtonProps) {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    closeDownloadManagerBlock,
    openDownloadManagerBlock,
    processingDownloadsCount,
    totalProgress,
    handleStopAll,
    handleClear,
    deleteDownloadsErrorMessage,
    deleteDownloadsLoading
  } = useDownloadManagerModal();

  if (!downloadsFetched) {
    return null;
  }

  return (
    <Fragment>
      <PureIconBadgeButtonHelper
        className={cl(
          processingDownloadsCount === 0 ? staleClassName : processingClassName,
          !processingClassName && !staleClassName
            ? 'focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white items-center gap-1.5 mb-px overflow-hidden sm:flex'
            : null,
          processingDownloadsCount === 0 ? 'hidden' : 'flex'
        )}
        i18nTextClassName={i18nTextClassName}
        i18nText={downloadsKeys.plural}
        icon={IconsEnum.ARROW_DOWN}
        iconClassName={cl('w-3.5 h-3.5', {
          'cycle-to-b': processingDownloadsCount > 0
        })}
        badgeValue={totalProgress}
        badgeSuffix="%"
        badgeClassName={badgeClassName}
        badgeAddClassName={processingDownloadsCount === 0 && 'hidden'}
        onClick={openDownloadManagerBlock}
        badgeDecimalScale={0}
      />
      <SimpleModal
        i18nTitle={downloadsKeys.plural}
        isOpen={downloadManagerBlockOpened}
        hideModal={closeDownloadManagerBlock}
        withoutInitialFocus
        submitClass="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
        submitButtonColor="gray"
        handleSubmit={handleStopAll}
        i18nSubmitText={words.stopAll}
        onCancel={handleClear}
        i18nCancelText={words.clear}
        closeOnCancel={false}
        cancelButtonPosition="start"
        childrenClassName="flex-1 overflow-y-auto px-2 z-0"
        withoutButtons={isEmpty(downloads)}
        isLoading={deleteDownloadsLoading}
      >
        <DownloadManagerModalItems
          downloads={downloads}
          downloadsFetched={downloadsFetched}
          downloadsError={downloadsError}
        />

        <div className="px-4">
          <AlertMessage message={deleteDownloadsErrorMessage} />
        </div>
      </SimpleModal>
    </Fragment>
  );
}

export default UserDownloadManagerModalButton;
