import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchUserMenuQueryResponse,
  FETCH_USER_MENU_QUERY
} from '../../../../../../users/queries/fetchUserMenu.query';

import { useCompanyCashAccount } from '../../../../../../accounts/hooks/useCompanyCashAccount';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useUser } from '../../../../../../users/hooks/useUser';

import { NextPureIconBadgeLinkHelper } from '../../../../../../../helpers/links/NextPureIconBadgeLinkHelper';

import { InvoicePath } from '../../../../../../invoices/InvoicePath';
import { UserCache } from '../../../../../../users/UserCache';
import { profilesKeys } from '../../../../../../../locales/keys';

interface UserFinanceDebitFinBadgeLinkProps {
  className?: ClassName;
  badgeClassName?: ClassName;
}

function UserFinanceDebitFinBadgeLink({
  className,
  badgeClassName
}: UserFinanceDebitFinBadgeLinkProps) {
  const currentUser = useCurrentUser();

  const { user } = useUser<FetchUserMenuQueryResponse>({
    cacheKey: UserCache.showMenuCacheKey(),
    query: FETCH_USER_MENU_QUERY,
    uuid: currentUser.nanoId
  });

  const { companyCashAccount } = useCompanyCashAccount({
    companyNanoId: user?.currentTeam?.nanoId
  });

  if (!user && !companyCashAccount) {
    return null;
  }

  return (
    <NextPureIconBadgeLinkHelper
      className={
        className ||
        'focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white hidden sm:flex items-center gap-1.5 mb-px'
      }
      badgeClassName={badgeClassName}
      i18nText={profilesKeys.balance}
      icon={IconsEnum.CASH_OUTLINE}
      iconClassName="w-3.5 h-3.5"
      badgeValue={companyCashAccount?.balance || 0}
      badgePrefix="$$"
      href={InvoicePath.paymentsInvoices()}
    />
  );
}

export default UserFinanceDebitFinBadgeLink;
