export default {
  accountNanoId: 'Account NanoID',
  accountBalances: 'Account Balances',
  balance: 'Balance',
  balances: 'Balances',
  balanceSheet: 'Balance sheet',
  bookId: 'Book ID',
  cash: 'Cash',
  credit: 'Credit',
  createAnAccount: 'Create an account',
  createNewAccount: 'create new account',
  createArtistAccount: 'Create artist account',
  createWorkerAccount: 'Create worker account',
  chartNumber: 'Chart number',
  forgotYourPasswordQuestion: 'Forgot your password?',
  hideZeroBalances: 'Hide zero balances',
  name: 'Account name',
  number: 'Number',
  noAccountsYet: 'No accounts yet',
  search: 'Search accounts',
  plural: 'Accounts',
  receivable: 'Receivable',
  signIn: 'Sign in',
  signUp: 'Sign up',
  signInToYourAccount: 'sign in to your account',
  singular: 'Account',
  types: {
    name: 'Account name',
    all: 'All',
    assets: 'Assets',
    depositAndExpense: 'Deposit & Expense',
    expenses: 'Expenses',
    equity: 'Equity',
    liabilities: 'Liabilities',
    provisions: 'Provisions',
    revenue: 'Revenue',
    revenueAndPayout: 'Revenue & PayOut'
  },
  viewTransactions: 'View transactions'
};
