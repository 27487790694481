import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  CreatedAt,
  CreateItemCacheKey,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  DefaultAt,
  DeleteItemCacheKey,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  NanoID,
  NanoIDFilter,
  NanoIdFilterType,
  UpdatedAt,
  UpdateItemCacheKey,
  UpdateItemCacheKeys,
  UpdateItemIsLoading,
  UUID
} from '../../types';
import { AvBillingInfoID } from '../avBillingInfos/avBillingInfosTypes';
import { CompanyNanoID } from '../companies/companiesTypes';
import { InvoicePaymentMethods } from '../invoices/invoicesTypes';

export type BillingInfoID = ID;
export type BillingInfoUUID = UUID;
export type BillingInfoNanoID = NanoID;
export type BillingInfoCreatedAt = CreatedAt;
export type BillingInfoUpdatedAt = UpdatedAt;
export type BillingInfoDefaultAt = DefaultAt;
export type BillingInfoWireDefaultAt = DefaultAt;
export type BillingInfoCardDefaultAt = DefaultAt;
export type BillingInfoCompanyName = string;
export type BillingInfoFirstName = string;
export type BillingInfoLastName = string;
export type BillingInfoContactPhone = string;
export type BillingInfoContactEmail = string;
export type BillingInfoCountry = string;
export type BillingInfoState = string;
export type BillingInfoCity = string;
export type BillingInfoZipCode = string;
export type BillingInfoAvBillingInfoID = AvBillingInfoID;
export type BillingInfoAddress = string;
export type BillingInfoCompanyNanoID = CompanyNanoID;
export type BillingInfoPaymentMethod = InvoicePaymentMethods;

export const enum FetchBillingInfosSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export interface FetchBillingInfosFilters {
  companyNanoId?: NanoIDFilter;
}

export interface FetchFinBillingInfosFilters {
  companyNanoId?: NanoIdFilterType;
}

export type FetchBillingInfosGqlQuery = FetchItemsGqlQuery;

export type FetchBillingInfosCacheKey = FetchItemsCacheKey;

export type FetchBillingInfosSort = FetchItemsSort;
export type FetchBillingInfosSortBillingInfos = FetchItemsSortItems;
export type FetchBillingInfosPage = FetchItemsPage;
export type FetchBillingInfosLimit = FetchItemsLimit;
export type FetchBillingInfosErrorMessage = FetchItemsErrorMessage;
export type FetchBillingInfosFetched = FetchItemsFetched;
export type FetchBillingInfosIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchBillingInfosPaginateBillingInfos = FetchItemsPaginateItems;
export type FetchBillingInfosPrefetchBillingInfos = FetchItemsPrefetchItems;
export type FetchBillingInfosTotalCount = FetchItemsTotalCount;

export type FetchBillingInfosCheckedAll = CheckedAll;
export type FetchBillingInfosOnCheckAll = OnCheckAll;
export type FetchBillingInfosChecked = Checked;
export type FetchBillingInfosCheckedHash = CheckedHashItem;
export type FetchBillingInfosOnSetCheckedIds = OnSetCheckedIds;

export type FetchBillingInfosShowBillingInfo = FetchItemsShowItem;
export type FetchBillingInfosEditBillingInfo = FetchItemsEditItem;

export const enum FetchBillingInfosSortableFields {
  NAME = 'name',
  COMPANY_NAME = 'companyName',
  CONTACT_NAME = 'contactName',
  CONTACT_PHONE = 'contactPhone',
  CONTACT_EMAIL = 'contactEmail',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  CREATED_AT = 'createdAt',
  AV_BILLING_INFO_ID = 'avBillingInfoId'
}

export const enum BillingInfoFields {
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY_NAME = 'companyName',
  CONTACT_PHONE = 'contactPhone',
  CONTACT_EMAIL = 'contactEmail',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  DEFAULT_AT = 'defaultAt',
  AV_BILLING_INFO_ID = 'avBillingInfoId',
  PAYMENT_METHOD = 'paymentMethod'
}

export type CreateBillingInfoIsLoading = CreateItemIsLoading;
export type CreateBillingInfoCacheKey = CreateItemCacheKey;
export type CreateBillingInfoCacheKeys = CreateItemCacheKeys;

export type DeleteBillingInfoCacheKeys = DeleteItemCacheKeys;
export type DeleteBillingInfoCacheKey = DeleteItemCacheKey;

export type UpdateBillingInfoIsLoading = UpdateItemIsLoading;
export type UpdateBillingInfoCacheKey = UpdateItemCacheKey;
export type UpdateBillingInfoCacheKeys = UpdateItemCacheKeys;

export type DefaultBillingInfoCacheKey = UpdateItemCacheKey;
export type MakeDefaultBillingInfoCacheKeys = UpdateItemCacheKeys;
