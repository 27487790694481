import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { SmartContractNanoID } from '../smartContracts/smartContractsTypes';
import { UserNanoID } from '../users/usersTypes';

import { TaskNanoID, TaskStatuses } from './tasksTypes';

const basePath = '/tasks';

export class TaskPath {
  static index() {
    return basePath;
  }

  static show(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}`;
  }

  static brief(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/brief`;
  }

  static burning() {
    return `${basePath}/filters/burning`;
  }

  static invitations() {
    return `${basePath}/invitations`;
  }

  static favorite() {
    return `${basePath}/filters/favorite`;
  }

  static myTasks() {
    return `${basePath}/filters/my-tasks`;
  }

  static withoutSourceFiles() {
    return `${basePath}/filters/without-source-files`;
  }

  static currentTasks() {
    return `${basePath}/filters/current-tasks`;
  }

  static myResults() {
    return `${basePath}/filters/my-results`;
  }

  static itemsResults() {
    return `${basePath}/results`;
  }

  static itemsResultsFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/results/filters/${searchFilterNanoId}`;
  }

  static userTasks(userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${userNanoId}`;
  }

  static userTasksByStatus(userNanoId: UserNanoID, status: TaskStatuses) {
    return `${basePath}/filters/user/${userNanoId}/${status}`;
  }

  static userResults(userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${userNanoId}/results`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }

  static accept(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/accept`;
  }

  static attachments(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/attachments`;
  }

  static files(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/files`;
  }

  static images(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/images`;
  }

  static sourceFiles(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/source-files`;
  }

  static products(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/products`;
  }

  static lifestyles(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/lifestyles`;
  }

  static materials(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/materials`;
  }

  static results(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/results`;
  }

  static team(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/team`;
  }

  static notifications(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/notifications`;
  }

  static transactions(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/transactions`;
  }

  static activity(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/activity`;
  }

  static smartContracts(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/smart-contracts`;
  }

  static journalRecords(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/journal-records`;
  }

  static taskSmartContracts(
    taskNanoId: TaskNanoID,
    smartContractNanoId: SmartContractNanoID
  ) {
    return `${basePath}/${taskNanoId}/smart-contracts/${smartContractNanoId}`;
  }
}
