import React from 'react';
import cl from 'classnames';

import { TeamAvatarTeam } from './TeamAvatar.types';

import { ImageItemImageVersions } from '../../../../helpers/ImageHelper';
import { Files } from '../../../../utils/Files';

interface TeamAvatarClassNameProps {
  className: string;
  type?: 'main' | 'xl' | 'xs';
  team: TeamAvatarTeam;
  width?: number;
  height?: number;
}

interface TeamAvatarTypedProps {
  className?: string;
  type: 'main' | 'xl' | 'xs';
  team: TeamAvatarTeam;
  width?: number;
  height?: number;
}

type TeamAvatarProps = TeamAvatarClassNameProps | TeamAvatarTypedProps;

function TeamAvatar({ className, type, team, width, height }: TeamAvatarProps) {
  return (
    <img
      className={
        className ||
        cl({
          'max-w-none h-9 w-9 rounded-full bg-gray-200 dark:bg-gray-800':
            type === 'main',
          'max-w-none h-9 w-9 rounded-full ring-2 ring-white dark:ring-gray-900 group-hover:ring-gray-200 dark:group-hover:ring-gray-700 bg-gray-200 dark:bg-gray-800':
            type === 'xl',
          'max-w-none h-6 w-6 rounded-full ring-2 ring-white dark:ring-gray-900 group-hover:ring-gray-200 dark:group-hover:ring-gray-700 bg-gray-200 dark:bg-gray-800':
            type === 'xs'
        })
      }
      src={Files.image(team.image, ImageItemImageVersions.Thumb160x160)}
      alt={team.name}
      title={team.name}
      width={width}
      height={height}
    />
  );
}

export default TeamAvatar;
