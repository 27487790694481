import { TeamNanoID } from '../teams/teamsTypes';

export class BillingInfoCache {
  static teamCacheKey(teamNanoId: TeamNanoID) {
    return `billing-infos-team-${teamNanoId}`;
  }
  static addFundsCacheKey(teamNanoId: TeamNanoID) {
    return `billing-infos-add-funds-${teamNanoId}`;
  }
}
