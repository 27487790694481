import { useShowQuery } from '../../../common/hooks/base/reactQuery/useShowQuery';
import {
  FetchUserGqlQuery,
  FetchUserProfileCacheKey,
  UserNanoID,
  UserUUID
} from '../../usersTypes';

interface UserResponse<ItemType> {
  user: ItemType;
}

interface UserOptions {
  cacheKey: FetchUserProfileCacheKey;
  query: FetchUserGqlQuery;
  uuid: UserUUID | UserNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

interface UseUserItem {
  uuid: UserUUID;
}

const itemKey = 'user';

function useUser<ItemType extends UseUserItem>({
  cacheKey,
  query,
  uuid,
  options = {}
}: UserOptions) {
  const { item, itemErrorData, itemError, itemLoading, itemFetched } =
    useShowQuery<UserResponse<ItemType>, ItemType>({
      cacheKey,
      itemKey,
      query,
      uuid,
      options,
      placeholderData: null
    });

  return {
    user: item,
    userErrorData: itemErrorData,
    userError: itemError,
    userLoading: itemLoading,
    userFetched: itemFetched
  };
}

export default useUser;
