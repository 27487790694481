import React, { memo } from 'react';
import size from 'lodash/size';

import {
  FetchDownloadsErrorMessage,
  FetchDownloadsFetched
} from '../../../../../../../../downloads/downloadsTypes';

import {
  DownloadManagerModalItem,
  DownloadManagerModalItemDownloads
} from '../DownloadManagerModalItem';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../../../helpers/Translate';

import { words } from '../../../../../../../../../locales/keys';

interface DownloadManagerModalItemsProps {
  downloads: DownloadManagerModalItemDownloads;
  downloadsFetched: FetchDownloadsFetched;
  downloadsError: FetchDownloadsErrorMessage;
}

function DownloadManagerModalItems({
  downloads,
  downloadsFetched,
  downloadsError
}: DownloadManagerModalItemsProps) {
  return (
    <div className="pt-6 pb-8">
      <div className="sm:px-2">
        <AlertMessage message={downloadsError} />
        <LoadingSkeleton loaded={downloadsFetched}>
          <ul className="space-y-2 w-full overflow-hidden">
            {size(downloads) > 0 ? (
              downloads.map((download) => (
                <DownloadManagerModalItem
                  key={download.uuid}
                  download={download}
                />
              ))
            ) : (
              <div className="text-center my-5">
                <Translate id={words.noResultsYet} />
              </div>
            )}
          </ul>
        </LoadingSkeleton>
      </div>
    </div>
  );
}

export default memo<DownloadManagerModalItemsProps>(DownloadManagerModalItems);
