import { AccountTypeNanoID } from '../accountTypes/accountTypesTypes';

const paymentsJournalRecordsBasePath = '/payments/transactions';

export class JournalRecordPath {
  static paymentsJournalRecords() {
    return paymentsJournalRecordsBasePath;
  }

  static paymentsJournalRecordsAccountType(
    accountTypeNanoId: AccountTypeNanoID
  ) {
    return `${paymentsJournalRecordsBasePath}/filters/account-types/${accountTypeNanoId}`;
  }

  static accountingTransactions() {
    return `/accounting-transactions`;
  }
}
