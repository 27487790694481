import {
  FetchAvBillingInfosCacheKey,
  FetchAvBillingInfosFilters,
  FetchAvBillingInfosLimit,
  FetchAvBillingInfosPage,
  FetchAvBillingInfosSort,
  AvBillingInfoCreatedAt,
  AvBillingInfoID,
  AvBillingInfoName,
  AvBillingInfoNanoID,
  AvBillingInfoUpdatedAt,
  AvBillingInfoUUID,
  AvBillingInfoCompanyName,
  AvBillingInfoContactName,
  AvBillingInfoContactPhone,
  AvBillingInfoContactEmail,
  AvBillingInfoCountry,
  AvBillingInfoState,
  AvBillingInfoCity,
  AvBillingInfoZipCode,
  AvBillingInfoDefaultAt,
  AvBillingInfoNotes,
  AvBillingInfoBankDetails,
  AvBillingInfoExcludedCountries,
  AvBillingInfoAddress,
  AvBillingInfoPaymentMethods
} from '../../avBillingInfosTypes';

import {
  useBffIndexQuery,
  BffIndexQueryResponse
} from '../../../common/hooks/base/reactQuery/useBffIndexQuery';

import {
  INITIAL_AV_BILLING_INFOS_FILTERS,
  INITIAL_AV_BILLING_INFOS_LIMIT,
  INITIAL_AV_BILLING_INFOS_PAGE,
  INITIAL_AV_BILLING_INFOS_SORT
} from '../../avBillingInfosConstants';

import { AvBillingInfoBffUrl } from '../../AvBillingInfoBffUrl';

export interface FetchAvBillingInfosAvBillingInfoType {
  id: AvBillingInfoID;
  nanoId: AvBillingInfoNanoID;
  uuid: AvBillingInfoUUID;
  name: AvBillingInfoName;
  companyName: AvBillingInfoCompanyName;
  contactName: AvBillingInfoContactName;
  contactPhone: AvBillingInfoContactPhone;
  contactEmail: AvBillingInfoContactEmail;
  country: AvBillingInfoCountry;
  state: AvBillingInfoState;
  city: AvBillingInfoCity;
  address: AvBillingInfoAddress;
  zipCode: AvBillingInfoZipCode;
  createdAt: AvBillingInfoCreatedAt;
  updatedAt: AvBillingInfoUpdatedAt;
  defaultAt: AvBillingInfoDefaultAt;
  companyId: AvBillingInfoCompanyName;
  notes: AvBillingInfoNotes;
  bankDetails: AvBillingInfoBankDetails;
  excludedCountries: AvBillingInfoExcludedCountries;
  paymentMethod: AvBillingInfoPaymentMethods;
}

export type FetchAvBillingInfosAvBillingInfoOnSuccess = (
  data: BffIndexQueryResponse<FetchAvBillingInfosAvBillingInfoType>
) => void;

interface PaginatedAvBillingInfosOptions {
  cacheKey: FetchAvBillingInfosCacheKey;
  initialFilters?: FetchAvBillingInfosFilters;
  initialSort?: FetchAvBillingInfosSort;
  initialPage?: FetchAvBillingInfosPage;
  initialLimit?: FetchAvBillingInfosLimit;
  serializer?: string;
  options?: {
    withoutPrefetch?: boolean;
    onSuccess?: FetchAvBillingInfosAvBillingInfoOnSuccess;
  };
}

const scope = 'avBillingInfos';

function usePaginatedAvBillingInfos({
  cacheKey,
  initialFilters = INITIAL_AV_BILLING_INFOS_FILTERS,
  initialSort = INITIAL_AV_BILLING_INFOS_SORT,
  initialPage = INITIAL_AV_BILLING_INFOS_PAGE,
  initialLimit = INITIAL_AV_BILLING_INFOS_LIMIT,
  serializer,
  options
}: PaginatedAvBillingInfosOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useBffIndexQuery<FetchAvBillingInfosAvBillingInfoType>({
    cacheKey,
    scope,
    url: AvBillingInfoBffUrl.index(),
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    serializer,
    options
  });

  return {
    avBillingInfosData: data,
    avBillingInfos: items,
    avBillingInfosError: itemsError,
    avBillingInfosErrorMessage: itemsErrorMessage,
    avBillingInfosTotalCount: itemsTotalCount,
    avBillingInfosFetched: isFetched,
    avBillingInfosLoading: isLoading,
    avBillingInfosIsPlaceholderData: isPlaceholderData,
    avBillingInfosFilters: currentFilters,
    avBillingInfosSort: currentSort,
    avBillingInfosPage: currentPage,
    avBillingInfosLimit: currentLimit,
    updateAvBillingInfoCache: updateItemCache,
    filterAvBillingInfos: filterItems,
    changeAvBillingInfosFilters: changeItemsFilters,
    clearAvBillingInfosFilters: clearItemsFilters,
    sortAvBillingInfos: sortItems,
    paginateAvBillingInfos: paginateItems,
    limitAvBillingInfos: limitItems,
    prefetchAvBillingInfos: prefetchItems
  };
}

export default usePaginatedAvBillingInfos;
