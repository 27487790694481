export default {
  assignType: 'Assign type',
  plural: 'Attachments',
  pluralGenitive: 'Attachments',
  pluralLowerCase: 'attachments',
  drawing: 'Drawing',
  drawings: 'Drawings',
  download: 'Download',
  gridView: 'Grid view',
  file: 'File attachment',
  listView: 'List view',
  references: 'References',
  reference: 'Reference',
  seeAll: 'See all attachments',
  textures: 'Textures',
  texture: 'Texture',
  threeD: '3D'
};
