import { IconsEnum } from '../../../../assets/icons/types';

import { HelpCenterModalButton } from '../../../../app/components/modalButtons/HelpCenterModalButton';
import { UserTermsModalButton } from '../../../../app/components/modalButtons/UserTermsModalButton';
import { ContactSupportModalButton } from '../../../../app/components/modalButtons/ContactSupportModalButton';

import { words } from '../../../../locales/keys';

function AuthFooter() {
  return (
    <div className="relative -z-1 w-full border-t border-gray-200 dark:border-gray-700 text-xs leading-6 flex justify-between text-gray-700 dark:text-gray-300 -mb-px">
      <div className="flex flex-1 justify-end">
        <HelpCenterModalButton
          icon={IconsEnum.QUESTION_MARK_CIRCLE_SOLID}
          className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white flex items-center gap-1.5 mb-px"
          i18nTextClassName="leading-6 2xl:leading-8"
          iconClassName="w-3.5 h-3.5"
          i18nText={words.helpCenter}
        />

        <ContactSupportModalButton
          icon={IconsEnum.CONTACT_SUPPORT}
          iconClassName="w-3.5 h-3.5"
          className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white flex items-center gap-1.5 mb-px"
          i18nText={words.contactSupport}
        />

        <UserTermsModalButton
          i18nText={words.terms}
          i18nTextClassName="leading-6 2xl:leading-8 font-medium text-blue-600 hover:text-blue-500"
          className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white flex items-center gap-1.5 mb-px"
        />
      </div>
    </div>
  );
}

export default AuthFooter;
