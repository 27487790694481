import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ItemsKeyType {
  add: LocalesKey;
  areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction: LocalesKey;
  bind: LocalesKey;
  bound: LocalesKey;
  createDate: LocalesKey;
  description: LocalesKey;
  hideCompleted: LocalesKey;
  id: LocalesKey;
  invoice: LocalesKey;
  mixed: LocalesKey;
  move: LocalesKey;
  moveTo: LocalesKey;
  name: LocalesKey;
  noScopeOfWorkYet: LocalesKey;
  payFromDeposit: LocalesKey;
  plural: LocalesKey;
  pluralLowerCase: LocalesKey;
  price: LocalesKey;
  postpone: LocalesKey;
  postponed: LocalesKey;
  postponedItems: LocalesKey;
  postponedItemsTotal: LocalesKey;
  qty: LocalesKey;
  rendering: LocalesKey;
  scopeOfWork: LocalesKey;
  select: LocalesKey;
  selectedTotal: LocalesKey;
  shownTotal: LocalesKey;
  singular: LocalesKey;
  singularLowerCase: LocalesKey;
  status: LocalesKey;
  subTotal: LocalesKey;
  threeDRenderingServicesPrepayment: LocalesKey;
  unbind: LocalesKey;
  unbound: LocalesKey;
  typeNotesHere: LocalesKey;
  youShouldAddAtLeastOneItem: LocalesKey;
}

export const itemsKeys = keyPathMirror<ItemsKeyType, string>(
  {
    add: null,
    areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction: null,
    bind: null,
    bound: null,
    createDate: null,
    description: null,
    hideCompleted: null,
    id: null,
    move: null,
    moveTo: null,
    invoice: null,
    mixed: null,
    name: null,
    noScopeOfWorkYet: null,
    payFromDeposit: null,
    plural: null,
    pluralLowerCase: null,
    price: null,
    postpone: null,
    postponed: null,
    postponedItems: null,
    postponedItemsTotal: null,
    qty: null,
    rendering: null,
    scopeOfWork: null,
    select: null,
    selectedTotal: null,
    shownTotal: null,
    singular: null,
    singularLowerCase: null,
    status: null,
    subTotal: null,
    threeDRenderingServicesPrepayment: null,
    unbind: null,
    unbound: null,
    typeNotesHere: null,
    youShouldAddAtLeastOneItem: null
  },
  'models.items.'
);
