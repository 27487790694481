import Link from 'next/link';
import cl from 'classnames';

import { IconsEnum } from '../../../assets/icons/types';
import { ClassName, I18nText } from '../../../types';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';
import { NumberHelper } from '../../NumberHelper';

type NextPureIconBadgeLinkHelperOnClick = (
  e: React.MouseEvent<HTMLAnchorElement>
) => void;

export interface NextPureIconBadgeLinkHelperDefaultProps {
  id?: string;
  href: string;
  prefetch?: boolean;
  className?: string;
  iconClassName?: string;
  badgeValue: number;
  badgePrefix?: string;
  badgeSuffix?: string;
  badgeClassName?: ClassName;
  badgeAddClassName?: ClassName;
  badgeDecimalScale?: number;
  onClick?: NextPureIconBadgeLinkHelperOnClick;
}

export interface NextPureIconBadgeLinkHelperIconProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface NextPureIconBadgeLinkHelperWithI18nProps {
  icon: IconsEnum | null;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface NextPureIconBadgeLinkHelperWithTextProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type NextPureIconBadgeLinkHelperProps =
  NextPureIconBadgeLinkHelperDefaultProps &
    (
      | NextPureIconBadgeLinkHelperIconProps
      | NextPureIconBadgeLinkHelperWithI18nProps
      | NextPureIconBadgeLinkHelperWithTextProps
    );

function NextPureIconBadgeLinkHelper({
  id,
  href,
  prefetch,
  className,
  icon = null,
  iconClassName,
  onClick,
  i18nTextClassName,
  i18nText,
  text,
  badgeValue,
  badgePrefix,
  badgeSuffix,
  badgeDecimalScale,
  badgeClassName,
  badgeAddClassName
}: NextPureIconBadgeLinkHelperProps) {
  return (
    <Link href={href} prefetch={prefetch}>
      <a href={href} className={className} onClick={onClick}>
        {icon ? (
          <Icon
            className={iconClassName}
            icon={icon}
            id={id ? `${id}-icon` : undefined}
          />
        ) : null}
        {icon && (i18nText || text) ? ' ' : null}
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
        {i18nText || text ? ' ' : null}
        <span
          className={cl(
            badgeClassName ||
              'bg-gray-300 dark:bg-gray-700 px-1 rounded leading-snug 2xl:leading-normal text-2xs',
            badgeAddClassName
          )}
        >
          <NumberHelper
            value={badgeValue}
            prefix={badgePrefix}
            suffix={badgeSuffix}
            decimalScale={badgeDecimalScale}
          />
        </span>
      </a>
    </Link>
  );
}

export default NextPureIconBadgeLinkHelper;
