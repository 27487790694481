export default {
  account: 'Project account',
  add: 'Add project',
  addDescription: 'Add project description',
  addTags: 'Add project tags',
  all: 'All projects',
  alreadyMember: 'Already project member',
  areYouSureYouWantToDeleteThisProjectYouWillNotBeAbleToUndoThisAction:
    'Are you sure you want to delete this project? You will not be able to undo this action.',
  batchOperations: 'Batch operations',
  areYouSureYouWantRemoveYouFromProjectThisActionCannotBeUndone:
    'Are you sure you want remove you from project? This action cannot be undone.',
  attachDirectlyToProject: 'Attach directly to project',
  editDescription: 'Edit description',
  fortunatelyItsEasyToCreateOne: "Fortunately, it's easy to create one",
  changeEstimateCost: "Change project's estimate cost",
  create: 'Create project',
  createNew: 'Create a new project',
  createOnly: 'Create project only',
  current: 'Current project',
  description: 'Project description',
  destination: 'Destination project',
  details: 'Project Details',
  download_excel: 'Download excel document with all projects',
  download_selected_excel: 'Download excel document with selected projects',
  downloadAllTaskResults: 'Download all task results',
  edit_description: 'Edit project description',
  edit_details: 'Edit project details',
  enter_name: 'Enter the project name',
  estimate_cost: "Project's estimate cost",
  favorite: 'Favorite projects',
  id: 'Project Id',
  looksLikeYouDontHaveAnyProjects: "Looks like you don't have any projects",
  members: 'Project members',
  messages: 'Project Messages',
  muteProject: 'Mute project',
  name: 'Project name',
  name_a_z: 'Project name (A-Z)',
  name_z_a: 'Project name (Z-A)',
  new: 'New Project',
  no: 'No projects',
  pleaseCreateYourFirstProjectToProceed:
    'Please, create your first project to proceed',
  pleaseEvaluate: 'Please, evaluate the project',
  pleaseProvideTheProjectBriefFilesAndScopeOfWork:
    'Please provide the project brief, files, and scope of work.',
  plural: 'Projects',
  pluralGenitiveLowerCase: 'projects',
  projectName: 'Project name',
  projectTeam: 'Project team',
  profile: 'Task Profile',
  remove_from: 'Remove from project',
  root: 'Project root',
  search: 'Search projects',
  searchByProjectName: 'Search by project name',
  searchProjectsByNameOrUser: 'Search projects by name or @user',
  select: 'Select project...',
  selectSingular: 'Select project',
  selectUser: 'Select project member',
  selected_users: 'Selected project members',
  setDueDate: 'Set project due date',
  set_cost: 'Set project cost',
  sendInvoice: 'Send invoice',
  singular: 'Project',
  singularAttachments: 'Project Attachments',
  allAttachments: 'All Project Attachments',
  singular_downcase: 'project',
  status: 'Project Status',
  tasks: 'Project tasks',
  tags: 'Project tags',
  team: 'Project team',
  total: 'Project total',
  totalCost: 'Total cost',
  trackProjectTime: 'Track project time',
  track_time: 'Track project time',
  unallocatedFunds: 'Project unallocated funds',
  update: 'Update project',
  pluralLowerCase: 'projects',
  inviteSelected: 'Invite selected',
  inviteUser: 'Invite user',
  inviteToMultipleProjects: 'Invite to multiple projects',
  invitations: 'Invitations',
  view: 'View project',
  optional: 'optional',
  optionalWhenYouExpectTheProjectToBeFinished:
    '(optional) When you expect the project to be finished. It can be  changed later.',
  optionalPleaseProvideARawEstimationOnProjectBudget:
    '(optional) Please, provide a raw estimation on project budget. It can be changed later.',
  youCanCreateYourOwnInvoiceAndAddHereAllThePartsOfYourProject:
    'You can create your own invoice and add here all the parts of your project.',
  youCanAddFilesRelatedToTheProjectSuchAsDrawingsTexturesReferencesModelsEtc:
    'You can add files related to the project such as drawings, textures, references, models, etc.'
};
