import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface AvBillingInfosKeyType {
  plural: LocalesKey;
  name: LocalesKey;
  paymentMethods: {
    wire: LocalesKey;
    payoneer: LocalesKey;
    payoneerCheckout: LocalesKey;
    stripe: LocalesKey;
    fondy: LocalesKey;
  };
}

export const avBillingInfosKeys = keyPathMirror<AvBillingInfosKeyType, string>(
  {
    plural: null,
    name: null,
    paymentMethods: {
      wire: null,
      payoneer: null,
      payoneerCheckout: null,
      stripe: null,
      fondy: null
    }
  },
  'models.avBillingInfos.'
);
