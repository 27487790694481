import { AvBillingInfosKeyType } from '../../keys/models/avBillingInfos';

const avBillingInfosKeys: AvBillingInfosKeyType = {
  plural: 'AV Billing infos',
  name: 'AV Billing info name',
  paymentMethods: {
    wire: 'Wire',
    payoneer: 'Card Payoneer',
    payoneerCheckout: 'Card Payoneer Checkout',
    stripe: 'Card Stripe',
    fondy: 'Card Fondy'
  }
};

export default avBillingInfosKeys;
