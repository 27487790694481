import { ClientError } from 'graphql-request';
import isArray from 'lodash/isArray';

import { ReadApiError } from '../../types';

import { checkClientErrorErrorsStatus } from './utils/checkClientErrorErrorsStatus';
import { clientErrorTypeDef } from './utils/clientErrorTypeDef';
import { payloadErrorTypeDef } from './utils/payloadErrorTypeDef';
import { readApiErrorTypeDef } from './utils/readApiErrorTypeDef';
import { checkReadApiErrorStatus } from './utils/checkReadApiErrorStatus';

interface PayloadErrorType {
  fullMessages: string[] | null;
}

export type ErrorType =
  | Error
  | ClientError
  | PayloadErrorType
  | ReadApiError
  | null;

const defaultServerError = 'Invalid server response';
const defaultClientError = 'Request Timeout';
const default401Error = 'Access denied';
const default403Error = 'Access denied';
const default404Error = 'Record Not Found';

function parseRequestError(error: ErrorType): string | null {
  if (!error) {
    return null;
  }

  if (payloadErrorTypeDef(error) && isArray(error?.fullMessages)) {
    return error.fullMessages.join(', ') || defaultServerError;
  }

  if (
    checkClientErrorErrorsStatus(error, { status_code: 401 }) ||
    checkReadApiErrorStatus(error, 401)
  ) {
    return default401Error;
  }

  if (
    checkClientErrorErrorsStatus(error, { status_code: 403 }) ||
    checkReadApiErrorStatus(error, 403)
  ) {
    return default403Error;
  }

  if (
    checkClientErrorErrorsStatus(error, (e) =>
      /5\d{2}/.test(String(e?.status_code))
    ) ||
    checkReadApiErrorStatus(error, /5\d{2}/)
  ) {
    return defaultServerError;
  }

  if (
    checkClientErrorErrorsStatus(error, { status_code: 404 }) ||
    checkReadApiErrorStatus(error, 404)
  ) {
    return default404Error;
  }

  if (clientErrorTypeDef(error) || readApiErrorTypeDef(error)) {
    return defaultServerError;
  }

  return defaultClientError;
}

export default parseRequestError;
