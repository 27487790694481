import {
  BillingInfoID,
  BillingInfoAddress,
  BillingInfoAvBillingInfoID,
  BillingInfoCity,
  BillingInfoCompanyName,
  BillingInfoCompanyNanoID,
  BillingInfoContactEmail,
  BillingInfoContactPhone,
  BillingInfoCountry,
  BillingInfoState,
  BillingInfoZipCode,
  CreateBillingInfoCacheKeys,
  BillingInfoPaymentMethod,
  BillingInfoFirstName,
  BillingInfoLastName,
  BillingInfoDefaultAt
} from '../../billingInfosTypes';
import {
  AvBillingInfoID,
  AvBillingInfoNanoID,
  AvBillingInfoName,
  AvBillingInfoCompanyName,
  AvBillingInfoContactName,
  AvBillingInfoContactPhone,
  AvBillingInfoContactEmail,
  AvBillingInfoCountry,
  AvBillingInfoState,
  AvBillingInfoCity,
  AvBillingInfoAddress,
  AvBillingInfoZipCode,
  AvBillingInfoBankDetails,
  AvBillingInfoNotes
} from '../../../avBillingInfos/avBillingInfosTypes';
import { BffApiCreateItemError } from '../../../../types';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { BillingInfoBffUrl } from '../../BillingInfoBffUrl';

interface CreateBillingInfoOptions {
  cacheKeys?: CreateBillingInfoCacheKeys;
}

export interface CreateBillingInfoInput {
  billingInfo: {
    firstName: BillingInfoFirstName;
    lastName: BillingInfoLastName;
    companyName: BillingInfoCompanyName;
    contactPhone: BillingInfoContactPhone;
    contactEmail: BillingInfoContactEmail;
    city: BillingInfoCity;
    country: BillingInfoCountry;
    state?: BillingInfoState;
    address: BillingInfoAddress;
    zipCode: BillingInfoZipCode;
    avBillingInfoId: BillingInfoAvBillingInfoID;
    companyNanoId: BillingInfoCompanyNanoID;
    paymentMethod?: BillingInfoPaymentMethod;
  };
}

export type CreateBillingInfoError = BffApiCreateItemError;

export interface CreateBillingInfoResponseType {
  id: BillingInfoID;
  firstName: BillingInfoFirstName;
  lastName: BillingInfoLastName;
  companyName: BillingInfoCompanyName;
  contactPhone: BillingInfoContactPhone;
  contactEmail: BillingInfoContactEmail;
  country: BillingInfoCountry;
  state: BillingInfoState;
  city: BillingInfoCity;
  address: BillingInfoAddress;
  zipCode: BillingInfoZipCode;
  defaultAt: BillingInfoDefaultAt | null;
  avBillingInfo: {
    id: AvBillingInfoID;
    nanoId: AvBillingInfoNanoID;
    name: AvBillingInfoName;
    companyName: AvBillingInfoCompanyName;
    contactName: AvBillingInfoContactName;
    contactPhone: AvBillingInfoContactPhone;
    contactEmail: AvBillingInfoContactEmail;
    country: AvBillingInfoCountry;
    state: AvBillingInfoState;
    city: AvBillingInfoCity;
    address: AvBillingInfoAddress;
    zipCode: AvBillingInfoZipCode;
    bankDetails: AvBillingInfoBankDetails;
    notes: AvBillingInfoNotes;
  };
}

function useCreateBillingInfo({ cacheKeys }: CreateBillingInfoOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useBffCreateQuery<
    CreateBillingInfoInput,
    CreateBillingInfoResponseType,
    CreateBillingInfoError,
    CreateBillingInfoResponseType
  >({ url: BillingInfoBffUrl.create(), cacheKeys });

  return {
    createBillingInfoData: createQueryData,
    createBillingInfoError: createQueryError,
    createBillingInfoLoading: createQueryLoading,
    createBillingInfoErrorMessage: createQueryErrorMessage,
    createBillingInfo: createQuery,
    createBillingInfoReset: createQueryReset
  };
}

export default useCreateBillingInfo;
