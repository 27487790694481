import { components, SingleValueProps } from 'react-select';

import { MultiSelectExtendedDataType } from '../../types';

import { Files } from '../../../../utils/Files';

import { ImageItemImageVersions } from '../../../ImageHelper';

function SingleValue({
  children,
  ...props
}: SingleValueProps<MultiSelectExtendedDataType>) {
  return (
    <components.SingleValue {...props}>
      {props.data?.image ? (
        <div className="flex items-center relative">
          <img
            className="absolute left-0 rounded-full h-8 w-8"
            src={Files.image(
              { file: props.data.image },
              ImageItemImageVersions.Thumb160x160
            )}
          />
          <span className="pl-10">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.SingleValue>
  );
}

export default SingleValue;
