export default {
  plural: 'Results',
  pluralLowerCase: 'results',
  download: 'Download results',
  sendToEmail: 'Send results to email',
  filter: 'Filter results',
  pluralGenitiveLowerCase: 'results',
  see: 'See results',
  singularLowerCase: 'result',
  hide: 'Hide results',
  cancel: 'Cancel results'
};
