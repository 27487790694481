import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AccountsKeyType {
  accountNanoId: LocalesKey;
  accountBalances: LocalesKey;
  balance: LocalesKey;
  balances: LocalesKey;
  balanceSheet: LocalesKey;
  bookId: LocalesKey;
  cash: LocalesKey;
  credit: LocalesKey;
  plural: LocalesKey;
  createAnAccount: LocalesKey;
  createNewAccount: LocalesKey;
  createArtistAccount: LocalesKey;
  createWorkerAccount: LocalesKey;
  forgotYourPasswordQuestion: LocalesKey;
  hideZeroBalances: LocalesKey;
  name: LocalesKey;
  chartNumber: LocalesKey;
  number: LocalesKey;
  noAccountsYet: LocalesKey;
  receivable: LocalesKey;
  search: LocalesKey;
  signIn: LocalesKey;
  signUp: LocalesKey;
  signInToYourAccount: LocalesKey;
  singular: LocalesKey;
  types: {
    name: LocalesKey;
    all: LocalesKey;
    assets: LocalesKey;
    depositAndExpense: LocalesKey;
    expenses: LocalesKey;
    equity: LocalesKey;
    liabilities: LocalesKey;
    provisions: LocalesKey;
    revenue: LocalesKey;
    revenueAndPayout: LocalesKey;
  };
  viewTransactions: LocalesKey;
}

export const accountsKeys = keyPathMirror<AccountsKeyType, string>(
  {
    accountNanoId: null,
    accountBalances: null,
    balance: null,
    balances: null,
    balanceSheet: null,
    bookId: null,
    cash: null,
    credit: null,
    plural: null,
    createAnAccount: null,
    createNewAccount: null,
    createArtistAccount: null,
    createWorkerAccount: null,
    chartNumber: null,
    forgotYourPasswordQuestion: null,
    hideZeroBalances: null,
    name: null,
    number: null,
    noAccountsYet: null,
    receivable: null,
    search: null,
    signIn: null,
    signUp: null,
    signInToYourAccount: null,
    singular: null,
    types: {
      name: null,
      all: null,
      assets: null,
      depositAndExpense: null,
      expenses: null,
      equity: null,
      liabilities: null,
      provisions: null,
      revenue: null,
      revenueAndPayout: null
    },
    viewTransactions: null
  },
  'models.accounts.'
);
