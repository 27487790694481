import { TeamNanoID, TeamTeamTypeEnum } from '../../teamsTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useTeam } from '../useTeam';

import {
  FetchShowTeamQueryResponse,
  FETCH_SHOW_TEAM_QUERY
} from '../../queries/fetchShowTeam.query';

import { isSelfCompany } from '../../utils/isSelfCompany';

import { TeamCache } from '../../TeamCache';
import { useFinTeam } from '../useFinTeam';
import {
  FETCH_COMPANY_QUERY,
  FetchCompanyQueryResponse
} from '../../queries/fetchCompany.query';

interface TeamShowPageOptions {
  teamNanoId: TeamNanoID;
  staleTime?: number;
}

function useTeamShowPage({ teamNanoId, staleTime }: TeamShowPageOptions) {
  const currentUser = useCurrentUser();

  const {
    team,
    teamFetched,
    teamErrorData,
    teamError,
    teamIsPlaceholderData,
    teamLoading
  } = useTeam<FetchShowTeamQueryResponse>({
    cacheKey: TeamCache.showCacheKey(),
    query: FETCH_SHOW_TEAM_QUERY,
    uuid: teamNanoId,
    options: {
      enabled: !!teamNanoId,
      enabledPlaceholder: !!teamNanoId,
      staleTime
    }
  });

  const { team: finTeam, teamFetched: finTeamFetched } =
    useFinTeam<FetchCompanyQueryResponse>({
      uuid: teamNanoId,
      query: FETCH_COMPANY_QUERY,
      cacheKey: TeamCache.showCompanyCacheKey(),
      options: {
        staleTime
      }
    });

  const isSelf = isSelfCompany({
    company: team,
    userNanoId: currentUser.nanoId
  });

  const isSelfLoading = !team && !teamFetched;

  const isWorker = team?.teamType === TeamTeamTypeEnum.WORKERS;

  return {
    finTeam,
    finTeamFetched,
    team,
    teamFetched,
    teamErrorData,
    teamError,
    teamIsPlaceholderData,
    teamLoading,
    isSelf,
    isSelfLoading,
    isWorker
  };
}

export default useTeamShowPage;
