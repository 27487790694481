import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ResultsKeyType {
  plural: LocalesKey;
  pluralGenitiveLowerCase: LocalesKey;
  download: LocalesKey;
  see: LocalesKey;
  singularLowerCase: LocalesKey;
  hide: LocalesKey;
  cancel: LocalesKey;
}

export const resultsKeys = keyPathMirror<ResultsKeyType, string>(
  {
    plural: null,
    pluralGenitiveLowerCase: null,
    download: null,
    see: null,
    singularLowerCase: null,
    hide: null,
    cancel: null
  },
  'models.results.'
);
