import { AvBillingInfoNanoID } from './avBillingInfosTypes';

const baseUrl = 'av_billing_infos';

export class AvBillingInfoBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static delete(avBillingInfoNanoID: AvBillingInfoNanoID) {
    return `${baseUrl}/${avBillingInfoNanoID}`;
  }
}
