import { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  UUID,
  FetchItemsUrl,
  FetchItemsCacheKey,
  FetchItemCacheKey,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsSerializer,
  BffApiFetchItemsError,
  NanoID,
  ID
} from '../../../../../../types';

import { useUpdateIndexQueryItemCache } from '../useIndexQuery/hooks/useUpdateIndexQueryItemCache';
import { useUpdateIndexQueryItemsCache } from '../useIndexQuery/hooks/useUpdateIndexQueryItemsCache';

import {
  indexRequestReducer,
  IndexRequestReducerType
} from '../useIndexQuery/reducers/indexRequestReducer';

import { changeItemsFiltersAction } from '../useIndexQuery/actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from '../useIndexQuery/actions/clearItemsFiltersAction';
import { filterItemsAction } from '../useIndexQuery/actions/filterItemsAction';
import { sortItemsAction } from '../useIndexQuery/actions/sortItemsAction';
import { paginateItemsAction } from '../useIndexQuery/actions/paginateItemsAction';
import { limitItemsAction } from '../useIndexQuery/actions/limitItemsAction';

import { BffApiRequest } from '../../../../../../utils/BffApiRequest';

import {
  INITIAL_FILTERS,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT
} from '../useIndexQuery/indexRequestConstants';

import {
  BffIndexQueryResponse,
  BffIndexQueryDefaultOptionsOpts
} from './useBffIndexQuery.types';

import { parseRequestError } from '../../../../../../utils/parseRequestError';

type BffIndexQueryErrorType = BffApiFetchItemsError;

interface BffIndexQueryDefaultOptions<NodeType, MetaType> {
  additionalParams?: Record<string, unknown>;
  cacheKey: FetchItemsCacheKey;
  url: FetchItemsUrl;
  scope: string;
  initialFilters?: FetchItemsFilters;
  initialSort?: FetchItemsSort;
  initialPage?: FetchItemsPage;
  initialLimit?: FetchItemsLimit;
  serializer?: FetchItemsSerializer;
  options?: BffIndexQueryDefaultOptionsOpts<NodeType, MetaType>;
}

interface BffIndexQueryWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemUrl: (id: ID | UUID | NanoID) => string;
}

interface BffIndexQueryWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemUrl?: never;
}

interface BffIndexQueryParams {
  filters: FetchItemsFilters;
  sort: FetchItemsSort;
  page: FetchItemsPage;
  limit: FetchItemsLimit;
  serializer?: FetchItemsSerializer;
}

type BffIndexQueryOptions<NodeType, MetaType> = BffIndexQueryDefaultOptions<
  NodeType,
  MetaType
> &
  (
    | BffIndexQueryWithPrefetchItemOptions
    | BffIndexQueryWithoutPrefetchItemOptions
  );

function useBffIndexQuery<NodeType, MetaType = undefined>({
  additionalParams = {},
  cacheKey,
  scope,
  url,
  initialFilters = INITIAL_FILTERS,
  initialSort = INITIAL_SORT,
  initialPage = INITIAL_PAGE,
  initialLimit = INITIAL_LIMIT,
  serializer,
  fetchItemCacheKey,
  fetchItemUrl,
  options = {}
}: BffIndexQueryOptions<NodeType, MetaType>) {
  const localForageCacheKey = `${cacheKey}-index`;

  const [{ currentFilters, currentLimit, currentPage, currentSort }, dispatch] =
    useReducer<IndexRequestReducerType>(indexRequestReducer, {
      currentPage: initialPage,
      currentLimit: initialLimit,
      currentFilters: initialFilters,
      currentSort: initialSort
    });

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<BffIndexQueryResponse<NodeType, MetaType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<BffIndexQueryResponse<NodeType, MetaType>>(
          localForageCacheKey
        ),
      { enabled: options.enabledPlaceholder }
    );

  const currentParams = useMemo<BffIndexQueryParams>(
    () => ({
      ...additionalParams,
      filters: currentFilters,
      sort: currentSort,
      page: currentPage,
      limit: currentLimit,
      serializer
    }),
    [
      additionalParams,
      currentFilters,
      currentSort,
      currentPage,
      currentLimit,
      serializer
    ]
  );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, currentParams];
  }, [cacheKey, currentParams]);

  const handleQuery = useCallback<
    (
      params: BffIndexQueryParams
    ) => Promise<BffIndexQueryResponse<NodeType, MetaType>>
  >(
    async (params) => {
      try {
        const response = await BffApiRequest.get<
          BffIndexQueryResponse<NodeType, MetaType>
        >(url, params);

        return response.data;
      } catch (err) {
        throw isEmpty(err?.response?.data?.error?.fullMessages)
          ? err
          : err?.response?.data?.error;
      }
    },
    [url]
  );

  const handlePrefetchItemQuery = useCallback<
    (prefetchItemUrl: string) => Promise<NodeType>
  >(async (prefetchItemUrl) => {
    try {
      if (!prefetchItemUrl) {
        return undefined;
      }

      const response = await BffApiRequest.get<NodeType>(prefetchItemUrl);

      return response.data;
    } catch (err) {
      throw isEmpty(err?.response?.data?.error?.fullMessages)
        ? err
        : err?.response?.data?.error;
    }
  }, []);

  const handleCurrentQuery = useCallback<
    () => Promise<BffIndexQueryResponse<NodeType, MetaType>>
  >(() => {
    return handleQuery(currentParams);
  }, [currentParams, handleQuery]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    BffIndexQueryResponse<NodeType, MetaType>,
    BffIndexQueryErrorType
  >(fullCacheKey, handleCurrentQuery, {
    enabled: options.enabled || placeholderDataFetched,
    cacheTime: options.cacheTime,
    staleTime: options.staleTime,
    refetchInterval: options.refetchInterval,
    keepPreviousData: options.keepPreviousData,
    refetchIntervalInBackground: options.refetchIntervalInBackground,
    onSuccess: (data) => {
      options.onSuccess?.(data);
      if (
        isEqual(currentFilters, initialFilters) &&
        isEqual(currentSort, initialSort) &&
        currentPage === initialPage &&
        currentLimit === initialLimit
      ) {
        return LocalForage.setItem<BffIndexQueryResponse<NodeType, MetaType>>(
          localForageCacheKey,
          data
        );
      }
    },
    placeholderData: () => {
      if (
        placeholderData &&
        isEqual(currentFilters, initialFilters) &&
        isEqual(currentSort, initialSort) &&
        currentPage === initialPage &&
        currentLimit === initialLimit
      ) {
        return placeholderData;
      }
    }
  });

  const queryClient = useQueryClient();

  const queryResponseValue = data?.[scope];

  useEffect(() => {
    if (options.withoutPrefetch) {
      return;
    }

    const params = {
      filters: currentFilters,
      sort: currentSort,
      page: currentPage + 1,
      limit: currentLimit,
      serializer
    };

    queryClient.prefetchQuery([cacheKey, params], () => {
      return handleQuery(params);
    });
  }, [
    cacheKey,
    queryClient,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    serializer,
    queryResponseValue?.paginationInfo?.nextPage,
    handleQuery,
    options.withoutPrefetch
  ]);

  const nodesTotalCount = queryResponseValue?.nodes?.length
    ? queryResponseValue?.paginationInfo?.totalCount ||
      placeholderData?.[scope]?.paginationInfo?.totalCount ||
      0
    : null;

  const isLoadingTotalCount = isLoading
    ? placeholderData?.[scope]?.paginationInfo?.totalCount
    : null;

  const defaultTotalCount = 0;

  const itemsTotalCount =
    nodesTotalCount || isLoadingTotalCount || defaultTotalCount;

  const updateItemCache = useUpdateIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const updateItemsCache = useUpdateIndexQueryItemsCache<NodeType>({
    fullCacheKey
  });

  return {
    data,
    items: queryResponseValue?.nodes || <NodeType[]>[],
    itemsError: parseRequestError(error),
    itemsErrorMessage: parseRequestError(error),
    itemsTotalCount,
    meta: queryResponseValue?.meta || <MetaType>{},
    isFetched,
    isLoading,
    isPlaceholderData,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    updateItemCache,
    updateItemsCache,
    filterItems: useCallback(
      (nextFilters: FetchItemsFilters) =>
        dispatch(filterItemsAction(nextFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback(
      (
        changedFilters: Partial<FetchItemsFilters>,
        removeFilters: string[] = []
      ) => dispatch(changeItemsFiltersAction(changedFilters, removeFilters)),
      [dispatch]
    ),
    clearItemsFilters: useCallback(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    sortItems: useCallback(
      (nextSort: FetchItemsSort) => dispatch(sortItemsAction(nextSort)),
      [dispatch]
    ),
    paginateItems: useCallback(
      (nextPage: FetchItemsPage) => dispatch(paginateItemsAction(nextPage)),
      [dispatch]
    ),
    limitItems: useCallback(
      (nextLimit: FetchItemsLimit) => dispatch(limitItemsAction(nextLimit)),
      [dispatch]
    ),
    prefetchItems: useCallback(
      ({
        nextFilters,
        nextSort,
        nextPage,
        nextLimit
      }: {
        nextFilters?: FetchItemsFilters;
        nextSort?: FetchItemsSort;
        nextPage?: FetchItemsPage;
        nextLimit?: FetchItemsLimit;
      }) => {
        const params = {
          filters: nextFilters || currentFilters,
          sort: nextSort || currentSort,
          page: nextPage || currentPage,
          limit: nextLimit || currentLimit,
          serializer
        };
        return queryClient.prefetchQuery([cacheKey, params], () => {
          return handleQuery(params);
        });
      },
      [
        queryClient,
        cacheKey,
        currentFilters,
        currentSort,
        currentPage,
        currentLimit,
        serializer,
        handleQuery
      ]
    ),
    prefetchItem: useCallback(
      (itemUuid: ID | UUID | NanoID) =>
        queryClient.prefetchQuery(
          [fetchItemCacheKey, itemUuid],
          () => handlePrefetchItemQuery(fetchItemUrl(itemUuid)),
          { staleTime: 5000 }
        ),
      [queryClient, fetchItemCacheKey, handlePrefetchItemQuery, fetchItemUrl]
    )
  };
}

export default useBffIndexQuery;
