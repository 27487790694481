import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface LifestylesKeyType {
  plural: LocalesKey;
  addLifestyle: LocalesKey;
  attach: LocalesKey;
  attachSelected: LocalesKey;
  all: LocalesKey;
  category: LocalesKey;
  deleteLifestyle: LocalesKey;
  deleteWarning: LocalesKey;
  editLifestyle: LocalesKey;
  name: LocalesKey;
  my: LocalesKey;
  search: LocalesKey;
  selected: LocalesKey;
  viewAll: LocalesKey;
  categories: {
    bedroom: LocalesKey;
    commercialAreas: LocalesKey;
    exteriors: LocalesKey;
    dining: LocalesKey;
    livingApartments: LocalesKey;
  };
}

export const lifestylesKeys = keyPathMirror<LifestylesKeyType, string>(
  {
    plural: null,
    addLifestyle: null,
    attach: null,
    attachSelected: null,
    all: null,
    category: null,
    deleteLifestyle: null,
    deleteWarning: null,
    editLifestyle: null,
    name: null,
    my: null,
    search: null,
    selected: null,
    viewAll: null,
    categories: {
      bedroom: null,
      commercialAreas: null,
      exteriors: null,
      dining: null,
      livingApartments: null
    }
  },
  'models.lifestyles.'
);
