import { useCallback, useState } from 'react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import { map } from 'lodash';

import {
  DownloadStatuses,
  DownloadUUID
} from '../../../../../../../../downloads/downloadsTypes';

import {
  FETCH_DOWNLOADS_QUERY,
  FetchDownloadsQueryResponse
} from '../../../../../../../../downloads/queries/fetchDownloads.query';
import { DELETE_DOWNLOADS_QUERY } from '../../../../../../../../downloads/queries/deleteDownloads.query';

import { useReadPaginatedDownloads } from '../../../../../../../../downloads/hooks/useReadPaginatedDownloads';
import { useDownloadNanoId } from '../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useModal } from '../../../../../../../../../helpers/modals/hooks/useModal';
import { useDeleteDownloads } from '../../../../../../../../downloads/hooks/useDeleteDownloads';

import { downloadFile } from '../../../../../../../../../utils/downloadFile';

import { DownloadCache } from '../../../../../../../../downloads/DownloadCache';

const downloadedUuids: DownloadUUID[] = [];

function useDownloadManagerModal() {
  const { hideModal, isOpen, showModal } = useModal();

  const {
    deleteDownloads,
    deleteDownloadsLoading,
    deleteDownloadsErrorMessage
  } = useDeleteDownloads({ query: DELETE_DOWNLOADS_QUERY });

  const [processingDownloadsIds, setProcessingDownloadsIds] = useState<
    DownloadUUID[]
  >([]);

  const { downloadNanoId } = useDownloadNanoId();

  const { downloads, downloadsFetched, downloadsError } =
    useReadPaginatedDownloads<FetchDownloadsQueryResponse>({
      query: FETCH_DOWNLOADS_QUERY,
      cacheKey: DownloadCache.indexCacheKey(),
      initialFilters: {
        self: true,
        status: { notEq: DownloadStatuses.FAILED }
      },
      initialLimit: 6,
      options: {
        refetchInterval: 1000 * 10,
        refetchIntervalInBackground: true,
        onSuccess: async (data) => {
          const processingDownloads = filter(
            data?.downloads?.nodes || [],
            (download) =>
              download.status === DownloadStatuses.INITIALIZED ||
              download.status === DownloadStatuses.PROCESSING
          );

          data?.downloads?.nodes?.forEach((download) => {
            if (
              downloadNanoId === download.deviceNanoId &&
              download.status === DownloadStatuses.COMPLETED &&
              !includes<DownloadUUID>(downloadedUuids, download.uuid)
            ) {
              downloadedUuids.push(download.uuid);
              console.log('DownloadManagerBlock', {
                downloadedUuids,
                downloadNanoId,
                deviceNanoId: download.deviceNanoId
              });
              downloadFile(download);
            }
          });

          if (size(processingDownloads) !== size(processingDownloadsIds)) {
            setProcessingDownloadsIds(map(processingDownloads, 'uuid'));
          }
        }
      }
    });

  const a = sumBy(downloads, (download) =>
    download.status === DownloadStatuses.COMPLETED ? 0 : download.progress
  );

  const b = size(
    filter(
      downloads,
      (download) => download.status !== DownloadStatuses.COMPLETED
    )
  );

  const totalProgress = b === 0 ? 0 : a / b;

  const handleClear = useCallback<() => void>(
    () => deleteDownloads({ uuids: map(downloads, 'uuid') }),
    [deleteDownloads, downloads]
  );

  const handleStopAll = useCallback(
    () => deleteDownloads({ uuids: processingDownloadsIds }),
    [deleteDownloads, processingDownloadsIds]
  );

  return {
    downloadManagerBlockOpened: isOpen,
    downloads,
    downloadsFetched,
    downloadsError,
    totalProgress,
    processingDownloadsIds,
    processingDownloadsCount: size(processingDownloadsIds),
    openDownloadManagerBlock: showModal,
    closeDownloadManagerBlock: hideModal,
    handleClear,
    handleStopAll,
    deleteDownloadsLoading,
    deleteDownloadsErrorMessage
  };
}

export default useDownloadManagerModal;
