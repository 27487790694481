import React, { useCallback, useMemo, useState } from 'react';
import cl from 'classnames';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { I18nText } from '../../types';

import { IconsEnum } from '../../assets/icons/types';

import { useCurrentUser } from '../../auth/hooks/useAuth/useAuth';

import { TabButton } from './components/TabButton';

export type TabsHelperDataType = {
  disabled?: boolean;
  i18nText: I18nText;
  icon: IconsEnum | null;
  tooltipI18nText: string;
  value: string;
};

interface TabsHelperWithoutStateProps {
  openedTab: string;
  defaultTab?: never;
}

interface TabsHelperWithStateProps {
  openedTab?: never;
  defaultTab: string;
}

interface TabsHelperDefaultProps {
  data: TabsHelperDataType[];
  onChange: (value: string) => void;
  withoutPermissions?: boolean;
  withTabsIcons?: boolean;
  withTabsIndexes?: boolean;
}

type TabsHelperProps = TabsHelperDefaultProps &
  (TabsHelperWithoutStateProps | TabsHelperWithStateProps);

function TabsHelper({
  data,
  openedTab,
  defaultTab,
  onChange,
  withoutPermissions = false
}: TabsHelperProps) {
  const currentUser = useCurrentUser();

  const [tab, setTab] = useState(defaultTab || openedTab);

  const handleTabChange = useCallback(
    (value) => {
      setTab(value);
      onChange && onChange(value);
    },
    [setTab, onChange]
  );

  const availableTabs = useMemo<TabsHelperDataType[]>(
    () =>
      filter(
        data,
        (item) =>
          withoutPermissions ||
          currentUser?.hasPermissions(`read_${item.value}_tab`)
      ),
    [currentUser, data, withoutPermissions]
  );

  return size(availableTabs) > 1 ? (
    <nav className="border-b border-gray-100 dark:border-gray-800 -mb-px flex font-medium text-sm">
      {availableTabs.map(({ value, i18nText, icon, disabled }) => (
        <TabButton
          className={cl(
            'border-b-2 py-3 px-4 rounded-t-md outline-none focus:ring-2 focus:ring-offset-0 hover:bg-gray-200 dark:hover:bg-gray-800 whitespace-nowrap',
            {
              'border-blue-500': openedTab
                ? openedTab === value
                : tab === value,
              'border-transparent': openedTab
                ? openedTab !== value
                : tab !== value,
              disabled: disabled
            }
          )}
          key={`tab-${value}`}
          data={value}
          disabled={disabled}
          i18nText={i18nText}
          icon={icon}
          onTabChange={handleTabChange}
        />
      ))}
    </nav>
  ) : null;
}

export default TabsHelper;
