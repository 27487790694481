import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProjectsKeyType {
  account: LocalesKey;
  add: LocalesKey;
  addTags: LocalesKey;
  areYouSureYouWantToDeleteThisProjectYouWillNotBeAbleToUndoThisAction: LocalesKey;
  attachDirectlyToProject: LocalesKey;
  all: LocalesKey;
  alreadyMember: LocalesKey;
  batchOperations: LocalesKey;
  createOnly: LocalesKey;
  id: LocalesKey;
  list: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  create: LocalesKey;
  createNew: LocalesKey;
  current: LocalesKey;
  name: LocalesKey;
  description: LocalesKey;
  destination: LocalesKey;
  downloadAllTaskResults: LocalesKey;
  addDescription: LocalesKey;
  addName: LocalesKey;
  editDescription: LocalesKey;
  fortunatelyItsEasyToCreateOne: LocalesKey;
  search: LocalesKey;
  searchByProjectName: LocalesKey;
  searchProjectsByNameOrUser: LocalesKey;
  selectSingular: LocalesKey;
  sendInvoice: LocalesKey;
  singularAttachments: LocalesKey;
  allAttachments: LocalesKey;
  status: LocalesKey;
  tasks: LocalesKey;
  looksLikeYouDontHaveAnyProjects: LocalesKey;
  new: LocalesKey;
  remove_from: LocalesKey;
  setDueDate: LocalesKey;
  tags: LocalesKey;
  view: LocalesKey;
  optionalPleaseProvideARawEstimationOnProjectBudget: LocalesKey;
  optionalWhenYouExpectTheProjectToBeFinished: LocalesKey;
  areYouSureYouWantRemoveYouFromProjectThisActionCannotBeUndone: LocalesKey;
  team: LocalesKey;
  total: LocalesKey;
  totalCost: LocalesKey;
  trackProjectTime: LocalesKey;
  optional: LocalesKey;
  pleaseProvideTheProjectBriefFilesAndScopeOfWork: LocalesKey;
  projectName: LocalesKey;
  projectTeam: LocalesKey;
  muteProject: LocalesKey;
  messages: LocalesKey;
  pleaseEvaluate: LocalesKey;
  pleaseCreateYourFirstProjectToProceed: LocalesKey;
  pluralLowerCase: LocalesKey;
  inviteSelected: LocalesKey;
  inviteUser: LocalesKey;
  inviteToMultipleProjects: LocalesKey;
  invitations: LocalesKey;
  changeEstimateCost: LocalesKey;
  unallocatedFunds: LocalesKey;
  update: LocalesKey;
  youCanCreateYourOwnInvoiceAndAddHereAllThePartsOfYourProject: LocalesKey;
  youCanAddFilesRelatedToTheProjectSuchAsDrawingsTexturesReferencesModelsEtc: LocalesKey;
}

export const projectsKeys = keyPathMirror<ProjectsKeyType, string>(
  {
    account: null,
    add: null,
    addTags: null,
    areYouSureYouWantToDeleteThisProjectYouWillNotBeAbleToUndoThisAction: null,
    attachDirectlyToProject: null,
    all: null,
    alreadyMember: null,
    batchOperations: null,
    createOnly: null,
    id: null,
    list: null,
    plural: null,
    singular: null,
    status: null,
    create: null,
    createNew: null,
    current: null,
    name: null,
    description: null,
    destination: null,
    downloadAllTaskResults: null,
    addDescription: null,
    addName: null,
    editDescription: null,
    fortunatelyItsEasyToCreateOne: null,
    looksLikeYouDontHaveAnyProjects: null,
    searchByProjectName: null,
    selectSingular: null,
    sendInvoice: null,
    singularAttachments: null,
    allAttachments: null,
    tasks: null,
    new: null,
    remove_from: null,
    search: null,
    searchProjectsByNameOrUser: null,
    setDueDate: null,
    tags: null,
    total: null,
    totalCost: null,
    view: null,
    optionalPleaseProvideARawEstimationOnProjectBudget: null,
    optionalWhenYouExpectTheProjectToBeFinished: null,
    areYouSureYouWantRemoveYouFromProjectThisActionCannotBeUndone: null,
    team: null,
    trackProjectTime: null,
    optional: null,
    pleaseEvaluate: null,
    pleaseCreateYourFirstProjectToProceed: null,
    pleaseProvideTheProjectBriefFilesAndScopeOfWork: null,
    projectName: null,
    projectTeam: null,
    pluralLowerCase: null,
    muteProject: null,
    messages: null,
    inviteSelected: null,
    inviteUser: null,
    inviteToMultipleProjects: null,
    invitations: null,
    changeEstimateCost: null,
    unallocatedFunds: null,
    update: null,
    youCanCreateYourOwnInvoiceAndAddHereAllThePartsOfYourProject: null,
    youCanAddFilesRelatedToTheProjectSuchAsDrawingsTexturesReferencesModelsEtc:
      null
  },
  'models.projects.'
);
