import { useState, useCallback } from 'react';

const usePopoverState = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const [referencePopoverElement, setReferencePopoverElement] =
    useState<HTMLElement | null>(null);

  const togglePopover = useCallback<() => void>(() => {
    setIsPopoverOpen((prevVal) => !prevVal);
  }, [setIsPopoverOpen]);

  const closePopover = useCallback<() => void>(() => {
    setIsPopoverOpen(false);
  }, [setIsPopoverOpen]);

  return {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef: setReferencePopoverElement,
    togglePopover,
    closePopover
  };
};

export default usePopoverState;
