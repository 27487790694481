import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProductsKeyType {
  attachSelected: LocalesKey;
  download: LocalesKey;
  my: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  search: LocalesKey;
  searchProductsByNameOrSku: LocalesKey;
  addProduct: LocalesKey;
  attach: LocalesKey;
  attachProduct: LocalesKey;
  all: LocalesKey;
  deleteProduct: LocalesKey;
  deleteWarning: LocalesKey;
  category: LocalesKey;
  editProduct: LocalesKey;
  name: LocalesKey;
  selected: LocalesKey;
  viewAll: LocalesKey;
  categories: {
    exterior: LocalesKey;
    homeDecor: LocalesKey;
    homeTextiles: LocalesKey;
    kitchen: LocalesKey;
    plants: LocalesKey;
  };
  oneOrMoreSkuWasNotFoundInProductLibrary: LocalesKey;
}

export const productsKeys = keyPathMirror<ProductsKeyType, string>(
  {
    attachSelected: null,
    download: null,
    my: null,
    plural: null,
    singular: null,
    search: null,
    searchProductsByNameOrSku: null,
    addProduct: null,
    attach: null,
    attachProduct: null,
    all: null,
    deleteProduct: null,
    deleteWarning: null,
    category: null,
    editProduct: null,
    name: null,
    selected: null,
    viewAll: null,
    categories: {
      exterior: null,
      homeDecor: null,
      homeTextiles: null,
      kitchen: null,
      plants: null
    },
    oneOrMoreSkuWasNotFoundInProductLibrary: null
  },
  'models.products.'
);
