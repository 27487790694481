import React, { ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';
import includes from 'lodash/includes';

import { TeamNanoID } from '../../../main/teams/teamsTypes';
import { UserNanoID } from '../../../main/users/usersTypes';

import { useNonTpvLastPath } from '../../hooks/useNonTpvLastPath';

import { AuthAppLayout } from '../AuthAppLayout';
import { CompanyViewAppLayout } from '../CompanyViewAppLayout';
import { MainAppLayout } from '../MainAppLayout';

import { isCompanyViewRoute } from '../../utils/isCompanyViewRoute';

import { AuthPath } from '../../../auth/AuthPath';

interface AppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps) {
  const router = useRouter();
  const nonTpvLastPath = useNonTpvLastPath(router);

  const handleLogoutUser = useCallback<() => void>(
    () => router.push(AuthPath.login()),
    [router]
  );

  if (
    includes(router.pathname, '/auth') ||
    includes(router.pathname, '/register')
  ) {
    return <AuthAppLayout>{children}</AuthAppLayout>;
  }

  if (isCompanyViewRoute(router)) {
    return (
      <CompanyViewAppLayout
        companyNanoId={router.query.slug as TeamNanoID}
        userNanoId={router.query.userSlug as UserNanoID}
        nonTpvLastPath={nonTpvLastPath}
      >
        {children}
      </CompanyViewAppLayout>
    );
  }

  return (
    <MainAppLayout onLogoutUser={handleLogoutUser}>{children}</MainAppLayout>
  );
}

export default AppLayout;
