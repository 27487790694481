import { BffApiCreateItemError } from '../../../../types';

import {
  InvoiceAmount,
  InvoicePayerId,
  InvoicePaymentMethods,
  InvoiceDueDate,
  InvoiceID,
  InvoiceCreatedAt,
  InvoiceNotes,
  InvoiceTerms,
  CreateInvoiceCacheKeys
} from '../../invoicesTypes';

import { BillingInfoID } from '../../../billingInfos/billingInfosTypes';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';

interface CreateInvoiceOptions {
  cacheKeys?: CreateInvoiceCacheKeys;
}

export interface CreateInvoiceInput {
  invoice: {
    amount: InvoiceAmount;
    payerId: InvoicePayerId;
    paymentMethod: InvoicePaymentMethods;
    billingInfoId: BillingInfoID;
    dueDate: InvoiceDueDate;
    notes?: InvoiceNotes;
    terms?: InvoiceTerms;
  };
}

export type CreateInvoiceError = BffApiCreateItemError;

interface CreateInvoiceResponseType {
  id: InvoiceID;
  createdAt: InvoiceCreatedAt;
  dueDate: InvoiceDueDate;
  notes?: InvoiceNotes;
  terms?: InvoiceTerms;
}

function useCreateInvoice({ cacheKeys }: CreateInvoiceOptions = {}) {
  const { createQuery, createQueryErrorMessage, createQueryLoading } =
    useBffCreateQuery<
      CreateInvoiceInput,
      CreateInvoiceResponseType,
      CreateInvoiceError,
      CreateInvoiceResponseType
    >({ url: InvoiceBffUrl.create(), cacheKeys });

  return {
    createInvoiceLoading: createQueryLoading,
    createInvoiceErrorMessage: createQueryErrorMessage,
    createInvoice: createQuery
  };
}

export default useCreateInvoice;
