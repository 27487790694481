import React, { useState, useCallback, useEffect } from 'react';
import floor from 'lodash/floor';
import maxBy from 'lodash/maxBy';

import {
  AddFundsModalButtonData,
  AddFundsModalButtonStepBackAction,
  AddFundsModalButtonDataBillingInfo
} from '../../AddFundsModalButton.types';
import {
  TeamNanoID,
  TeamDeferredPayment,
  TeamTerms
} from '../../../../../../teams/teamsTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { useCreateBillingInfoForm } from '../../../../../../billingInfos/components/forms/CreateBillingInfoForm/hooks/useCreateBillingInfoForm';

import { useTheme } from '../../../../../../../app/hooks/useTheme';
import { usePayInvoice } from '../../../../../hooks/usePayInvoice';
import { useCreateInvoice } from '../../../../../hooks/useCreateInvoice';

import { useAddFundsModalButtonBillingInfos } from '../../hooks/useAddFundsModalButtonBillingInfos';

import { AddFundsCreateBillingInfoForm } from '../../../../../../billingInfos/components/forms/CreateBillingInfoForm';

import { AddFundsModalButtonGeneralInfo } from '../../helpers/AddFundsModalButtonGeneralInfo';
import { AddFundsModalButtonBillingInfoSelect } from '../../helpers/AddFundsModalButtonBillingInfoSelect';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../helpers/Translate';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';

import { PayoneerCheckoutPayment } from '../../../../../../payoneerCheckout/utils/PayoneerCheckoutPayment';
import { paymentNetworksId } from '../../../../../../payoneerCheckout/utils/PayoneerCheckoutPayment/payoneerCheckoutPaymentConstants';

import { generateNanoId } from '../../../../../../../utils/generateNanoId';

import { calcInvoiceDueDate } from '../../../../../utils/calcInvoiceDueDate';

import { BillingInfoCache } from '../../../../../../billingInfos/BillingInfoCache';
import { words, invoicesKeys } from '../../../../../../../locales/keys';

import { DEFAULT_TRANSACTION_FEE } from '../../../../../invoicesConstants';

const ADD_FUNDS_CREATE_BILLING_INFO_FORM = 'add-funds-create-billing-info-form';

interface AddFundsModalButtonCardBillingInfoAndCardStepProps {
  companyNanoId: TeamNanoID;
  companyDeferredPayment: TeamDeferredPayment;
  companyCashBalance: number;
  companyTerms: TeamTerms;
  addFundsData: AddFundsModalButtonData;
  onStepBack: AddFundsModalButtonStepBackAction;
}

function AddFundsModalButtonCardBillingInfoAndCardStep({
  companyNanoId,
  companyDeferredPayment,
  companyCashBalance,
  companyTerms,
  addFundsData,
  onStepBack
}: AddFundsModalButtonCardBillingInfoAndCardStepProps) {
  const [paid, setPaid] = useState<boolean>(false);

  const currentUser = useCurrentUser();
  const currentUserId = currentUser.get('id');

  const { billingInfos, billingInfosErrorMessage, billingInfosLoading } =
    useAddFundsModalButtonBillingInfos({ companyNanoId });

  const {
    control,
    registerFields,
    validationErrors,
    handleCreateBillingInfoWithResponse,
    createBillingInfoLoading,
    createBillingInfoErrorMessage,
    watchCountry,
    watchPaymentMethod
  } = useCreateBillingInfoForm({
    companyNanoId,
    cacheKey: BillingInfoCache.addFundsCacheKey(companyNanoId)
  });

  const { createInvoiceLoading, createInvoiceErrorMessage, createInvoice } =
    useCreateInvoice();
  const { payInvoiceLoading, payInvoiceErrorMessage, handlePayInvoice } =
    usePayInvoice();

  // TODO use widget properly with valid listUrl
  const { theme } = useTheme();
  useEffect(() => {
    PayoneerCheckoutPayment.runWidget({
      theme,
      listUrl: '',
      widgetId: generateNanoId()
    });
  }, [theme]);

  const [billingInfo, setBillingInfo] =
    useState<AddFundsModalButtonDataBillingInfo | null>(null);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

  const defaultBillingInfo = maxBy(billingInfos, 'defaultAt');
  useEffect(() => {
    if (defaultBillingInfo && !billingInfo && !showCreateForm) {
      setBillingInfo(defaultBillingInfo);
    }
  }, [billingInfo, defaultBillingInfo, showCreateForm]);

  const handleSelectBillingInfo = useCallback<
    (billingInfo: AddFundsModalButtonDataBillingInfo) => void
  >((billingInfo) => {
    setBillingInfo(billingInfo);
    setShowCreateForm(false);
  }, []);

  const handleShowCreateForm = useCallback<() => void>(() => {
    setBillingInfo(null);
    setShowCreateForm(true);
  }, []);

  const handleProceed = useCallback<() => void>(async () => {
    const billingInfoData = showCreateForm
      ? await handleCreateBillingInfoWithResponse()
      : billingInfo;

    if (showCreateForm) {
      handleSelectBillingInfo(billingInfoData);
    }

    const invoiceData = await createInvoice({
      invoice: {
        amount: addFundsData.paymentMethodAndAmount?.amount,
        payerId: currentUserId,
        paymentMethod: addFundsData.paymentMethodAndAmount?.method,
        billingInfoId: billingInfoData?.id,
        dueDate: calcInvoiceDueDate(companyDeferredPayment),
        terms: companyTerms,
        notes: billingInfoData?.avBillingInfo?.notes
      }
    });

    await handlePayInvoice({
      invoiceId: invoiceData?.id,
      invoice: {
        paymentMethod: addFundsData.paymentMethodAndAmount.method,
        transactionFeeExpense: floor(
          (addFundsData.paymentMethodAndAmount?.amount *
            DEFAULT_TRANSACTION_FEE) /
            100,
          2
        ),
        confirmationId: generateNanoId()
      }
    });

    setPaid(true);
  }, [
    companyDeferredPayment,
    companyTerms,
    addFundsData,
    billingInfo,
    showCreateForm,
    currentUserId,
    handleSelectBillingInfo,
    handleCreateBillingInfoWithResponse,
    createInvoice,
    handlePayInvoice
  ]);

  // temp
  if (paid) {
    return <div className="p-4 text-center">Paid</div>;
  }

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto px-2 z-0">
        <div className="p-4 space-y-12">
          <AddFundsModalButtonGeneralInfo
            companyCashBalance={companyCashBalance}
            paymentMethod={addFundsData?.paymentMethodAndAmount?.method}
            amount={addFundsData?.paymentMethodAndAmount?.amount}
          />
          <div className="space-y-8">
            <fieldset>
              <legend className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                <Translate id={invoicesKeys.billingInfo} />
              </legend>
              <div id={paymentNetworksId} />
            </fieldset>
            <fieldset>
              <legend className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                <Translate id={invoicesKeys.billingInfo} />
              </legend>
              <LoadingSkeleton loaded={!billingInfosLoading}>
                <AddFundsModalButtonBillingInfoSelect
                  billingInfos={billingInfos}
                  selectedBillingInfoId={billingInfo?.id}
                  showCreateForm={showCreateForm}
                  onBillingInfoSelect={handleSelectBillingInfo}
                  onShowCreateForm={handleShowCreateForm}
                />
              </LoadingSkeleton>
              <AlertMessage message={billingInfosErrorMessage} />
            </fieldset>
            {showCreateForm ? (
              <fieldset className="space-y-2 px-2">
                <AddFundsCreateBillingInfoForm
                  control={control}
                  form={ADD_FUNDS_CREATE_BILLING_INFO_FORM}
                  isLoading={createBillingInfoLoading}
                  registerCompanyName={registerFields.registerCompanyName}
                  registerFirstName={registerFields.registerFirstName}
                  registerLastName={registerFields.registerLastName}
                  registerContactPhone={registerFields.registerContactPhone}
                  registerContactEmail={registerFields.registerContactEmail}
                  registerCity={registerFields.registerCity}
                  registerAddress={registerFields.registerAddress}
                  registerZipCode={registerFields.registerZipCode}
                  companyNameValidationError={
                    validationErrors.companyNameValidationError
                  }
                  firstNameValidationError={
                    validationErrors.firstNameValidationError
                  }
                  lastNameValidationError={
                    validationErrors.lastNameValidationError
                  }
                  contactPhoneValidationError={
                    validationErrors.contactPhoneValidationError
                  }
                  contactEmailValidationError={
                    validationErrors.contactEmailValidationError
                  }
                  avBillingInfoIdValidationError={
                    validationErrors.avBillingInfoIdValidationError
                  }
                  cityValidationError={validationErrors.cityValidationError}
                  countryValidationError={
                    validationErrors.countryValidationError
                  }
                  stateValidationError={validationErrors.stateValidationError}
                  addressValidationError={
                    validationErrors.addressValidationError
                  }
                  zipCodeValidationError={
                    validationErrors.zipCodeValidationError
                  }
                  watchCountry={watchCountry}
                  watchPaymentMethod={watchPaymentMethod}
                />
                <div className="px-4">
                  <AlertMessage message={createBillingInfoErrorMessage} />
                </div>
              </fieldset>
            ) : null}
          </div>
          <AlertMessage
            message={createInvoiceErrorMessage || payInvoiceErrorMessage}
          />
        </div>
      </div>
      <div className="flex p-4 space-x-1 justify-between">
        <div className="flex space-x-1" />
        <div className="flex space-x-1" />
        <div className="flex space-x-1">
          <PureButtonHelper
            i18nText={words.back}
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            onClick={onStepBack}
          />
          <PureButtonHelper
            i18nText={words.proceed}
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
            disabled={
              (!billingInfo && !showCreateForm) ||
              createInvoiceLoading ||
              payInvoiceLoading ||
              createBillingInfoLoading
            }
            onClick={handleProceed}
          />
        </div>
      </div>
    </div>
  );
}

export default AddFundsModalButtonCardBillingInfoAndCardStep;
