import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SmartContractsKeyType {
  av: LocalesKey;
  avGetsFixedShare: LocalesKey;
  avGetsRemainderAfterAllOtherParticipants: LocalesKey;
  avMinimumShare: LocalesKey;
  avShareScheme: LocalesKey;
  archivizer: LocalesKey;
  areYuSureYouWantToCancelTheSmartContract: LocalesKey;
  amount: LocalesKey;
  allSmartContracts: LocalesKey;
  bound: LocalesKey;
  basicSmartContract: LocalesKey;
  cancel: LocalesKey;
  canceled: LocalesKey;
  created: LocalesKey;
  closed: LocalesKey;
  changeShare: LocalesKey;
  checkIn: LocalesKey;
  checkTheAvailabilityOfAllSmartContractRules: LocalesKey;
  contractorRule: LocalesKey;
  delete: LocalesKey;
  dueDate: LocalesKey;
  draftSmartContract: LocalesKey;
  fees: LocalesKey;
  forBasicWorkflow: LocalesKey;
  forPaymentsFromAv: LocalesKey;
  forPaymentsFromPfr: LocalesKey;
  fixed: LocalesKey;
  id: LocalesKey;
  internalSmartContract: LocalesKey;
  internalSmartContractIsUsedForPaymentsFromPfrPfbdOrDirectlyFromArchivizer: LocalesKey;
  invoices: LocalesKey;
  holdRate: LocalesKey;
  label: LocalesKey;
  name: LocalesKey;
  net: LocalesKey;
  netAmount: LocalesKey;
  noAvShareInSmartContract: LocalesKey;
  noParticipantsYet: LocalesKey;
  openSmartContract: LocalesKey;
  opened: LocalesKey;
  participants: LocalesKey;
  paid: LocalesKey;
  payer: LocalesKey;
  payFromAV: LocalesKey;
  payFromPFR: LocalesKey;
  payFromPFBD: LocalesKey;
  plural: LocalesKey;
  pfbd: LocalesKey;
  pfr: LocalesKey;
  project: LocalesKey;
  postponed: LocalesKey;
  provisionForRevision: LocalesKey;
  provisionForBadDebt: LocalesKey;
  revision: LocalesKey;
  revisionSmartContract: LocalesKey;
  residual: LocalesKey;
  removeFromContract: LocalesKey;
  role: LocalesKey;
  singular: LocalesKey;
  scf: LocalesKey;
  smartContractFee: LocalesKey;
  smartContractFees: LocalesKey;
  smartContractTotal: LocalesKey;
  share: LocalesKey;
  status: LocalesKey;
  statuses: {
    open: LocalesKey;
    canceled: LocalesKey;
    draft: LocalesKey;
    done: LocalesKey;
  };
  statusLabels: {
    all: LocalesKey;
    open: LocalesKey;
    canceled: LocalesKey;
    closed: LocalesKey;
    draft: LocalesKey;
    done: LocalesKey;
  };
  task: LocalesKey;
  totalAmount: LocalesKey;
  terms: LocalesKey;
  transactionFee: LocalesKey;
  trx: LocalesKey;
  value: LocalesKey;
}

export const smartContractsKeys = keyPathMirror<SmartContractsKeyType, string>(
  {
    av: null,
    avGetsFixedShare: null,
    avGetsRemainderAfterAllOtherParticipants: null,
    avMinimumShare: null,
    avShareScheme: null,
    archivizer: null,
    areYuSureYouWantToCancelTheSmartContract: null,
    amount: null,
    allSmartContracts: null,
    bound: null,
    basicSmartContract: null,
    cancel: null,
    canceled: null,
    created: null,
    closed: null,
    changeShare: null,
    checkIn: null,
    checkTheAvailabilityOfAllSmartContractRules: null,
    contractorRule: null,
    delete: null,
    dueDate: null,
    draftSmartContract: null,
    fees: null,
    forBasicWorkflow: null,
    forPaymentsFromAv: null,
    forPaymentsFromPfr: null,
    fixed: null,
    id: null,
    internalSmartContract: null,
    internalSmartContractIsUsedForPaymentsFromPfrPfbdOrDirectlyFromArchivizer:
      null,
    invoices: null,
    holdRate: null,
    label: null,
    name: null,
    net: null,
    netAmount: null,
    noAvShareInSmartContract: null,
    noParticipantsYet: null,
    openSmartContract: null,
    opened: null,
    participants: null,
    paid: null,
    payer: null,
    payFromAV: null,
    payFromPFR: null,
    payFromPFBD: null,
    plural: null,
    pfbd: null,
    pfr: null,
    project: null,
    postponed: null,
    provisionForRevision: null,
    provisionForBadDebt: null,
    revision: null,
    revisionSmartContract: null,
    residual: null,
    removeFromContract: null,
    role: null,
    scf: null,
    singular: null,
    smartContractFee: null,
    smartContractFees: null,
    smartContractTotal: null,
    status: null,
    statuses: {
      open: null,
      canceled: null,
      draft: null,
      done: null
    },
    statusLabels: {
      all: null,
      open: null,
      canceled: null,
      closed: null,
      draft: null,
      done: null
    },
    share: null,
    task: null,
    totalAmount: null,
    terms: null,
    transactionFee: null,
    trx: null,
    value: null
  },
  'models.smartContracts.'
);
