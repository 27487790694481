import { useEffect } from 'react';

import first from 'lodash/first';

import { TeamNanoID } from '../../../teams/teamsTypes';

import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../queries/fetchAccounts.query';

import { useFinPaginatedAccounts } from '../useFinPaginatedAccounts';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { AccountCache } from '../../AccountCache';

import { CASH_ACCOUNT_TYPE_CHART_NUMBER } from '../../accountsConstants';

interface AddFundsModalButtonContentProps {
  companyNanoId: TeamNanoID;
}

function AddFundsModalButtonContent({
  companyNanoId
}: AddFundsModalButtonContentProps) {
  const prevCompanyNanoId = usePreviousValue(companyNanoId);

  const {
    accounts,
    accountsErrorMessage,
    accountsFetched,
    changeAccountsFilters
  } = useFinPaginatedAccounts<FetchAccountsQueryResponse>({
    query: FETCH_ACCOUNTS_QUERY,
    cacheKey: AccountCache.companyCashAccountCacheKey(companyNanoId),
    initialFilters: {
      accountTypeChartNumber: { eq: CASH_ACCOUNT_TYPE_CHART_NUMBER },
      companyNanoId: { eq: companyNanoId }
    },
    options: {
      enabled: !!companyNanoId,
      enabledPlaceholder: !!companyNanoId
    }
  });

  useEffect(() => {
    if (prevCompanyNanoId !== companyNanoId) {
      changeAccountsFilters({
        companyNanoId: { eq: companyNanoId }
      });
    }
  }, [changeAccountsFilters, companyNanoId, prevCompanyNanoId]);

  return {
    companyCashAccount: first(accounts) || null,
    companyCashAccountErrorMessage: accountsErrorMessage,
    companyCashAccountFetched: accountsFetched
  };
}

export default AddFundsModalButtonContent;
