import React, { ReactNode } from 'react';
import { SimpleModalButtonDefaultProps } from './SimpleModalButton.types';

import { ModalOptions, useModal } from '../../modals/hooks/useModal';

import { SimpleModal } from '../../../helpers/modals/SimpleModal';
import { PureIconButtonHelper } from '../PureIconButtonHelper';
import { PureTooltipIconButtonHelper } from '../PureTooltipIconButtonHelper';
import { ButtonHelper } from '../ButtonHelper';

interface SimpleModalButtonProps {
  children: ReactNode;
}

function SimpleModalButton({
  afterSubmit,
  buttonChildren,
  children,
  className,
  i18nCancelText,
  i18nSubmitText,
  i18nCloseText,
  submitButtonColor,
  submitClass,
  buttonsContainerClassName,
  submitIcon,
  submitIconClassName,
  i18nText,
  buttonText,
  i18nTextClassName,
  i18nTitle,
  title,
  tooltipPlacement,
  tooltipI18nText,
  tooltipSingleton,
  icon,
  iconClassName,
  id,
  modalSize,
  isLoading,
  disabled,
  submitDisabled,
  modalIcon,
  modalIconClassName,
  withoutCancelButton,
  withoutButtons,
  withCloseButton,
  withoutSubmitButton,
  childrenClassName,
  preventModalClose,
  allowPropagation,
  notHideOnSubmit,
  onClose,
  onOpen,
  onCancel,
  closeOnCancel,
  cancelButtonPosition,
  onSubmit,
  onToggle,
  onMouseEnter,
  withoutInitialFocus
}: SimpleModalButtonProps & SimpleModalButtonDefaultProps & ModalOptions) {
  const { isOpen, showModal, hideModal, handleSubmit } = useModal({
    onOpen,
    onClose,
    onToggle,
    onSubmit,
    afterSubmit,
    preventModalClose,
    notHideOnSubmit
  });

  const pureIconI18nTextButton = buttonText ? null : (
    <PureIconButtonHelper
      className={className}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      text={buttonText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
    />
  );

  const pureIconTextButton = buttonText ? (
    <PureIconButtonHelper
      className={className}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
    />
  ) : null;

  const tooltipI18nTextButton = buttonText ? null : (
    <PureTooltipIconButtonHelper
      className={className}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
      tooltipSingleton={tooltipSingleton}
    />
  );

  const tooltipTextButton = buttonText ? (
    <PureTooltipIconButtonHelper
      className={className}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      text={buttonText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
      tooltipSingleton={tooltipSingleton}
    />
  ) : null;

  const button = buttonChildren ? (
    <ButtonHelper
      className={className}
      disabled={disabled}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
    >
      {buttonChildren}
    </ButtonHelper>
  ) : null;

  return (
    <>
      {button ||
        tooltipI18nTextButton ||
        tooltipTextButton ||
        pureIconTextButton ||
        pureIconI18nTextButton}

      <SimpleModal
        handleSubmit={handleSubmit}
        hideModal={hideModal}
        i18nCancelText={i18nCancelText}
        i18nSubmitText={i18nSubmitText}
        i18nCloseText={i18nCloseText}
        submitButtonColor={submitButtonColor}
        buttonsContainerClassName={buttonsContainerClassName}
        submitClass={submitClass}
        submitIcon={submitIcon}
        submitIconClassName={submitIconClassName}
        i18nTitle={i18nTitle}
        title={title}
        modalSize={modalSize}
        id={id}
        isLoading={isLoading}
        disabled={disabled}
        submitDisabled={submitDisabled}
        isOpen={isOpen}
        modalIcon={modalIcon}
        modalIconClassName={modalIconClassName}
        withoutCancelButton={withoutCancelButton}
        withoutButtons={withoutButtons}
        withoutSubmitButton={withoutSubmitButton}
        withCloseButton={withCloseButton}
        childrenClassName={childrenClassName}
        allowPropagation={allowPropagation}
        onCancel={onCancel}
        closeOnCancel={closeOnCancel}
        cancelButtonPosition={cancelButtonPosition}
        withoutInitialFocus={withoutInitialFocus}
      >
        {children}
      </SimpleModal>
    </>
  );
}

export default SimpleModalButton;
