import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

interface StringsType {
  applyToAll: LocalesKey;
  alreadyAdded: LocalesKey;
  areYouSureThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToClearAllFormFields: LocalesKey;
  areYouSureYouWantToDeleteThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToCancelTheTask: LocalesKey;
  areYouSureYouWantToClearSelected: LocalesKey;
  areYouSureYouWantToDeletTheBillingAddress: LocalesKey;
  areYouSureYouWantToDeleteAccount: LocalesKey;
  areYouSureYouWantToBlockThisAccount: LocalesKey;
  areYouSureYouWantToUnblockThisAccount: LocalesKey;
  areYouSureYouWantToDeleteThisAccount: LocalesKey;
  areYouSureYouWantToRemoveThisUserFromCompany: LocalesKey;
  areYouSureYouWantToBlockThisCompany: LocalesKey;
  atLeastOneTaskMustBeEntered: LocalesKey;
  byRatingTasksYouAcceptTaskResultsForTheClient: LocalesKey;
  changeVisibility: LocalesKey;
  dragAndDropFilesOr: LocalesKey;
  dropTheFilesHere: LocalesKey;
  filterByDate: LocalesKey;
  helpWithTextFormatting: LocalesKey;
  pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly: LocalesKey;
  pleaseProvideYourAddress: LocalesKey;
  pleaseRateTheTask: LocalesKey;
  pleaseRateTaskRusult: LocalesKey;
  previewTextFormatting: LocalesKey;
  iConfirmThatIHaveReadAndAgreeTo: LocalesKey;
  acceptTaskResult: LocalesKey;
  indicateHowMuchDoYouAgreeWithTheFollowingStatement: LocalesKey;
  approveTaskResult: LocalesKey;
  weAreOnline: LocalesKey;
  weAreReadyToHelp: LocalesKey;
  writeHereWarning: LocalesKey;
  textFormattingHelp: LocalesKey;
  thePaymentHasBeenAcceptedForProcessingUsually: LocalesKey;
  thePaymentHasBeenCanceled: LocalesKey;
  theTaskResultHasBeenApproved: LocalesKey;
  thisCommentWouldBeVisibleOnlyInTimeline: LocalesKey;
  thisActionWillAffect: LocalesKey;
  youWillBeRedirectedToTheProjectPageIn: LocalesKey;
  youWillBeRedirectedToThePaymentsPageIn: LocalesKey;
  startTypingToSearchForClients: LocalesKey;
  errorLoadingImage: LocalesKey;
  updateCost: LocalesKey;
  updateDueDate: LocalesKey;
  pleaseWaitYourDownloadWillBeReadyInSeveralMinutes: LocalesKey;
  orChangeTableSettingsToAlwaysShowHiddenElements: LocalesKey;
  hiddenAnd: LocalesKey;
  beforeYouAcceptTheTaskWeLikeToRemindYouThat: LocalesKey;
  onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt: LocalesKey;
  youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask: LocalesKey;
  ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly: LocalesKey;
  pleaseRateResults: LocalesKey;
  thereAreNoNonValidTasks: LocalesKey;
  thereAreNoValidTasks: LocalesKey;
  pleaseClickOnStarsToRateTheResult: LocalesKey;
  linkToTheCRMaUsernameAndPasswordCombinationUsedForLoggingInToOnlineAccountEtc: LocalesKey;
  youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed: LocalesKey;
  selectInvoiceFromTheList: LocalesKey;
  sendEmailNotification: LocalesKey;
}

export const stringsKeys = keyPathMirror<StringsType, string>(
  {
    applyToAll: null,
    alreadyAdded: null,
    areYouSureThisActionCannotBeUndone: null,
    areYouSureYouWantToClearAllFormFields: null,
    areYouSureYouWantToDeleteThisActionCannotBeUndone: null,
    areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone: null,
    areYouSureYouWantToCancelTheTask: null,
    areYouSureYouWantToClearSelected: null,
    areYouSureYouWantToDeletTheBillingAddress: null,
    areYouSureYouWantToBlockThisAccount: null,
    areYouSureYouWantToUnblockThisAccount: null,
    areYouSureYouWantToDeleteAccount: null,
    areYouSureYouWantToDeleteThisAccount: null,
    areYouSureYouWantToRemoveThisUserFromCompany: null,
    areYouSureYouWantToBlockThisCompany: null,
    atLeastOneTaskMustBeEntered: null,
    byRatingTasksYouAcceptTaskResultsForTheClient: null,
    changeVisibility: null,
    dragAndDropFilesOr: null,
    dropTheFilesHere: null,
    filterByDate: null,
    helpWithTextFormatting: null,
    pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly:
      null,
    pleaseProvideYourAddress: null,
    pleaseRateTheTask: null,
    pleaseRateTaskRusult: null,
    previewTextFormatting: null,
    iConfirmThatIHaveReadAndAgreeTo: null,
    acceptTaskResult: null,
    approveTaskResult: null,
    weAreOnline: null,
    weAreReadyToHelp: null,
    writeHereWarning: null,
    textFormattingHelp: null,
    thePaymentHasBeenAcceptedForProcessingUsually: null,
    thePaymentHasBeenCanceled: null,
    theTaskResultHasBeenApproved: null,
    thisCommentWouldBeVisibleOnlyInTimeline: null,
    thisActionWillAffect: null,
    youWillBeRedirectedToTheProjectPageIn: null,
    youWillBeRedirectedToThePaymentsPageIn: null,
    startTypingToSearchForClients: null,
    errorLoadingImage: null,
    updateCost: null,
    updateDueDate: null,
    pleaseWaitYourDownloadWillBeReadyInSeveralMinutes: null,
    orChangeTableSettingsToAlwaysShowHiddenElements: null,
    pleaseClickOnStarsToRateTheResult: null,
    indicateHowMuchDoYouAgreeWithTheFollowingStatement: null,
    hiddenAnd: null,
    beforeYouAcceptTheTaskWeLikeToRemindYouThat: null,
    onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt:
      null,
    ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly:
      null,
    pleaseRateResults: null,
    thereAreNoNonValidTasks: null,
    thereAreNoValidTasks: null,
    youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask:
      null,
    linkToTheCRMaUsernameAndPasswordCombinationUsedForLoggingInToOnlineAccountEtc:
      null,
    youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed:
      null,
    selectInvoiceFromTheList: null,
    sendEmailNotification: null
  },
  'strings.'
);
