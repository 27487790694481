import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface MessagesKeyType {
  addToDescription: LocalesKey;
  all: LocalesKey;
  areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible: LocalesKey;
  areYouSureYouWantToSendThisMessageToAllProjectTasks: LocalesKey;
  authors: LocalesKey;
  emailNotificationSent: LocalesKey;
  flagged: LocalesKey;
  forward: LocalesKey;
  forwardMessageFrom: LocalesKey;
  forwardedFrom: LocalesKey;
  fromClients: LocalesKey;
  inProjectsWhereIAmAMember: LocalesKey;
  inTasksWhereIAmAMember: LocalesKey;
  markAllAsRead: LocalesKey;
  markAsRead: LocalesKey;
  markAsUnread: LocalesKey;
  messageId: LocalesKey;
  messageIds: LocalesKey;
  messageWithAttachments: LocalesKey;
  my: LocalesKey;
  new: LocalesKey;
  noPlural: LocalesKey;
  passwordWasSuccessfullyUpdated: LocalesKey;
  pinnedMessages: LocalesKey;
  plural: LocalesKey;
  project: LocalesKey;
  search: LocalesKey;
  selectAuthor: LocalesKey;
  send: LocalesKey;
  sendEmailNotification: LocalesKey;
  sending: LocalesKey;
  sent: LocalesKey;
  showOlder: LocalesKey;
  singular: LocalesKey;
  starred: LocalesKey;
  toLatestMessages: LocalesKey;
  tracked: LocalesKey;
  visible: LocalesKey;
  withAttachments: LocalesKey;
}

export const messagesKeys = keyPathMirror<MessagesKeyType, string>(
  {
    addToDescription: null,
    all: null,
    areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible: null,
    areYouSureYouWantToSendThisMessageToAllProjectTasks: null,
    authors: null,
    emailNotificationSent: null,
    flagged: null,
    forward: null,
    forwardMessageFrom: null,
    forwardedFrom: null,
    fromClients: null,
    inProjectsWhereIAmAMember: null,
    inTasksWhereIAmAMember: null,
    markAllAsRead: null,
    markAsRead: null,
    markAsUnread: null,
    messageId: null,
    messageIds: null,
    messageWithAttachments: null,
    my: null,
    new: null,
    noPlural: null,
    passwordWasSuccessfullyUpdated: null,
    pinnedMessages: null,
    plural: null,
    project: null,
    search: null,
    selectAuthor: null,
    send: null,
    sendEmailNotification: null,
    sending: null,
    sent: null,
    showOlder: null,
    singular: null,
    starred: null,
    toLatestMessages: null,
    tracked: null,
    visible: null,
    withAttachments: null
  },
  'models.messages.'
);
