export const enum IconsEnum {
  ADJUSTMENTS_OUTLINE = 'adjustments_outline',
  ANDROID = 'android',
  ANNOTATION_SOLID = 'annotation_solid',
  APPLE = 'apple',
  ARROWS_EXPAND_SOLID = 'arrows_expand_solid',
  ARROWS_EXPAND_OUTLINE = 'arrows_expand_outline',
  ARROW_CIRCLE_UP_SOLID = 'arrow_circle_up_solid',
  ARROW_DOWN = 'arrow_down',
  ARROW_DOWN_TRIANGLE_SOLID = 'arrow_down_triangle_solid',
  ARROW_LEFT = 'arrow_left',
  ARROW_LEFT_OUTLINE = 'arrow_left_outline',
  ARROW_NARROW_UP_OUTLINE = 'arrow_narrow_up_outline',
  ARROW_PATH_OUTLINE = 'arrow_path_outline',
  ARROW_NARROW_RIGHT_SOLID = 'arrow_narrow_right_solid',
  ARROW_RIGHT_SOLID = 'arrow_right_solid',
  ARROW_SM_DOWN = 'arrow_sm_down',
  ARROW_SM_LEFT_OUTLINE = 'arrow_sm_left_outline',
  AT_SYMBOL = 'at_symbol',
  AV = 'av',
  ARCHIVE_BOX_X_MARK = 'archive_box_x_mark',
  ARCHIVE_BOX_X_MARK_OUTLINE = 'archive_box_x_mark_outline',
  BADGE_CHECK = 'badge_check',
  BELL = 'bell',
  BELL_OUTLINE = 'bell_outline',
  BLANK = 'blank',
  BLOCK = 'block',
  BOOK_OPEN_SOLID = 'book_open_solid',
  BLUE_BELL = 'blue_bell',
  BRIEFCASE_SOLID = 'briefcase_solid',
  BURNING = 'burning',
  CALENDAR_OUTLINE = 'calendar_outline',
  CALENDAR_SOLID = 'calendar_solid',
  CASH = 'cash',
  CASH_OUTLINE = 'cash_outline',
  CHAT = 'chat',
  CHAT_OUTLINE = 'chat_outline',
  CHAT_ALT = 'chat_alt',
  CHART_BAR = 'chart_bar',
  CHART_BAR_SQUARE_SOLID = 'chart_bar_square_solid',
  CHART_PIE = 'chart_pie',
  CHART_SQUARE_BAR = 'chart_square_bar',
  CHECK = 'check',
  CHECK_OUTLINE = 'check_outline',
  CHECK_CIRCLE_OUTLINE = 'check_circle_outline',
  CHECK_CIRCLE_SOLID = 'check_circle_solid',
  TEMPLATE_SOLID = 'template_solid',
  TRASH_OUTLINE = 'trash_outline',
  TRASH_SOLID = 'trash_solid',
  TRENDING_DOWN_SOLID = 'trending_down_solid',
  TRENDING_UP_SOLID = 'trending_up_solid',
  CHEVRON_DOWN = 'chevron_down',
  CHEVRON_DOWN_SOLID = 'chevron_down_solid',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  CHEVRON_UP = 'chevron_up',
  CIRCLE_OUTLINE = 'circle_outline',
  CIRCLE_SOLID = 'circle_solid',
  CLIPBOARD_CHECK_SOLID = 'clipboard_check_solid',
  CLIPBOARD_CHECK_OUTLINE = 'clipboard_check_outline',
  CLIPBOARD_DOCUMENT_CHECK_OUTLINE = 'clipboard_document_check_outline',
  CLIPBOARD_DOCUMENT_CHECK_SOLID = 'clipboard_document_check_solid',
  CLIPBOARD_LIST_SOLID = 'clipboard_list_solid',
  CLOCK = 'clock',
  CLOCK_OUTLINE = 'clock_outline',
  CLOUD_UPLOAD_OUTLINE = 'cloud_upload_outline',
  CLOUD_UPLOAD_SOLID = 'cloud_upload_solid',
  COG = 'cog',
  COG_EMPTY = 'cog_empty',
  COLLECTION_OUTLINE = 'collection_outline',
  COLLECTION_SOLID = 'collection_solid',
  COLOR_SWATCH_OUTLINE = 'color_swatch_outline',
  CONTACT_SUPPORT = 'contact_support',
  COPY = 'copy',
  CORONA_SOLID = 'corona_solid',
  CREDIT_CARD_SOLID = 'credit_card_solid',
  CROSS = 'cross',
  CROSSED_BELL = 'crossed_bell',
  CUBE_OUTLINE = 'cube_outline',
  CUBE_SOLID = 'cube_solid',
  CUBE_TRANSPARENT_OUTLINE = 'cube_transparent_outline',
  CURRENCY_DOLLAR_SOLID = 'currency_dollar_solid',
  DATABASE_OUTLINE = 'database_outline',
  DOCUMENT_DOWNLOAD_OUTLINE = 'document_download_outline',
  DOCUMENT_DUPLICATE_OUTLINE = 'document_duplicate_outline',
  DOCUMENT_OUTLINE = 'document_outline',
  DOCUMENT_PDF_SOLID = 'document_pdf_solid',
  DOCUMENT_ARCHIVE_SOLID = 'document_archive_solid',
  DOCUMENT_AUDIO_SOLID = 'document_audio_solid',
  DOCUMENT_REPORT_SOLID = 'document_report_solid',
  DOCUMENT_REPORT_OUTLINE = 'document_report_outline',
  DOCUMENT_TABLE_OUTLINE = 'document_table',
  DOCUMENT_TEXT_OUTLINE = 'document_text',
  DOCUMENT_TEXT_SOLID = 'document_text_solid',
  DOCUMENT_UPLOAD_OUTLINE = 'document_upload_outline',
  DOCUMENT_UPLOAD_SOLID = 'document_upload_solid',
  DOCUMENT_VIDEO_SOLID = 'document_video_solid',
  DOCUMENT_SOLID = 'document_solid',
  DOCUMENT_MEDIAS = 'document_medias',
  DOCUMENT_MINUS_SOLID = 'document_minus_solid',
  DOTS_HORIZONTAL_OUTLINE = 'dots_horizontal_outline',
  DOWNLOAD_OUTLINE = 'download_outline',
  DOWNLOAD_SOLID = 'download_solid',
  DOWN_ARROW = 'down_arrow',
  EMAIL_SOLID = 'email_solid',
  EMPTY_STAR = 'empty_star',
  ENVELOPE = 'envelope',
  EXCLAMATION = 'exclamation',
  EXCLAMATION_CIRCLE = 'exclamation_circle',
  EXCLAMATION_SOLID = 'exclamation_solid',
  EXTERNAL_LINK_SOLID = 'external_link_solid',
  EYE = 'eye',
  EYE_CROSSED = 'eye_crossed',
  EYE_SOLID = 'eye_solid',
  EYE_SPLASH_SOLID = 'eye_splash_solid',
  FILLED_STAR = 'filled_star',
  FILTER = 'filter',
  FILTER_SOLID = 'filter_solid',
  FIRE_SOLID = 'fire_solid',
  FIRE_OUTLINE = 'fire_outline',
  FLAG = 'flag',
  FOLDER_CUBE_SOLID = 'folder_cube_solid',
  FOLDER_DOWNLOAD = 'folder_download',
  FOLDER_FILE_SOLID = 'folder_file_solid',
  FOLDER_IMAGE_SOLID = 'folder_image_solid',
  FOLDER_MATERIAL_SOLID = 'folder_material_solid',
  FOLDER_OPEN_OUTLINE = 'folder_open_outline',
  FOLDER_RULERS_SOLID = 'folder_rulers_solid',
  FOLDER_SOLID = 'folder_solid',
  FORWARD = 'forward',
  HASH_OUTLINE = 'hash_outline',
  HEART_OUTLINE = 'heart_outline',
  HEART_SOLID = 'heart_solid',
  HOME = 'home',
  IDENTIFICATION = 'identification',
  INFORMATION_CIRCLE_SOLID = 'information_circle_solid',
  INFORMATION_CIRCLE_OUTLINE = 'information_circle_outline',
  INBOX_OUTLINE = 'inbox_outline',
  KEY = 'key',
  LABEL_OUTLINE = 'label_outline',
  LABEL_SOLID = 'label_solid',
  LIGHTING_BOLT_SOLID = 'lighting_bolt_solid',
  LINK_CROSSED_ALT_SOLID = 'link_crossed_alt_solid',
  LINK_SOLID = 'link_solid',
  LIST_BULLET_ALT = 'list_bullet_alt',
  LOCATION = 'location',
  LOCK_CLOSE = 'lock_close',
  LOCK_OPEN = 'lock_open',
  LOGOUT = 'logout',
  MAIL = 'mail',
  MAIL_OPEN = 'mail_open',
  MAIL_OUTLINE = 'mail_outline',
  MAGNIFYING_GLASS_CORCLE_SOLID = 'magnifying_glass_circle_solid',
  MATERIAL_OUTLINE = 'material_outline',
  MENU_ALT_SOLID = 'menu_alt_solid',
  MENU_OUTLINE = 'menu_outline',
  MINUS_SOLID = 'minus_solid',
  MINUS_SM_SOLID = 'minus_sm_solid',
  NEWSPAPER_SOLID = 'newspaper_solid',
  OFFICE_BUILDING_OUTLINE = 'office_building_outline',
  PAPER_AIRPLANE_SOLID = 'paper_airplane_solid',
  PAPER_CLIP_OUTLINE = 'paper_clip_outline',
  PAPER_CLIP_SOLID = 'paper_clip_solid',
  PAUSE_OUTLINE = 'pause_outline',
  PAUSE_SOLID = 'pause_solid',
  PENCIL = 'pencil',
  PENCIL_ALT_OUTLINE = 'pencil_alt_outline',
  PENCIL_ALT_SOLID = 'pencil_alt_solid',
  PENCIL_OUTLINE = 'pencil_outline',
  PHONE = 'phone',
  PHONE_SOLID = 'phone_solid',
  PHOTOGRAPH_OUTLINE = 'photograph_outline',
  PHOTOGRAPH_SOLID = 'photograph_solid',
  PIN = 'pin',
  PLAY_OUTLINE = 'play_outline',
  PLAY_SOLID = 'play_solid',
  PLUS = 'plus',
  PLUS_CIRCLE_OUTLINE = 'plus_circle_outline',
  PLUS_OUTLINE = 'plus_outline',
  PLUS_SOLID = 'plus_solid',
  PLUS_SM_SOLID = 'plus_sm_solid',
  PULSE_OUTLINE = 'pulse_outline',
  PULSE_SOLID = 'pulse_solid',
  PROJECTS = 'projects',
  QUESTION_MARK_CIRCLE_OUTLINE = 'question_mark_circle_outline',
  QUESTION_MARK_CIRCLE_SOLID = 'question_mark_circle_solid',
  REACT = 'react',
  RECEIPT_REFUND = 'receipt_refund',
  REFRESH_SOLID = 'refresh_solid',
  REPLY_OUTLINE = 'reply_outline',
  REPLY_SOLID = 'reply_solid',
  RECEIPT_REFUND_OUTLINE = 'receipt_refund_outline',
  ROCKET_OUTLINE = 'rocket_outline',
  ROCKET_SOLID = 'rocket_solid',
  RULERS_OUTLINE = 'rulers_outline',
  SEARCH = 'search',
  SELECTOR = 'selector',
  SERVER_SOLID = 'server_solid',
  SHIELD_CHECK_OUTLINE = 'shield_check_outline',
  SHIELD_CHECK_SOLID = 'shield_check_solid',
  SHIELD_EXCLAMATION = 'shield_exclamation',
  SMASHING_CGI = 'smashing_cgi',
  SMILE = 'smile',
  SORT_ASC = 'sort_asc',
  SORT_DESC = 'sort_desc',
  SQUARE_3_STACK_3D = 'square_3_stack_3d',
  SQUARE_3_STACK_3D_OUTLINE = 'square_3_stack_3d_outline',
  STARS_MULTIPLE_SOLID = 'stars_multiple_solid',
  STOP_OUTLINE = 'stop_outline',
  STOP_SOLID = 'stop_solid',
  SWITCH_HORIZONTAL_SOLID = 'switch_horizontal_solid',
  TAG_OUTLINE = 'tag_outline',
  TOP_ARROW = 'top_arrow',
  TRANSFER_INCOMING_SOLID = 'transfer_incoming_solid',
  TRANSFER_OUTGOING_SOLID = 'transfer_outgoing_solid',
  TRANSLATE = 'translate',
  TICKET_SOLID = 'ticket',
  UPLOAD_SOLID = 'upload_solid',
  USER_SOLID = 'user_solid',
  USER_ADD_OUTLINE = 'user_add_outline',
  USER_ADD_SOLID = 'user_add_solid',
  USER_SUBTRACT_OUTLINE = 'user_subtract_outline',
  USER_CIRCLE = 'user_circle',
  USER_CIRCLE_SOLID = 'user_circle_solid',
  USER_GROUP = 'user_group',
  USER_GROUP_SOLID = 'user_group_solid',
  USER_ORGANIZATION = 'user_organization',
  USERS_SOLID = 'users_solid',
  VIEW_GRID = 'view_grid',
  VIEW_LIST = 'view_list',
  VIEW_LIST_SOLID = 'view_list_solid',
  VRAY_SOLID = 'vray_solid',
  WITHDRAWAL_OUTLINE = 'withdrawal_outline',
  WRENCH_SCREWDRIVER_OUTLINE = 'wrench_screwdriver_outline',
  X = 'x',
  X_CIRCLE = 'x_circle',
  X_CIRCLE_OUTLINE = 'x_circle_outline',
  X_OUTLINE = 'x_outline'
}

export interface IconProps {
  className?: string;
  id?: string;
  strokeWidth?: string;
}
