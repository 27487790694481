export default {
  plural: 'Reports',
  create: 'Create Report',
  delete: 'Delete Report',
  requeue: 'Requeue Report',
  total: 'Total',
  initialized: 'Initialized',
  processing: 'Processing',
  failed: 'Failed',
  errorMessage: 'Error Message',
  completed: 'Completed',
  goToTheReportsPage: 'Go to the reports page.',
  exportData: 'Export data',
  types: {
    projectsGlobalReport: 'Projects Global Report',
    projectsGlobalLite1Report: 'Projects Global Lite 1 Report',
    projectsGlobalLite2Report: 'Projects Global Lite 2 Report',
    projectsGlobalLite3Report: 'Projects Global Lite 3 Report',
    tasksIndexReport: 'Tasks Index Report',
    tasksGlobalReport: 'Tasks Global Report',
    tasksGlobalLiteReport: 'Tasks Global Lite Report',
    tasksGlobalLite1Report: 'Tasks Global Lite 1 Report',
    tasksGlobalLite2Report: 'Tasks Global Lite 2 Report',
    tasksGlobalLite3Report: 'Tasks Global Lite 3 Report',
    clientsGlobalReport: 'Clients Global Report',
    clientsGlobalLiteReport: 'Clients Global Lite Report',
    clientsGlobalLite1Report: 'Clients Global Lite 1 Report',
    clientsPulseReport: 'Clients Pulse Report',
    workersPulseReport: 'Workers Pulse Report',
    workersGlobalReport: 'Workers Global Report',
    rangeRevenueRecognitionReport: 'Range Revenue Recognition Report',
    totalClientPaymentsReport: 'Total Client Payments Report',
    clientsRevenueReport: 'Clients Revenue Report',
    workersRevenueReport: 'Workers Revenue Report',
    clientToWorkerPaymentsReport: 'Client To Worker Payments Report',
    receivedPaymentsReport: 'Received Payments Report',
    workersTimeReport: 'Workers Time Report',
    revenueShareReport: 'Revenue Share Report',
    clientsAveragePaymentFrequencyReport:
      'Clients Average Payment Frequency Report',
    workersDownloadsReport: 'Workers Downloads Report',
    workersDownloadsDetailedReport: 'Workers Downloads Detailed Report',
    globalProjectsBalanceReport: 'Global Projects Balance Report',
    globalTasksBalanceReport: 'Global Tasks Balance Report',
    clientsBalancesReport: 'Clients Balances Report',
    workersBalancesReport: 'Workers Balances Report',
    suspendedTasksReport: 'Suspended Tasks Report',
    globalProjectsDebtReport: 'Global Projects Debt Report',
    globalTasksDebtReport: 'Global Tasks Debt Report',
    globalClientsDebtReport: 'Global Clients Debt Report',
    globalWorkersDebtReport: 'Global Workers Debt Report',
    globalTeamsAndClientsReport: 'Global Teams And Clients Report',
    tasksAccountReceivableReport: 'Tasks Account Receivable Report',
    rfaReport: 'RFA Report',
    libraryUsageReport: 'Library Usage Report',
    clientsFilesUsageReport: 'Clients Files Usage Report',
    clientsRequirementsFilesReport: 'Clients Requirements Files Report',
    blockedUsersAllocationsReport: 'Blocked Users Allocations Report',
    burnedTasksReport: 'Burned Tasks Report',
    tasksCheckListsReport: 'Tasks Checklists Report',
    replyRateReport: 'Reply Rate Report',
    postponedTermsReport: 'Postponed Terms Report',
    taggedTasksReport: 'Tagged Tasks Report',
    actqReport: '## ACTQ Report',
    managersDynamicsReport: 'Managers Dynamics Report',
    doubleDistributionsReport: 'Double Distributions Report',
    minusDistributionsReport: 'Minus Distributions Report'
  }
};
