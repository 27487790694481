import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ItemTypesKeyType {
  plural: LocalesKey;
  defaultPlural: LocalesKey;
  search: LocalesKey;
  select: LocalesKey;
  create: LocalesKey;
  update: LocalesKey;
  delete: LocalesKey;
}

export const itemTypesKeys = keyPathMirror<ItemTypesKeyType, string>(
  {
    plural: null,
    defaultPlural: null,
    search: null,
    select: null,
    create: null,
    update: null,
    delete: null
  },
  'models.itemTypes.'
);
