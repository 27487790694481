import { useCallback, useRef, useState, KeyboardEvent } from 'react';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';

import { MultiSelectDataType, MultiSelectMixedDataType } from '../../types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

interface MultiSelectOptions {
  data?: MultiSelectMixedDataType[];
  emptyValue?: MultiSelectDataType | null;
  menuIsOpen?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  optionsLoading?: boolean;
}

function useMultiSelect({
  data,
  emptyValue,
  menuIsOpen,
  onMenuClose,
  onMenuOpen,
  optionsLoading
}: MultiSelectOptions) {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(menuIsOpen);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    onMenuClose?.();
  }, [setMenuOpen, onMenuClose]);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
    onMenuOpen?.();
  }, [setMenuOpen, onMenuOpen]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Escape' && isMenuOpen) {
        e.stopPropagation();
      }
    },
    [isMenuOpen]
  );

  const { t } = useTranslate();

  const localizeValue = useCallback(
    (value) =>
      isEmpty(value)
        ? null
        : {
            value: value.value,
            label: value.i18nLabel ? t(value.i18nLabel) : value.label
          },
    [t]
  );

  const dataWithEmptyValue = emptyValue
    ? compact(
        concat<MultiSelectMixedDataType | null>(localizeValue(emptyValue), data)
      )
    : data;

  const optionsLoadingRef = useRef(optionsLoading);
  optionsLoadingRef.current = optionsLoading;

  const handleOptionsLoadingMessage = useCallback(
    () => (optionsLoading ? t('words.searching') : t('words.noSearchResults')),
    [optionsLoading, t]
  );

  const handleFilterOption = useCallback(
    () => !optionsLoadingRef.current,
    [optionsLoadingRef]
  );

  return {
    dataWithEmptyValue,
    handleFilterOption,
    handleKeyDown,
    handleMenuClose,
    handleMenuOpen,
    handleOptionsLoadingMessage,
    localizeValue
  };
}

export default useMultiSelect;
