import { components, MultiValueProps } from 'react-select';

import { MultiSelectExtendedDataType } from '../../types';

import { Files } from '../../../../utils/Files';

import { ImageItemImageVersions } from '../../../ImageHelper';

function MultiValue({
  children,
  ...props
}: MultiValueProps<MultiSelectExtendedDataType>) {
  return (
    <components.MultiValue {...props}>
      {props.data?.image ? (
        <div className="flex items-center relative">
          <img
            className="absolute left-0 rounded-full h-6 w-6"
            src={Files.image(
              { file: props.data.image },
              ImageItemImageVersions.Thumb160x160
            )}
          />
          <span className="pl-8">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.MultiValue>
  );
}

export default MultiValue;
