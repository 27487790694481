import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserUUID } from '../users/usersTypes';
import { TeamClientStatusesEnum, TeamID } from './teamsTypes';

export class TeamCache {
  static showCacheKey() {
    return 'team';
  }

  static showBillingInfoCacheKey() {
    return 'team-billing-info';
  }

  static showCompanyCacheKey() {
    return 'company';
  }

  static showCompanyIdsCacheKey() {
    return 'company-ids';
  }

  static showCompanyRevenueCacheKey() {
    return 'company-revenue';
  }

  static showCompanyAccountReceivablesCacheKey() {
    return 'company-account-receivables';
  }

  static showCompanyItemTypesCacheKey() {
    return 'company-item-types';
  }

  static showCompanyAddFundsCacheKey() {
    return 'company-add-funds';
  }

  static showCompanyTimeZoneCacheKey() {
    return 'company-time-zone';
  }

  static showRedirectCacheKey(teamId: TeamID) {
    return `team-${teamId}-redirect`;
  }

  static indexSelectCacheKey(fieldName: string) {
    return `teams-select-${fieldName}`;
  }

  static defaultSelectCacheKey(fieldName: string) {
    return `default-teams-select-${fieldName}`;
  }

  static companiesCacheKey() {
    return 'companies';
  }

  static companiesMyCompaniesCacheKey() {
    return 'companies-my-companies';
  }

  static companiesUnassignedCacheKey() {
    return 'companies-unassigned';
  }

  static companiesFavoriteCacheKey() {
    return 'companies-favorite';
  }

  static companiesStatusCacheKey(teamClientStatus: TeamClientStatusesEnum) {
    return `companies-status-${teamClientStatus}`;
  }

  static companiesTotlaCountStatusCacheKey(
    teamClientStatus: TeamClientStatusesEnum
  ) {
    return `companies-total-count-status-${teamClientStatus}`;
  }

  static companiesTotlaCountFinStatusCacheKey(
    teamClientStatus: TeamClientStatusesEnum
  ) {
    return `companies-total-count-fin-status-${teamClientStatus}`;
  }

  static filteredCompaniesCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `companies-filtered-${searchFilterNanoId}`;
  }

  static performersCacheKey() {
    return 'performer-companies';
  }

  static performersFavoriteCacheKey() {
    return 'performer-companies-favorite';
  }

  static performersUnassignedCacheKey() {
    return 'performer-companies-unassigned';
  }

  static performersMyPerformersCacheKey() {
    return 'performer-companies-my-performers';
  }

  static filteredPerformersCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `performer-companies-filtered-${searchFilterNanoId}`;
  }

  static userCompany(userUuid: UserUUID) {
    return `user-${userUuid}-company`;
  }

  static selectedTeamSelectFieldCacheKey(fieldName: string) {
    return `team-select-field-${fieldName}`;
  }
}
