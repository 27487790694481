import { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { ClassName } from '../../../types';

import {
  followCursorPlugin,
  defaultDelay,
  defaultDuration,
  defaultOffset,
  defaultTouch,
  TooltipPlacement
} from '../tooltipsConstants';

import { Translate } from '../../Translate';

interface TooltipBaseProps<T> {
  referenceElement: T;
  withArrow?: boolean;
  placement?: TooltipPlacement;
  delay?: number | [number | null, number | null];
  duration?: number | [number | null, number | null];
  interactive?: boolean;
  followCursor?: boolean | 'horizontal' | 'vertical' | 'initial';
  offset?: [number, number];
  appendTo?: 'parent' | Element;
  className?: ClassName;
  zIndex?: number;
}

interface TooltipWithI18nProps {
  tooltipI18nText: string;
  tooltipText?: never;
  children?: never;
}

interface TooltipWithTextProps {
  tooltipI18nText?: never;
  tooltipText: string;
  children?: never;
}

interface TooltipWithChildrenProps {
  tooltipI18nText?: never;
  tooltipText?: never;
  children: ReactNode;
}

function Tooltip<T extends HTMLElement | null>({
  referenceElement,
  tooltipI18nText,
  tooltipText,
  children,
  withArrow = false,
  placement = TooltipPlacement.BOTTOM,
  interactive = false,
  followCursor,
  offset = defaultOffset,
  appendTo,
  className,
  zIndex
}: TooltipBaseProps<T> &
  (TooltipWithI18nProps | TooltipWithTextProps | TooltipWithChildrenProps)) {
  if (!tooltipI18nText && !tooltipText && !children) {
    return null;
  }

  return (
    <Tippy
      reference={referenceElement}
      interactive={interactive}
      placement={placement}
      arrow={withArrow}
      delay={defaultDelay}
      duration={defaultDuration}
      offset={offset}
      appendTo={appendTo}
      className={className}
      content={
        tooltipI18nText ? (
          <Translate id={tooltipI18nText} />
        ) : (
          tooltipText || children
        )
      }
      zIndex={zIndex}
      plugins={followCursor ? followCursorPlugin : undefined}
      touch={defaultTouch}
      {...(followCursor ? { followCursor } : {})}
    />
  );
}

export default Tooltip;
