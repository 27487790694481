import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TeamsKeyType {
  addPerformer: LocalesKey;
  addUser: LocalesKey;
  addTerms: LocalesKey;
  addYourTeamMembersToCompany: LocalesKey;
  accountReceivables: LocalesKey;
  artistsTeam: LocalesKey;
  airTable: LocalesKey;
  allCompanies: LocalesKey;
  allPerformers: LocalesKey;
  basicInfo: LocalesKey;
  blockAccount: LocalesKey;
  changeEmail: LocalesKey;
  changeCompanyOwner: LocalesKey;
  createCompany: LocalesKey;
  createAndViewCompany: LocalesKey;
  companyMembers: LocalesKey;
  companyName: LocalesKey;
  companyHasNoOwner: LocalesKey;
  companyLibrary: LocalesKey;
  companyUsers: LocalesKey;
  companies: LocalesKey;
  cm: LocalesKey;
  dashboard: LocalesKey;
  documents: LocalesKey;
  debtLimit: LocalesKey;
  deferredPayment: LocalesKey;
  description: LocalesKey;
  deliveryTime: LocalesKey;
  editCompanyPicture: LocalesKey;
  enterTheEmailAddressOfTheUser: LocalesKey;
  financeInfo: LocalesKey;
  finance: {
    balance: LocalesKey;
    debt: LocalesKey;
    revenue: LocalesKey;
    sigmaPayments: LocalesKey;
  };
  finLastPayments: LocalesKey;
  finStatus: LocalesKey;
  files: LocalesKey;
  bookId: LocalesKey;
  hourlyRate: LocalesKey;
  holdRate: LocalesKey;
  info: LocalesKey;
  inviteNewUser: LocalesKey;
  lastPayments: LocalesKey;
  lastActivity: LocalesKey;
  list: LocalesKey;
  marketingData: LocalesKey;
  name: LocalesKey;
  ownerId: LocalesKey;
  performers: LocalesKey;
  performersTat: LocalesKey;
  performersTeam: LocalesKey;
  plural: LocalesKey;
  pm: LocalesKey;
  profile: LocalesKey;
  provideAccess: LocalesKey;
  provisionForBadDebt: LocalesKey;
  provisionForRevision: LocalesKey;
  permissions: LocalesKey;
  personalization: LocalesKey;
  recalculateBalance: LocalesKey;
  records: LocalesKey;
  reports: LocalesKey;
  respondTime: LocalesKey;
  revenueShare: LocalesKey;
  revisions: LocalesKey;
  requirements: LocalesKey;
  resume: LocalesKey;
  singular: LocalesKey;
  source: LocalesKey;
  status: LocalesKey;
  searchCompanies: LocalesKey;
  searchPerformers: LocalesKey;
  selectCompany: LocalesKey;
  selectCompanies: LocalesKey;
  selectCompanyWithoutEllipsis: LocalesKey;
  settings: LocalesKey;
  shares: LocalesKey;
  smartContractSettings: LocalesKey;
  statuses: {
    new: LocalesKey;
    active: LocalesKey;
    paused: LocalesKey;
    declined: LocalesKey;
    realDecline: LocalesKey;
    noPayments: LocalesKey;
  };
  tasksWithoutSourceFiles: LocalesKey;
  tat: LocalesKey;
  terms: LocalesKey;
  transactionFee: LocalesKey;
  viewPerTask: LocalesKey;
  billingInfo: LocalesKey;
  items: LocalesKey;
  wire: LocalesKey;
  card: LocalesKey;
  preferredPaymentMethod: LocalesKey;
  addBillingInfoToSetPaymentMethod: LocalesKey;
  pleaseSelectBillingInfo: LocalesKey;
  merge: LocalesKey;
  mergeCompany: LocalesKey;
  mergeCompaniesWarning: {
    caution: LocalesKey;
    allUserInformationFromTheSelected: LocalesKey;
    company: LocalesKey;
    willBe: LocalesKey;
    merged: LocalesKey;
  };
  myCompanies: LocalesKey;
  selectUser: LocalesKey;
  usersInvited: LocalesKey;
}

export const teamsKeys = keyPathMirror<TeamsKeyType, string>(
  {
    addPerformer: null,
    addUser: null,
    addTerms: null,
    addYourTeamMembersToCompany: null,
    accountReceivables: null,
    artistsTeam: null,
    airTable: null,
    allCompanies: null,
    allPerformers: null,
    basicInfo: null,
    blockAccount: null,
    changeEmail: null,
    changeCompanyOwner: null,
    createCompany: null,
    createAndViewCompany: null,
    companies: null,
    companyMembers: null,
    companyHasNoOwner: null,
    companyLibrary: null,
    companyName: null,
    companyUsers: null,
    cm: null,
    dashboard: null,
    documents: null,
    debtLimit: null,
    deferredPayment: null,
    description: null,
    deliveryTime: null,
    editCompanyPicture: null,
    enterTheEmailAddressOfTheUser: null,
    financeInfo: null,
    finance: {
      balance: null,
      debt: null,
      revenue: null,
      sigmaPayments: null
    },
    finLastPayments: null,
    finStatus: null,
    files: null,
    hourlyRate: null,
    holdRate: null,
    bookId: null,
    info: null,
    inviteNewUser: null,
    lastPayments: null,
    lastActivity: null,
    list: null,
    marketingData: null,
    name: null,
    ownerId: null,
    performers: null,
    performersTat: null,
    performersTeam: null,
    plural: null,
    pm: null,
    profile: null,
    provideAccess: null,
    provisionForBadDebt: null,
    provisionForRevision: null,
    permissions: null,
    personalization: null,
    recalculateBalance: null,
    records: null,
    reports: null,
    respondTime: null,
    requirements: null,
    resume: null,
    revenueShare: null,
    revisions: null,
    singular: null,
    source: null,
    status: null,
    searchCompanies: null,
    searchPerformers: null,
    selectCompany: null,
    selectCompanies: null,
    selectCompanyWithoutEllipsis: null,
    settings: null,
    shares: null,
    smartContractSettings: null,
    statuses: {
      new: null,
      active: null,
      paused: null,
      declined: null,
      realDecline: null,
      noPayments: null
    },
    tasksWithoutSourceFiles: null,
    tat: null,
    terms: null,
    transactionFee: null,
    viewPerTask: null,
    billingInfo: null,
    items: null,
    wire: null,
    card: null,
    preferredPaymentMethod: null,
    addBillingInfoToSetPaymentMethod: null,
    pleaseSelectBillingInfo: null,
    merge: null,
    mergeCompany: null,
    mergeCompaniesWarning: {
      caution: null,
      allUserInformationFromTheSelected: null,
      company: null,
      willBe: null,
      merged: null
    },
    myCompanies: null,
    selectUser: null,
    usersInvited: null
  },
  'models.teams.'
);
