export default {
  add: 'Add product to library',
  all: 'All products',
  attachSelected: 'Attach selected products',
  download: 'Download product',
  deleteProduct: 'Delete product',
  deleteWarning:
    'Are you sure you want to delete the product? This action cannot be undone.',
  my: 'My Products',
  plural: 'Products',
  singular: 'Product',
  addProduct: 'Add product',
  attach: 'Attach products',
  attachProduct: 'Attach product',
  category: 'Product category',
  editProduct: 'Edit product',
  name: 'Product name',
  search: 'Search products',
  searchProductsByNameOrSku: 'Search products by name or SKU',
  selected: 'Selected products',
  viewAll: 'View all products',
  categories: {
    homeDecor: 'Home decor',
    homeTextiles: 'Home textiles',
    exterior: 'Exterior',
    plants: 'Plants',
    kitchen: 'Kitchen'
  },
  oneOrMoreSkuWasNotFoundInProductLibrary:
    'One or more SKU was not found in product library'
};
