export default {
  applyToAll: 'Apply to all',
  alreadyAdded: 'Already added',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Are you sure you want to delete this record? This action cannot be undone.',
  areYouSureYouWantToCancelTheTask: 'Are you sure you want to cancel the task?',
  areYouSureYouWantToClearSelected: 'Are you sure you want to clear selected?',
  areYouSureYouWantToClearAllFormFields:
    'Are you sure you want to clear all form fields?',
  areYouSureYouWantToDeletTheBillingAddress:
    'Are you sure you want to delete the billing address?',
  areYouSureYouWantToDeleteAccount: 'Are you sure you want to delete account?',
  areYouSureYouWantToBlockThisAccount:
    'Are you sure you want to block this account?',
  areYouSureYouWantToUnblockThisAccount:
    'Are you sure you want to unblock this account?',
  areYouSureYouWantToDeleteThisAccount:
    'Are you sure you want to delete this account? You will lose access to it forever.\n\nIf you are sure, confirm by entering the password below.',
  areYouSureYouWantToRemoveThisUserFromCompany:
    'Are you sure you want to remove this user from company?',
  areYouSureYouWantToBlockThisCompany:
    'Are you sure you want to block this company? All members will lose access to it.',
  atLeastOneTaskMustBeEntered: 'At least one task must be entered',
  dropTheFilesHere: 'Drop the files here ...',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'By rating tasks, you accept task results for the client',
  changeVisibility: 'Change visibility',
  dragAndDropFilesOr: 'Drag and drop files, or:',
  filterByDate: 'Filter by date',
  helpWithTextFormatting: 'Help with text formatting',
  indicateHowMuchDoYouAgreeWithTheFollowingStatement:
    'Please rate the degree to which you agree or disagree with each of the following statements',
  pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly:
    'Please consider creating a new task with further instructions or contact us at project thread directly.',
  pleaseProvideYourAddress: 'Please, provide your address',
  pleaseRateTheTask: 'Please, rate the task',
  pleaseRateTaskRusult: 'Please, rate task result',
  previewTextFormatting: 'Preview text formatting',
  iConfirmThatIHaveReadAndAgreeTo: 'I confirm that I have read and agree to ',
  acceptTaskResult: 'Accept task result',
  approveTaskResult: 'Approve task results',
  weAreOnline: "We're online 08:00 AM to 05:00 PM GMT Monday-Friday.",
  weAreReadyToHelp:
    "At that time we're ready to help and answer any questions.",
  writeHereWarning:
    'The team may not track this task since it has been completed, are you sure you want to leave a message here?',
  textFormattingHelp: 'Text formatting help',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'The payment has been accepted for processing. Usually the payment is processed and credited, or canceled by the payment system/bank that has issued the card within 10—30 minutes. One should not make new payment attempts during this time.',
  thePaymentHasBeenCanceled: 'The payment has been canceled.',
  theTaskResultHasBeenApproved:
    'The task result has been approved by the quality control manager. Waiting for the client to rate and accept the result.',
  pleaseClickOnStarsToRateTheResult:
    'Please click on stars to rate the results and then Accept in case you are satisfied and want to download the final files',
  thisCommentWouldBeVisibleOnlyInTimeline:
    'This comment would be visible only in Timeline',
  youWillBeRedirectedToTheProjectPageIn:
    'You will be redirected to the project page in',
  youWillBeRedirectedToThePaymentsPageIn:
    'You will be redirected to the payments page in',
  startTypingToSearchForClients: 'Start typing to search for clients',
  errorLoadingImage: 'Error loading image',
  thisActionWillAffect: 'This action will affect',
  updateCost: 'Update cost',
  updateDueDate: 'Update due date',
  pleaseWaitYourDownloadWillBeReadyInSeveralMinutes:
    'Please, wait. Your download will be ready in several minutes.',
  orChangeTableSettingsToAlwaysShowHiddenElements:
    'or change table settings to always show hidden elements.',
  hiddenAnd: 'hidden and',
  beforeYouAcceptTheTaskWeLikeToRemindYouThat: `Before you accept the task, we'd like to remind you that:`,
  onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt: `Once you accept the task, it will close and you won't be able to leave new comments in it.`,
  ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly:
    'If you want the result tweaked or adjusted after that, it will be a new task and charged accordingly.',
  pleaseRateResults: 'Please rate results',
  thereAreNoNonValidTasks: 'There are no non-valid tasks.',
  thereAreNoValidTasks: 'There are no valid tasks.',
  youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask:
    'You can always download the files to see them on your device before accepting the task',
  linkToTheCRMaUsernameAndPasswordCombinationUsedForLoggingInToOnlineAccountEtc:
    'Link to the CRM, a username and password combination used for logging in to online account etc.',
  youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed:
    'You are about to change the client. Existing tasks items will be deleted. Are you sure you want to proceed?',
  selectInvoiceFromTheList: 'Select invoice from the list',
  sendEmailNotification: 'Send email notification'
};
