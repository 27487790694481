import axios from 'axios';
import join from 'lodash/join';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';

import { BFF_API_PATH } from '../../config';
import { generateUuid } from '../generateUuid';

interface BffApiRequestOptions {
  limit: number;
  page: number;
  sort: string[];
  filters: Record<string, any>;
}

const bffApiUrl =
  typeof window === 'undefined'
    ? null
    : join([window.location.origin, BFF_API_PATH], '');

function headers(additionalHeaders?: Record<string, string | number>) {
  return {
    headers: {
      'Content-Type': 'application/json',
      ...additionalHeaders
    }
  };
}

function url(path, options = null) {
  return isEmpty(options)
    ? path
    : [
        path,
        `${compact(
          keys(options).map((key) => {
            if (options[key]) {
              return [
                snakeCase(key),
                isObject(options[key])
                  ? JSON.stringify(options[key])
                  : options[key]
              ].join('=');
            }

            return null;
          })
        ).join('&')}`
      ].join('?');
}

class BffApiRequest {
  static get<T = unknown>(path: string, options?: BffApiRequestOptions) {
    const requestId = generateUuid();

    return axios.get<T>(
      url(`${bffApiUrl}/${url(path, { ...options, requestId })}`),
      headers({
        requestId
      })
    );
  }
  static post<T = unknown>(path: string, data) {
    const requestId = generateUuid();

    return axios.post<T>(
      url(join([bffApiUrl, path], '/'), { requestId }),
      data,
      headers({
        requestId
      })
    );
  }
  static patch<T = unknown>(path: string, data) {
    const requestId = generateUuid();

    return axios.patch<T>(
      url(join([bffApiUrl, path], '/'), { requestId }),
      data,
      headers({
        requestId
      })
    );
  }
  static delete<T = unknown>(path: string, data?) {
    const requestId = generateUuid();

    return axios.delete<T>(url(join([bffApiUrl, path], '/'), { requestId }), {
      ...headers({
        requestId
      }),
      data
    });
  }
}

export default BffApiRequest;
