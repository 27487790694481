import React, { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../types';

import { Translate } from '../Translate';

export interface RadioButtonInputProps<ValueType> {
  disabled?: IsDisabled;
  i18nText?: I18nText;
  labelClassName?: ClassName;
  name: string;
  onChange: (buttonValue: ValueType) => void;
  option: ValueType;
  optionClassName?: ClassName;
  value: ValueType;
}

function RadioButtonInput<ValueType extends string | number>({
  disabled,
  i18nText,
  labelClassName,
  name,
  onChange,
  option,
  optionClassName,
  value
}: RadioButtonInputProps<ValueType>) {
  const handleChange = useCallback(() => {
    onChange(option);
  }, [option, onChange]);

  return (
    <div className="mr-4">
      <label className={labelClassName || 'inline-flex items-center'}>
        <input
          type="radio"
          className="basic-radio"
          name={name}
          value={option}
          checked={value === option}
          onChange={handleChange}
          disabled={disabled}
        />

        {i18nText ? (
          <span className={optionClassName || 'ml-2'}>
            <Translate id={i18nText} />
          </span>
        ) : (
          <span className={optionClassName}>{option}</span>
        )}
      </label>
    </div>
  );
}

export default RadioButtonInput;
