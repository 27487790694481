import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface JournalRecordsKeyType {
  account: LocalesKey;
  accountingTransactions: LocalesKey;
  accountType: LocalesKey;
  accountTypeChartNumber: LocalesKey;
  accountTypeChartNumbers: LocalesKey;
  itcId: LocalesKey;
  amount: LocalesKey;
  createdAtGte: LocalesKey;
  createdAtLte: LocalesKey;
  sumSide: LocalesKey;
  id: LocalesKey;
  balance: LocalesKey;
  credit: LocalesKey;
  date: LocalesKey;
  debit: LocalesKey;
  event: LocalesKey;
  journalEntryId: LocalesKey;
  journalRecordId: LocalesKey;
  side: LocalesKey;
  invoice: LocalesKey;
  plural: LocalesKey;
  import: LocalesKey;
  importTransactions: LocalesKey;
  incomeStatementPeriod: LocalesKey;
  incomeStatementRecordType: LocalesKey;
  searchTransactions: LocalesKey;
  searchByAccountTypeNanoId: LocalesKey;
  searchTransactionById: LocalesKey;
  searchByInvoiceId: LocalesKey;
  searchByTaskId: LocalesKey;
  searchByCompanyNanoId: LocalesKey;
  searchByProjectId: LocalesKey;
  searchByIncomeStatementRecordType: LocalesKey;
  searchByUserNanoId: LocalesKey;
  selectAccountTypeChartNumbers: LocalesKey;
  transactions: LocalesKey;
  transactionId: LocalesKey;
  transactionsImports: LocalesKey;
  transactionEventName: LocalesKey;
}

export const journalRecordsKeys = keyPathMirror<JournalRecordsKeyType, string>(
  {
    account: null,
    accountingTransactions: null,
    accountType: null,
    accountTypeChartNumber: null,
    accountTypeChartNumbers: null,
    itcId: null,
    amount: null,
    sumSide: null,
    id: null,
    balance: null,
    credit: null,
    createdAtGte: null,
    createdAtLte: null,
    date: null,
    side: null,
    debit: null,
    journalEntryId: null,
    journalRecordId: null,
    event: null,
    invoice: null,
    plural: null,
    import: null,
    importTransactions: null,
    incomeStatementPeriod: null,
    incomeStatementRecordType: null,
    searchTransactions: null,
    searchByAccountTypeNanoId: null,
    searchTransactionById: null,
    searchByInvoiceId: null,
    searchByCompanyNanoId: null,
    searchByTaskId: null,
    searchByProjectId: null,
    searchByIncomeStatementRecordType: null,
    searchByUserNanoId: null,
    selectAccountTypeChartNumbers: null,
    transactions: null,
    transactionId: null,
    transactionsImports: null,
    transactionEventName: null
  },
  'models.journalRecords.'
);
