import { gql } from 'graphql-request';

import {
  AccountID,
  AccountUUID,
  AccountNanoID,
  AccountCreatedAt,
  AccountGeneralLedgerId,
  AccountIncreaseSide,
  AccountBalance,
  AccountAccountTypeUUID,
  AccountAccountTypeNanoID,
  AccountAccountTypeCreatedAt,
  AccountAccountTypeName,
  AccountAccountTypeClientName,
  AccountAccountTypePerformerName,
  AccountAccountTypeShortName,
  AccountAccountTypeSystemName,
  AccountAccountTypeChartNumber,
  AccountAccountTypeIncreaseSides
} from '../accountsTypes';

export interface FetchAccountsQueryResponse {
  id: AccountID;
  uuid: AccountUUID;
  nanoId: AccountNanoID;
  createdAt: AccountCreatedAt;
  generalLedgerId: AccountGeneralLedgerId;
  increaseSide: AccountIncreaseSide;
  balance: AccountBalance;
  accountType: {
    uuid: AccountAccountTypeUUID;
    nanoId: AccountAccountTypeNanoID;
    createdAt: AccountAccountTypeCreatedAt;
    name: AccountAccountTypeName;
    clientName: AccountAccountTypeClientName;
    performerName: AccountAccountTypePerformerName;
    shortName: AccountAccountTypeShortName;
    systemName: AccountAccountTypeSystemName;
    chartNumber: AccountAccountTypeChartNumber;
    increaseSide: AccountAccountTypeIncreaseSides;
  };
}

export const FETCH_ACCOUNTS_QUERY = gql`
  query Accounts(
    $filters: AccountsFilters
    $limit: Int
    $offset: Int
    $sort: [AccountsSortEnum!]
  ) {
    accounts(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      nodes {
        accountType {
          chartNumber
          createdAt
          increaseSide
          name
          clientName
          performerName
          shortName
          nanoId
          systemName
          uuid
        }
        balance
        createdAt
        generalLedgerId
        id
        increaseSide
        nanoId
        uuid
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
