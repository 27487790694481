import React, { MouseEventHandler, useCallback } from 'react';
import cl from 'classnames';

import { ClassName, I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';
import { NumberHelper } from '../../NumberHelper';

type PureIconBadgeButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureIconBadgeButtonHelperDefaultProps {
  id?: string;
  blurOnMouseLeave?: boolean;
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  badgeValue: number;
  badgePrefix?: string;
  badgeSuffix?: string;
  badgeClassName?: ClassName;
  badgeAddClassName?: ClassName;
  badgeDecimalScale?: number;
  onClick?: PureIconBadgeButtonHelperOnClick;
  onMouseEnter?: () => void;
}

export interface PureIconBadgeButtonHelperIconProps {
  icon: IconsEnum;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureIconBadgeButtonHelperWithI18nProps {
  icon: IconsEnum;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureIconBadgeButtonHelperWithTextProps {
  icon: IconsEnum;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureIconBadgeButtonHelperProps = PureIconBadgeButtonHelperDefaultProps &
  (
    | PureIconBadgeButtonHelperIconProps
    | PureIconBadgeButtonHelperWithI18nProps
    | PureIconBadgeButtonHelperWithTextProps
  );

function PureIconBadgeButtonHelper({
  id,
  blurOnMouseLeave,
  className,
  disabled,
  icon,
  iconClassName,
  onClick,
  onMouseEnter,
  i18nTextClassName,
  i18nText,
  text,
  badgeValue,
  badgePrefix,
  badgeSuffix,
  badgeDecimalScale,
  badgeClassName,
  badgeAddClassName
}: PureIconBadgeButtonHelperProps) {
  const handleClick = useCallback<PureIconBadgeButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const handleMouseLeave = useCallback<MouseEventHandler>(
    (event) => (event.target as HTMLButtonElement)?.blur(),
    []
  );

  return (
    <button
      id={id}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
      type="button"
    >
      {icon ? (
        <Icon
          className={iconClassName}
          icon={icon}
          id={id ? `${id}-icon` : undefined}
        />
      ) : null}
      {icon && (i18nText || text) ? ' ' : null}
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
      {i18nText || text ? ' ' : null}
      <span
        className={cl(
          badgeClassName ||
            'bg-gray-300 dark:bg-gray-700 px-1 rounded leading-snug 2xl:leading-normal text-2xs',
          badgeAddClassName
        )}
      >
        <NumberHelper
          value={badgeValue}
          prefix={badgePrefix}
          suffix={badgeSuffix}
          decimalScale={badgeDecimalScale}
        />
      </span>
    </button>
  );
}

export default PureIconBadgeButtonHelper;
