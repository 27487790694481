import {
  AssociationIDsFilter,
  BooleanFilter,
  CreatedAt,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DateFilterType,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemCacheKey,
  FetchItemFetched,
  FetchItemGqlQuery,
  FetchItemIsPlaceholderData,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  Item,
  ItemGqlError,
  NumberFilterType,
  StringArrayFilter,
  TextFilterType,
  TrackTimeInItemGqlQuery,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID
} from '../../types';
import { UserNanoID, UserUUID } from '../users/usersTypes';

export type CategoryID = ID;
export type CategoryUUID = UUID;
export type CategoryName = string;
export type CategoryNameEn = string;
export type CategoryLocalizedName = string;
export type CategoryCreatedAt = CreatedAt;

export type CategoryParentID = CategoryID;
export type CategoryParentUUID = CategoryUUID;
export type CategoryParentName = CategoryName;
export type CategoryParentLocalizedName = CategoryLocalizedName;

export enum CategoryFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export interface FetchCategoriesFilters {
  name?: TextFilterType;
  nameEn?: TextFilterType;
  nameUk?: TextFilterType;
  order?: NumberFilterType;
  categoriesCount?: NumberFilterType;
  pdfExport?: BooleanFilter;
  blocked?: BooleanFilter;
  createdAt?: DateFilterType;
  parentId?: IdFilterType;
  productClientIds?: AssociationIDsFilter;
  lifestyleClientIds?: AssociationIDsFilter;
  scope?: StringArrayFilter;
  productCategoryIds?: AssociationIDsFilter;
  productParentCategoryIds?: AssociationIDsFilter;
}

export enum FetchCategoriesSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchCategoriesFilterFields {
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchCategoriesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  LOCALIZED_NAME_ASC = 'LOCALIZED_NAME_ASC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export enum FetchCategoriesScopes {
  PRODUCTS_MISTAKE_TYPES = 'facilities_mistake_types',
  PRODUCTS_STATUSES = 'facilities_statuses',
  PRODUCTS_TAGS = 'products_tags',
  PRODUCTS_FILE_VERSION_TAGS = 'facilities_file_version_tags',
  PRODUCT_GROUPS = 'facility_groups',
  PRODUCT_COLLECTIONS = 'facilities_collections',
  LIFESTYLE_GROUPS = 'scene_rooms',
  COUNTRIES = 'clients_countries',
  STATES = 'clients_states',
  REG_POSITION_ID = 'user_position',
  FACILITY_STYLES = 'facility_styles',
  FACILITY_MATERIALS = 'facility_materials',
  FACILITY_COLORS = 'facilities_colors',
  FACILITY_MANUFACTURERS = 'facility_manufacturers',
  FACILITIES_RENDER_TYPES = 'facilities_render_types',
  SCENE_STYLES = 'scene_styles',
  SCENE_MATERIALS = 'scene_materials',
  SCENE_MAIN_COLORS = 'scene_main_colors',
  SCENE_ACCENT_COLORS = 'scene_accent_colors',
  SCENE_TONES = 'scene_tones',
  SCENE_GAMMAS = 'scene_gammas',
  SCENE_PERFORMERS = 'scene_performers',
  SCENES_RENDER_TYPES = 'scenes_render_types',
  LIFESTYLES_TAGS = 'lifestyles_tags',
  PROJECTS_NAMES = 'projects_names',
  CATEGORY = 'clients_categories',
  COMPANY_SIZE = 'users_company_sizes',
  MANUAL_CLIENT_STATUS = 'users_manual_client_status',
  INTEGRATION = 'users_integrations',
  SUB_CATEGORIES = 'users_sub_categories',
  SOURCE = 'clients_sources',
  MARKETING_SOURCE = 'marketing_source',
  COLD_SOURCE = 'cold_source',
  URL = 'url',
  REAL_DECLINE_REASONS = 'real_decline_reason',
  POSITION = 'user_position',
  PROFICIENCY_LEVEL = 'proficiency_level',
  SPECIALIZATIONS = 'users_specializations',
  QUALIFICATIONS = 'users_qualifications',
  CELLS = 'users_cells',
  GROUPS = 'users_groups',
  PAYMENT_TYPE = 'payment_type',
  CLIENT_MANAGER = 'client_manager',
  PROJECT_MANAGERS = 'project_managers',
  THREE_D_SERVICES = '3d_services',
  COMPANY_REVENUE = 'company_revenue',
  NATURE_OF_LEAD = 'nature_of_lead',
  WORKFLOW_TYPE = 'workflow_type',
  COMPLEXITIES = 'complexities',
  SCOPE_OF_WORKS = 'scope_of_works',
  TEAMS_TAGS = 'teams_tags'
}

export type FetchCategoriesGqlQuery = FetchItemsGqlQuery;
export type FetchCategoryGqlQuery = FetchItemGqlQuery;
export type CreateCategoryGqlQuery = CreateItemGqlQuery;
export type UpdateCategoryGqlQuery = UpdateItemGqlQuery;
export type DeleteCategoryGqlQuery = DeleteItemGqlQuery;
export type DownloadCategoryResultsGqlQuery = UpdateItemGqlQuery;
export type TrackTimeInCategoryGqlQuery = TrackTimeInItemGqlQuery;

export type CreateCategoryGqlStatus = CreateItemGqlStatus;
export type UpdateCategoryGqlStatus = UpdateItemGqlStatus;
export type DeleteCategoryGqlStatus = DeleteItemGqlStatus;
export type DownloadCategoryResultsGqlStatus = UpdateItemGqlStatus;
export type CategoryGqlError = ItemGqlError;

export type FetchCategoriesCacheKey = FetchItemsCacheKey;
export type FetchCategoryCacheKey = FetchItemCacheKey;

export type FetchCategoriesFetched = FetchItemsFetched;
export type FetchCategoriesErrorMessage = FetchItemsErrorMessage;
export type FetchCategoriesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchCategoriesPage = FetchItemsPage;
export type FetchCategoriesLimit = FetchItemsLimit;
export type FetchCategoriesSort = FetchItemsSort; // FetchCategoriesSortTypes[];
export type FetchCategoriesTotalCount = FetchItemsTotalCount;
export type FetchCategoriesSortCategories = FetchItemsSortItems;
export type FetchCategoriesPaginateCategories = FetchItemsPaginateItems;
export type FetchCategoriesPrefetchCategories = FetchItemsPrefetchItems;
export type FetchCategoriesShowCategory = FetchItemsShowItem;
export type FetchCategoriesEditCategory = FetchItemsEditItem;

export type FetchCategoryFetched = FetchItemFetched;
export type FetchCategoryIsPlaceholderData = FetchItemIsPlaceholderData;

export type FetchCategoriesFilterCategories =
  FetchItemsFilterItems<FetchCategoriesFilters>;
export type FetchCategoriesClearCategoriesFilters = FetchItemsClearItemsFilters;
// Deprecated

export type FetchCategoriesFiltersFunc = (
  nextFilters: FetchCategoriesFilters
) => void;

// export type FetchCategoriesSort = FetchCategoriesSortItem[];

export interface CategoryType extends Item {
  blocked: boolean;
  name: string;
  nameEn: string;
  nameUk: string;
  order: number;
  pdfExport: boolean;
  categoriesCount: number;
  section: {
    uuid: UUID;
    name: string;
  };
  parent: {
    uuid: UUID;
    localizedName: string;
  };
  user: {
    uuid: UserUUID;
    nanoId: UserNanoID;
    fullName: string;
    image: {
      uuid: UUID;
      file: string;
    };
  };
}

export type CategoriesType = CategoryType[];
