import { avBillingInfosKeys } from '../../locales/keys';
import {
  AvBillingInfoPaymentMethods,
  FetchAvBillingInfosSortTypes
} from './avBillingInfosTypes';

export enum AvBillingInfosPermissions {
  READ_ADMIN_MENU_AV_BILLING_INFOS_LINK = 'read_admin_menu_av_billing_infos_link',

  READ_AV_BILLING_INFOS_INDEX_PAGE = 'read_av_billing_infos_index_page',

  READ_AV_BILLING_INFOS_TABLE_SELECTED_FIELD = 'read_av_billing_infos_table_selected_field',
  READ_AV_BILLING_INFOS_TABLE_NAME_FIELD = 'read_av_billing_infos_table_name_field',
  READ_AV_BILLING_INFOS_TABLE_IMAGE_FIELD = 'read_av_billing_infos_table_image_field',
  READ_AV_BILLING_INFOS_TABLE_USER_FIELD = 'read_av_billing_infos_table_user_field',
  READ_AV_BILLING_INFOS_TABLE_CREATED_AT_FIELD = 'read_av_billing_infos_table_created_at_field',
  READ_AV_BILLING_INFOS_TABLE_DROPDOWN_FIELD = 'read_av_billing_infos_table_dropdown_field',
  READ_AV_BILLING_INFOS_TABLE_COMPANY_NAME_FIELD = 'read_av_billing_infos_table_company_name_field',
  READ_AV_BILLING_INFOS_TABLE_COUNTRY_FIELD = 'read_av_billing_infos_table_country_field',
  READ_AV_BILLING_INFOS_TABLE_STATE_FIELD = 'read_av_billing_infos_table_state_field',
  READ_AV_BILLING_INFOS_TABLE_CITY_FIELD = 'read_av_billing_infos_table_city_field',
  READ_AV_BILLING_INFOS_TABLE_ADDRESS_FIELD = 'read_av_billing_infos_table_address_field',
  READ_AV_BILLING_INFOS_TABLE_ZIP_CODE_FIELD = 'read_av_billing_infos_table_zip_code_field',
  READ_AV_BILLING_INFOS_TABLE_CONTACT_EMAIL_FIELD = 'read_av_billing_infos_table_contact_email_field',
  READ_AV_BILLING_INFOS_TABLE_CONTACT_PHONE_FIELD = 'read_av_billing_infos_table_contact_phone_field',
  READ_AV_BILLING_INFOS_TABLE_EXCLUSION_FIELD = 'read_av_billing_infos_table_exclusion_field',
  READ_AV_BILLING_INFOS_TABLE_BANK_DETAILS_FIELD = 'read_av_billing_infos_table_bank_details_field',
  READ_AV_BILLING_INFOS_TABLE_NOTES_FIELD = 'read_av_billing_infos_table_notes_field',

  READ_AV_BILLING_INFOS_DELETE_ITEM_CATEGORY_BUTTON = 'read_av_billing_infos_delete_billing_info_button',
  READ_AV_BILLING_INFOS_CREATE_ITEM_CATEGORY_BUTTON = 'read_av_billing_infos_create_billing_info_button'
}

export const INITIAL_AV_BILLING_INFOS_FILTERS = {};
export const INITIAL_AV_BILLING_INFOS_PAGE = 1;
export const INITIAL_AV_BILLING_INFOS_SORT = [
  FetchAvBillingInfosSortTypes.CREATED_AT_DESC
];
export const INITIAL_AV_BILLING_INFOS_LIMIT = 50;

export const usaCountryName = 'USA';

export const avBillingInfoI18nTextPaymentMethods = {
  [AvBillingInfoPaymentMethods.WIRE]: avBillingInfosKeys.paymentMethods.wire,
  [AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT]:
    avBillingInfosKeys.paymentMethods.payoneerCheckout,
  [AvBillingInfoPaymentMethods.PAYONEER]:
    avBillingInfosKeys.paymentMethods.payoneer,
  [AvBillingInfoPaymentMethods.FONDY]: avBillingInfosKeys.paymentMethods.fondy,
  [AvBillingInfoPaymentMethods.STRIPE]: avBillingInfosKeys.paymentMethods.stripe
};
