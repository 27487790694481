import { gql } from 'graphql-request';

import {
  TeamClientStatus,
  TeamID,
  TeamLastPaymentDate,
  TeamNanoID,
  TeamUUID,
  TeamFirstPaymentDate,
  TeamGeneralLedgerID
} from '../teamsTypes';

export interface FetchCompanyQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  bitClientStatus: TeamClientStatus;
  bitFirstPaymentDate: TeamFirstPaymentDate;
  bitLastPaymentDate: TeamLastPaymentDate;
  generalLedgerId: TeamGeneralLedgerID;
}

export const FETCH_COMPANY_QUERY = gql`
  query Company($uuid: ID!) {
    team(uuid: $uuid) {
      bitClientStatus
      bitFirstPaymentDate
      bitLastPaymentDate
      id
      nanoId
      uuid
      generalLedgerId
    }
  }
`;
