export default {
  av: 'AV',
  avGetsFixedShare: 'AV gets fixed share',
  avGetsRemainderAfterAllOtherParticipants:
    'AV gets remainder after all other participants',
  avMinimumShare: 'AV minimum share',
  avShareScheme: 'AV Share Scheme',
  archivizer: 'Archivizer',
  areYuSureYouWantToCancelTheSmartContract:
    'Are you sure you want to cancel the smart contract?',
  amount: 'Amount',
  allSmartContracts: 'All smart contracts',
  basicSmartContract: 'Basic smart contract',
  bound: 'Bound',
  cancel: 'Cancel',
  canceled: 'Canceled',
  checkIn: 'Check in',
  created: 'Created',
  closed: 'Closed',
  changeShare: 'Change share',
  checkTheAvailabilityOfAllSmartContractRules:
    'Check the availability of all smart contract rules',
  contractorRule: 'Contractor rule',
  delete: 'Delete',
  dueDate: 'Due date',
  draftSmartContract: 'Draft smart contract',
  fees: 'Fees',
  forBasicWorkflow: 'For basic workflow',
  forPaymentsFromAv: 'For payments from AV',
  forPaymentsFromPfr: 'For payments from PFR',
  fixed: 'Fixed',
  id: 'ID',
  internalSmartContract: 'Internal smart contract',
  internalSmartContractIsUsedForPaymentsFromPfrPfbdOrDirectlyFromArchivizer:
    'Internal smart contract is used for payments from PFR, PFBD, or directly from Archivizer',
  invoices: 'Invoices',
  holdRate: 'Hold rate',
  label: 'Label',
  name: 'Name',
  net: 'Net',
  netAmount: 'Net amount',
  noAvShareInSmartContract: 'No AV share in smart-contract',
  noParticipantsYet: 'No participants yet',
  openSmartContract: 'Open smart contract',
  opened: 'Opened',
  participants: 'Participants',
  paid: 'Paid',
  payer: 'Payer',
  payFromAV: 'Pay from AV',
  payFromPFR: 'Pay from PFR',
  payFromPFBD: 'Pay from PFBD',
  plural: 'Smart contracts',
  pfbd: 'PFBD',
  pfr: 'PFR',
  project: 'Project',
  postponed: 'Postponed',
  provisionForRevision: 'Provision for revision',
  provisionForBadDebt: 'Provision for bad debt',
  revision: 'Revision',
  revisionSmartContract: 'Revision smart contract',
  residual: 'Residual',
  removeFromContract: 'Remove from contract',
  role: 'role',
  singular: 'Smart contract',
  scf: 'SCF',
  smartContractFee: 'Smart contract fee',
  smartContractFees: 'Smart contract fees',
  smartContractTotal: 'Smart contract total',
  share: 'Share',
  status: 'Status',
  statuses: {
    open: 'Open',
    canceled: 'Canceled',
    draft: 'Draft',
    done: 'Done'
  },
  statusLabels: {
    all: 'All',
    open: 'Open',
    canceled: 'Canceled',
    closed: 'Closed',
    draft: 'Draft',
    done: 'Done'
  },
  task: 'Task',
  totalAmount: 'Total amount',
  terms: 'Terms',
  transactionFee: 'Transaction fee',
  trx: 'TRX',
  value: 'Value'
};
