import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  DefaultAt,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  NanoID,
  StringArrayFilter,
  StringFilter,
  UpdatedAt,
  UUID
} from '../../types';

export type AvBillingInfoID = ID;
export type AvBillingInfoUUID = UUID;
export type AvBillingInfoNanoID = NanoID;
export type AvBillingInfoCreatedAt = CreatedAt;
export type AvBillingInfoUpdatedAt = UpdatedAt;
export type AvBillingInfoDefaultAt = DefaultAt;
export type AvBillingInfoName = string;
export type AvBillingInfoCompanyName = string;
export type AvBillingInfoContactName = string;
export type AvBillingInfoContactPhone = string;
export type AvBillingInfoContactEmail = string;
export type AvBillingInfoCountry = string;
export type AvBillingInfoState = string;
export type AvBillingInfoCity = string;
export type AvBillingInfoZipCode = string;
export type AvBillingInfoBankDetails = string | null;
export type AvBillingInfoNotes = string | null;
export type AvBillingExclusion = string | null;
export type AvBillingInfoExcludedCountries = string[];
export type AvBillingInfoAddress = string;

export const enum AvBillingInfoPaymentMethods {
  WIRE = 'wire',
  CARD = 'card',
  PAYONEER = 'payoneer',
  PAYONEER_CHECKOUT = 'payoneer_checkout',
  STRIPE = 'stripe',
  FONDY = 'fondy'
}

export const enum FetchAvBillingInfosSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_DESC = 'NAME_DESC',
  NAME_ASC = 'NAME_ASC'
}

export interface FetchAvBillingInfosFilters {
  name?: StringFilter;
  paymentMethod?: StringArrayFilter;
  allowedCountry?: StringFilter;
}

export type FetchAvBillingInfosGqlQuery = FetchItemsGqlQuery;

export type FetchAvBillingInfosCacheKey = FetchItemsCacheKey;

export type FetchAvBillingInfosSort = FetchItemsSort;
export type FetchAvBillingInfosSortAvBillingInfos = FetchItemsSortItems;
export type FetchAvBillingInfosPage = FetchItemsPage;
export type FetchAvBillingInfosLimit = FetchItemsLimit;
export type FetchAvBillingInfosErrorMessage = FetchItemsErrorMessage;
export type FetchAvBillingInfosFetched = FetchItemsFetched;
export type FetchAvBillingInfosIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchAvBillingInfosPaginateAvBillingInfos = FetchItemsPaginateItems;
export type FetchAvBillingInfosPrefetchAvBillingInfos = FetchItemsPrefetchItems;
export type FetchAvBillingInfosTotalCount = FetchItemsTotalCount;

export type FetchAvBillingInfosCheckedAll = CheckedAll;
export type FetchAvBillingInfosOnCheckAll = OnCheckAll;
export type FetchAvBillingInfosChecked = Checked;
export type FetchAvBillingInfosCheckedHash = CheckedHashItem;
export type FetchAvBillingInfosOnSetCheckedIds = OnSetCheckedIds;

export type FetchAvBillingInfosShowAvBillingInfo = FetchItemsShowItem;
export type FetchAvBillingInfosEditAvBillingInfo = FetchItemsEditItem;

export const enum FetchAvBillingInfosSortableFields {
  NAME = 'name',
  COMPANY_NAME = 'companyName',
  CONTACT_NAME = 'contactName',
  CONTACT_PHONE = 'contactPhone',
  CONTACT_EMAIL = 'contactEmail',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  BANK_DETAILS = 'bankDetails',
  NOTES = 'notes',
  CREATED_AT = 'createdAt'
}

export const enum AvBillingInfoFields {
  NAME = 'name',
  COMPANY_NAME = 'companyName',
  CONTACT_NAME = 'contactName',
  CONTACT_PHONE = 'contactPhone',
  CONTACT_EMAIL = 'contactEmail',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  BANK_DETAILS = 'bankDetails',
  NOTES = 'notes'
}

export type CreateAvBillingInfoIsLoading = CreateItemIsLoading;
export type CreateAvBillingInfoCacheKeys = CreateItemCacheKeys;

export type DeleteAvBillingInfoCacheKeys = DeleteItemCacheKeys;
