import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface OperationsKeyType {
  addRecord: LocalesKey;
  record: LocalesKey;
  recordAdded: LocalesKey;
  status: LocalesKey;
  recordedDate: LocalesKey;
  dueDate: LocalesKey;
  completedDate: LocalesKey;
  lastRecord: LocalesKey;
  subject: LocalesKey;
  statuses: {
    planned: LocalesKey;
    completed: LocalesKey;
    not_started: LocalesKey;
    in_progress: LocalesKey;
    canceled: LocalesKey;
    waiting_for_input: LocalesKey;
    deferred: LocalesKey;
  };
  types: {
    call: LocalesKey;
    event: LocalesKey;
    task: LocalesKey;
  };
  typeOfActivity: LocalesKey;
}

export const operationsKeys = keyPathMirror<OperationsKeyType, string>(
  {
    addRecord: null,
    record: null,
    recordAdded: null,
    status: null,
    recordedDate: null,
    dueDate: null,
    completedDate: null,
    lastRecord: null,
    subject: null,
    statuses: {
      planned: null,
      completed: null,
      not_started: null,
      in_progress: null,
      canceled: null,
      waiting_for_input: null,
      deferred: null
    },
    types: {
      call: null,
      event: null,
      task: null
    },
    typeOfActivity: null
  },
  'models.operations.'
);
