import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ProfilesKeyType {
  activeTasks: LocalesKey;
  airtable: LocalesKey;
  access: LocalesKey;
  atLowerCase: LocalesKey;
  accounts: LocalesKey;
  averageEvaluation: LocalesKey;
  balance: LocalesKey;
  bonusAmount: LocalesKey;
  basicInfo: LocalesKey;
  dashboard: LocalesKey;
  debt: LocalesKey;
  description: LocalesKey;
  documents: LocalesKey;
  editProfilePicture: LocalesKey;
  evaluations: LocalesKey;
  files: LocalesKey;
  finance: LocalesKey;
  financeInfo: LocalesKey;
  invitations: LocalesKey;
  latestResults: LocalesKey;
  marketingInfo: LocalesKey;
  mar: LocalesKey;
  myProjects: LocalesKey;
  myTasks: LocalesKey;
  myMessages: LocalesKey;
  myResults: LocalesKey;
  openSmartContracts: LocalesKey;
  personalization: LocalesKey;
  rate: LocalesKey;
  ratio: LocalesKey;
  records: LocalesKey;
  reports: LocalesKey;
  resume: LocalesKey;
  requirements: LocalesKey;
  revenue: LocalesKey;
  singular: LocalesKey;
  status: LocalesKey;
  smartContractSettings: LocalesKey;
  statistic: LocalesKey;
  tasks: LocalesKey;
  tasksByStatus: LocalesKey;
  tasksWithoutSourceFiles: LocalesKey;
  teamAndPosition: LocalesKey;
  timeByDate: LocalesKey;
  totalHours: LocalesKey;
  totalHoursDay: LocalesKey;
  totalPayments: LocalesKey;
  totalProjects: LocalesKey;
  totalTasks: LocalesKey;
  underpayments: LocalesKey;
  kAvr: LocalesKey;
  kAverage: LocalesKey;
  kMed: LocalesKey;
  kMedian: LocalesKey;
  userMessages: LocalesKey;
  userResults: LocalesKey;
}

export const profilesKeys = keyPathMirror<ProfilesKeyType, string>(
  {
    activeTasks: null,
    access: null,
    airtable: null,
    atLowerCase: null,
    accounts: null,
    averageEvaluation: null,
    balance: null,
    bonusAmount: null,
    basicInfo: null,
    dashboard: null,
    debt: null,
    description: null,
    documents: null,
    editProfilePicture: null,
    evaluations: null,
    files: null,
    finance: null,
    financeInfo: null,
    invitations: null,
    latestResults: null,
    kAvr: null,
    kAverage: null,
    kMed: null,
    kMedian: null,
    marketingInfo: null,
    mar: null,
    myProjects: null,
    myTasks: null,
    myMessages: null,
    myResults: null,
    openSmartContracts: null,
    personalization: null,
    rate: null,
    ratio: null,
    records: null,
    reports: null,
    resume: null,
    requirements: null,
    revenue: null,
    singular: null,
    status: null,
    smartContractSettings: null,
    statistic: null,
    tasks: null,
    tasksByStatus: null,
    tasksWithoutSourceFiles: null,
    teamAndPosition: null,
    timeByDate: null,
    totalHours: null,
    totalHoursDay: null,
    totalPayments: null,
    totalProjects: null,
    totalTasks: null,
    underpayments: null,
    userMessages: null,
    userResults: null
  },
  'models.profiles.'
);
